import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCouponTransactions = (afterCursor = null, search = {}) => {
  console.log('@@4: ', search);
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.searchCouponId) {
    searchString += `, singleCouponIn: "${search.searchCouponId}"`;
  }
  // brand: "146,145"
  // campaign: "38,39"
  // campaign_active_period: "2022-04-08,"
  // campaign_active_status: "false"
  // coupon_set: "1"
  // coupon_valid_period: "2022-04-08,2022-05-27"
  // page: 1
  // rank: true
  // redemption_method: "MERCHANT_APP_SCAN_COUPON_QR_CODE"
  // report_period: ",2022-06-25"
  // searchKey: undefined
  // single_coupon: "934,935"
  // single_coupon_status: "BUY"
  // transaction_type: "EXPIRED"

  if (search.brand) {
    searchString += `, brandIn: "${search.brand}"`;
  }
  if (search.campaign) {
    searchString += `, campaignIn: "${search.campaign}"`;
  }
  if (search.coupon) {
    searchString += `, singleCouponIn: "${search.coupon}"`;
  }
  if (search.campaign_active_period) {
    const campaignActivePeriod = search.campaign_active_period.split(',');
    searchString += `, ${
      campaignActivePeriod[0]
        ? `campaignActiveGte: "${campaignActivePeriod[0]}", `
        : ''
    }${
      campaignActivePeriod[1]
        ? `campaignActiveLte: "${campaignActivePeriod[1]}"`
        : ''
    }`;
  }
  if (search.campaign_active_status) {
    searchString += `, campaignIsExpired: ${search.campaign_active_status}`;
  }
  if (search.coupon_set) {
    searchString += `, couponSetIn: "${search.coupon_set}"`;
  }
  if (search.coupon_valid_period) {
    const couponValidPeriod = search.coupon_valid_period.split(',');
    searchString += `, ${
      couponValidPeriod[0] ? `absoluteGte: "${couponValidPeriod[0]}", ` : ''
    }${couponValidPeriod[1] ? `absoluteLte: "${couponValidPeriod[1]}"` : ''}`;
  }

  if (search.redemption_method) {
    searchString += `, redemptionMethodIn: "${search.redemption_method}"`;
  }

  if (search.report_period) {
    const reportPeriod = search.report_period.split(',');
    searchString += `, ${
      reportPeriod[0] ? `reportDateGte: "${reportPeriod[0]}", ` : ''
    }${reportPeriod[1] ? `reportDateLte: "${reportPeriod[1]}"` : ''}`;
  }
  if (search.use_time) {
    const reportPeriod = search.use_time.split(',');
    searchString += `, ${
      reportPeriod[0] ? `usedAtGte: "${reportPeriod[0]}", ` : ''
    }${reportPeriod[1] ? `usedAtLte: "${reportPeriod[1]}"` : ''}`;
  }
  if (search.single_coupon) {
    searchString += `, serialNumberIcontains: "${search.single_coupon}"`;
  }
  if (search.single_coupon_status) {
    searchString += `, singleCouponStatusIn: "${search.single_coupon_status}"`;
  }
  if (search.srk) {
    searchString += `, srk: "${search.srk}"`;
  }
  if (search.coupon_code) {
    searchString += `, couponCode: "${search.coupon_code}"`;
  }
  if (search.is_manual_use) {
    searchString += `, isManualUse: true`;
  }
  if (search.transaction_type) {
    searchString += `, typeIn: "${search.transaction_type}"`;
  }

  if (search.starred_campaign) {
    searchString += `, campaignIsStarred: ${search.starred_campaign}`;
  }

  if (search.reference_id) {
    searchString += `, referenceIdIcontains: "${search.reference_id}"`;
  }

  if (search.coupon_medium) {
    searchString += `, couponMediumIn: "${search.coupon_medium}"`;
  }

  if (search.campaign_type) {
    searchString += `, campaignTypeIn: "${search.campaign_type}"`;
  }
  if (search.goodie_bag_reward_type) {
    searchString += `, rewardTypeIn: "${search.goodie_bag_reward_type}"`;
  }

  // if (search.expired_date) {
  //   switch (search.expired_date) {
  //     case 'absolute':
  //       if (search.absolute) {
  //         const expiredDateRange = search.absolute.split(',');
  //         searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
  //       }
  //       break;
  //     case 'relativeDay':
  //       if (search.relativeDay) {
  //         searchString += `, relativeDay: ${search.relativeDay}`;
  //       }
  //       break;
  //     default:
  //       searchString += `, allTime: true`;
  //       break;
  //   }
  // }
  // if (search.paymentAndSettlementReport) {
  //   searchString += `, paymentAndSettlementReport: ${search.paymentAndSettlementReport}`;
  // }
  const query = `{
        couponTransactions(
          first: 20,
          after: "${afterCursor}"${searchString}
        ) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              type
              pointsSpent
              creationDate
              usedAt
              transactionAmountSurveyValue
              customer {
                pk
                ssoUid
                membershipId
              }
              membershipId
              coupon {
                id
                pk
                effectiveDate
                expiryDate
                serialNumber
                forcedInactiveBy
                template {
                    pk
                    name
                    validPeriodType
                    absoluteEffectiveDate
                    absoluteExpiryDate
                    numberOfDaysToExpireAfterAcquisition
                    requireHkidOrPassportNumber
                }
                ownerProfile {
                  fullname
                  mobilePhoneNumberCountryCode
                  mobilePhoneNumberSubscriberNumber
                  identityNumber
                }
                owner {
                  pk
                }
                isUsed
                isVoid
                isExpired
                isForcedInactive
                dateOfGrant
                usedAt
                referenceId
                srk
                code
                usedPlatform
                batch {
                  couponMedium
                }
                membershipId
              }
              administrator {
                pk
                username
                adminType
                brand{
                  name
                }
              }
            }
          }
        }
      }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponTransaction = (couponTransactionID) => {
  const query = `
  {
    couponTransaction(id: "${couponTransactionID}") {
      id
      pk
      type
      pointsSpent
      creationDate
      usedAt
      transactionAmountSurveyValue
      customer {
        pk
        ssoUid
        membershipId
      }
      membershipId
      campaign {
        pk
        name
      }
      coupon {
        id
        pk
        effectiveDate
        expiryDate
        serialNumber 
        template {
          pk
          name
          validPeriodType
          absoluteEffectiveDate
          absoluteExpiryDate
          numberOfDaysToExpireAfterAcquisition
          requireHkidOrPassportNumber
          discountCodeFormat
          barcodeFormat {
            id
            pk
            name
          }
          redemptionMethod
          periodRestriction
          weeklyRules {
            edges {
              node {
                pk
                couponMedium
                weekDays {
                  edges {
                    node {
                      pk
                    }
                  }
                }
                timePeriodType
                timePeriods (orderBy: "startTime"){
                  edges {
                    node {
                      startTime
                      endTime
                    }
                  }
                }
              }
            }
          }
          dateRules (orderBy: "dayDate"){
            edges {
              node {
                pk
                couponMedium
                isExclude
                dayDate
                timePeriodType
                timePeriods (orderBy: "startTime"){
                  edges {
                    node {
                      startTime
                      endTime
                    }
                  }
                }
              }
            }
          }
        }
        ownerProfile {
          fullname
          mobilePhoneNumberCountryCode
          mobilePhoneNumberSubscriberNumber
          identityNumber
        }
        referenceId
        isUsed
        usedAt
        batch {
          couponMedium
        }
        membershipId
        usedPlatform
        useMethod
      }
      administrator {
        pk
        username
        adminType
      }
      store{
        id
        pk
        name
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPointTransactionRecords = (afterCursor = '', search = {}) => {
  // let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  let searchString = `, orderBy: "-pk"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.type === 'REMOVE') {
    searchString += `, pointTypeIn: "TYPE_POINT_REMOVE"`;
  } else if (search.type === 'ADD') {
    searchString += `, pointTypeIn: "TYPE_POINT_ADD"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.record_type) {
    searchString += `, transactionTypeIn: "${search.record_type}"`;
  }
  if (search.value) {
    const valueRange = search.value.split(',');
    searchString += `, valueGte: ${valueRange[0]}, valueLte: ${valueRange[1]}`;
  }
  if (search.create_date) {
    searchString += `, creationDateEqual: "${search.create_date}"`;
  }

  const query = `
  {
    pointTransactions(
      first: 20,
      after: "${afterCursor}",
      hideBeVoidRecords: false${searchString}
    ) {
      totalCount
      maxPointValue
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          eventIdentifier
          customer {
            pk
            ssoUid
            membershipId
          }
          pointType
          value
          transactionType
          remarks
          creationDate
          usedType
          usedRemark
          usedDate
          adminVoidTransaction {
            pk
            eventIdentifier
            transactionType
          }
          expireAtDate
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOnePointTransactionRecord = (pointRecordID) => {
  const query = `
  {
    pointTransaction(id: "${pointRecordID}") {
      id
      pk
      eventIdentifier
      customer {
        pk
        ssoUid
      }
      pointType
      value
      transactionType
      remarks
      creationDate
      usedType
      usedRemark
      usedDate
      adminVoidTransaction {
        pk
        eventIdentifier
        transactionType
      }
      expireAtDate
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPointAddTypeRecord = (inputBody) => {
  const query = `
  mutation AddPoints($input: AddPointsInput!) {
    addPoints(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPointRemoveTypeRecord = (inputBody) => {
  const query = `
  mutation RemovePoints($input: RemovePointsInput!) {
    removePoints(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransactions = (afterCursor = '', search) => {
  console.log('@@261: ', search);
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.transaction_type) {
    searchString += `, transactionTypeIn: "${search.transaction_type}"`;
  }
  if (search.offline_event_type) {
    searchString += `, offlineEventTypeIn: "${search.offline_event_type}"`;
  }
  if (search.online_event_type) {
    searchString += `, onlineEventTypeIn: "${search.online_event_type}"`;
  }
  if (search.transaction_date) {
    searchString += `, creationDateEqual: "${search.transaction_date}"`;
  }

  const query = `
  {
    transactions(
      first: 20,
      after: "${afterCursor}"${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          date
          transactionType
          customer {
            pk
            ssoUid
            membershipId
          }
          store {
            pk
            name
            id
            brand {
              pk
              name
            }
          }
          onlineEventType
          offlineEventType
          creationDate
          invoiceId
          totalValue
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransaction = (id) => {
  const query = `
  {
    transaction(id: "${id}") {
      id
      pk
      date
      transactionType
      creationDate
      customer {
        pk
        ssoUid
      }
      store {
        pk
        id
        name
      }
      staffName
      onlineEventType
      offlineEventType
      shippingFee
      otherCharge
      totalValue
      remarks
      shopifyOrderId
      shopifyOrderPayload
      posInvoiceId
      invoiceId
      receiptImage
      creditCardSlipImage
      purchasedItems {
        edges {
          node {
            id
            pk
            name
            sku
            category
            brand
            quantity
            value
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const approveTransaction = (id) => {
  const query = `
  mutation ApproveTransaction($input: ApproveTransactionInput!) {
    approveTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectTransaction = (id) => {
  const query = `
  mutation RejectTransaction($input: RejectTransactionInput!) {
    rejectTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const recallTransaction = (id) => {
  const query = `
  mutation RecallTransaction($input: RecallTransactionInput!) {
    recallTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteTransactions = (ids) => {
  const query = `
  mutation DeleteTransactions($input: DeleteTransactionsInput!) {
    deleteTransactions(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createTransaction = (inputBody) => {
  const query = `
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      node {
        id
        pk
        date
        transactionType
        customer {
                  id
              }
        store {
                  id
              }
        staffName
        shippingFee
        otherCharge
        totalValue
        remarks
        posInvoiceId
        invoiceId
        receiptImage
        creditCardSlipImage
        offlineEventType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateTransaction = (inputBody) => {
  const query = `
  mutation UpdateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      node {
        id
        pk
        date
        transactionType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
