import React, { useState, forwardRef } from 'react';
import { Image, Button, Dropdown } from 'react-bootstrap';
import './QRCodeCollapse.scss';
import { downloadZipFiles } from './QRCodeDownloadUtil';

const image =
  'https://aillia-dev-cms-bucket.s3.ap-east-1.amazonaws.com/media/664f13c7-d0b6-46d2-975d-41df2dff6367.png';

const popperConfig = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
        adaptive: false,
      },
    },
  ],
};
const CustomItemToggle = forwardRef(({ source }, ref) => (
  <div className='preview-image-container'>
    <Image
      src={source}
      ref={ref}
      className="preview-image"
      onClick={(e) => {
        e.preventDefault();
      }}
    />
  </div>
));

export default function QRCodeCollapse({
  codeDisplayImage,
  codeDownloadImage,
  codeDownloadImageA5,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      show={isOpen}
      drop="right"
      className="store-qr-code-container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Dropdown.Toggle as={CustomItemToggle} source={codeDisplayImage} />

      <Dropdown.Menu popperConfig={popperConfig}>
        <Dropdown.Item>
          <div className="qr-code-download-container">
            <Image className="qr-code-download-image" src={codeDownloadImage} />
            <div className="button-container">
              <Button
                className="qr-code-download-button btn-further"
                onClick={() => {
                  downloadZipFiles(codeDownloadImage);
                }}
              >
                Download A4 size
              </Button>
              <Button
                className="qr-code-download-button btn-further"
                onClick={() => {
                  downloadZipFiles(codeDownloadImageA5);
                }}
              >
                Download A5 size
              </Button>
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
