import React from 'react';
import CreateCampaign from '../containers/engagement/campaign/CreateCampaign';
import CreateCoupon from '../containers/engagement/coupon/CreateCoupon';
import Dashboard from '../containers/dashboard/Dashboard';
import CampaignItemOverview from '../containers/engagement/campaign/CampaignItemOverview';
import CouponSetDetail from '../containers/engagement/coupon/CouponSetDetail';
import SingleCouponDetail from '../containers/engagement/coupon/SingleCouponDetail';
import CreateEarningRule from '../containers/engagement/earningRule/CreateEarningRule';
import CreateSegment from '../containers/customers/segments/CreateSegment';
import SegmentDetail from '../containers/customers/segments/SegmentDetail';
import CustomerOverviewDetail from '../containers/customers/customer/CustomerOverviewDetail';
import CustomerEditForm from '../containers/customers/customer/CustomerEditForm';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import CreatePointRecord from '../containers/record/pointRecords/CreatePointRecord';
import PointTransactionOverview from '../containers/record/pointRecords/PointTransactionOverview';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import CouponTransactionOverview from '../containers/record/couponRecords/CouponTransactionOverview';
import TransactionOverView from '../containers/record/records/TransactionOverview';
import CreateTransaction from '../containers/record/records/CreateTransaction';
import CreateAdminGroup from '../containers/admin/adminGroups/CreateAdminGroup';
import CreateAdmin from '../containers/admin/admins/CreateAdmin';
import CreateBrand from '../containers/merchants/brands/CreateBrand';
import CreateMessage from '../containers/engagement/message/CreateMessage';
import MessageOverview from '../containers/engagement/message/MessageOverview';
import AdminDetail from '../containers/admin/admins/AdminDetail';
import CampaignCategoryDetail from '../containers/engagement/campaignCategory/CampaignCategoryDetail';
import CreateCampaignCategory from '../containers/engagement/campaignCategory/CreateCampaignCategory';
import StoreDetail from '../containers/merchants/stores/StoreDetail';
import CreateStore from '../containers/merchants/stores/CreateStore';
import StoreCategoryDetail from '../containers/merchants/storeCategory/StoreCategoryDetail';
import CreateStoreCategory from '../containers/merchants/storeCategory/CreateStoreCategory';
import CreateCustomerGroup from '../containers/customers/customerGroup/CreateCustomerGroup';
import { PermissionCodes } from './PermissionCodes';
import CreateBanner from '../containers/homeManage/banners/CreateBanner';
import FeaturedCampaignDetail from '../containers/homeManage/featuredCampaigns/FeaturedCampaignDetail';
import BrandOverView from '../containers/merchants/brands/BrandOverView';
import MarketDetail from '../containers/engagement/markets/MarketDetail';
import CreateMarket from '../containers/engagement/markets/CreateMarket';
import CreateChannel from '../containers/engagement/channel/CreateChannel';
import ChannelDetail from '../containers/engagement/channel/ChannelDetail';
import LogoLabelDetail from '../containers/engagement/logoLabel/LogoLabelDetail';
import CreateLogoLabel from '../containers/engagement/logoLabel/CreateLogoLabel';
import CreateLangauge from '../containers/engagement/language/CreateLanguage';
import LanguageDetail from '../containers/engagement/language/LanguageDetail';
import CreateSurveyForm from '../containers/engagement/surveyForm/CreateSurveyForm';
import SurveyFormDetail from '../containers/engagement/surveyForm/SurveyFormDetail';
import OfferTypeLabelDetail from '../containers/engagement/offerTypeLabel/OfferTypeLabelDetail';
import CreateOfferTypeLabel from '../containers/engagement/offerTypeLabel/CreateOfferTypeLabel';
import AttributeTagDetail from '../containers/engagement/attributeTag/AttributeTagDetail';
import CreateAttributeTag from '../containers/engagement/attributeTag/CreateAttributeTag';
import CampaignDetail from '../containers/engagement/campaign/CampaignDetail';
import GoodieBagDetail from '../containers/engagement/goodieBags/GoodieBagDetail';
import CreateGoodieBag from '../containers/engagement/goodieBags/CreateGoodieBag';
import GoodieBagEntitlement from '../containers/engagement/goodieBags/GoodieBagEntitlement/GoodieBagEntitlement';
import TourCardDetail from '../containers/engagement/tourCard/TourCardDetail';
import CreateTourCard from '../containers/engagement/tourCard/CreateTourCard';
import TermsAndConditionsDetail from '../containers/engagement/termsAndConditions/TermsAndConditionsDetail';
import CreateTermsAndConditions from '../containers/engagement/termsAndConditions/CreateTermsAndConditions';
import PaymentSettlementReportList from '../containers/engagement/paymentSettlement/PaymentSettlementReportList';
import PaymentSettlementReportDetail from '../containers/engagement/paymentSettlement/PaymentSettlementReportDetail';
import InterestPreferenceDetail from '../containers/customers/interestPreference/InterestPreferenceDetail';
import CreateInterestPreference from '../containers/customers/interestPreference/CreateInterestPreference';
import CreateGeneralAlert from '../containers/engagement/message/CreateGeneralAlert';
import GeneralAlertDetail from '../containers/engagement/message/GeneralAlertDetail';
import Entitlement from '../containers/engagement/campaign/FortuneBagEntitlement/Entitlement';
import Home from '../containers/base/Home';
import GeneralAlertList from '../containers/engagement/message/GeneralAlertList';
import OTPChannelDetail from '../containers/customers/otpChannel/OTPChannelDetail';
import CreateOTPChannel from '../containers/customers/otpChannel/CreateOTPChannel';
import CreateWorkingTeam from '../containers/admin/workingTeam/CreateWorkingTeam';
import WorkingTeamDetail from '../containers/admin/workingTeam/WorkingTeamDetail';
import CreateGoodieBagGroup from '../containers/engagement/goodieBagGroup/CreateGoodieBagGroup';
import GoodieBagGroupDetail from '../containers/engagement/goodieBagGroup/GoodieBagGroupDetail';
import CreateCampaignGroup from '../containers/engagement/campaignGroup/CreateCampaignGroup';
import CampaignGroupDetail from '../containers/engagement/campaignGroup/CampaignGroupDetail';

export const pageRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    main: () => <Home />,
  },
  {
    path: ['/campaigns/create'],
    exact: true,
    codename: PermissionCodes.addCampaign,
    main: () => <CreateCampaign />,
  },
  {
    path: ['/campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign />,
  },
  {
    path: ['/campaigns/:id/overview'],
    exact: true,
    name: 'Overview',
    main: () => <CampaignItemOverview />,
  },
  {
    path: ['/campaigns/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <CampaignDetail />,
  },
  {
    path: ['/campaigns/:id/edit/entitlement/'],
    exact: true,
    name: 'Entitlement',
    codename: PermissionCodes.fortunebagEntitlement,
    main: () => <Entitlement />,
  },
  {
    path: ['/campaign_groups/create', '/campaign_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaignGroup,
    main: () => <CreateCampaignGroup />,
  },
  {
    path: ['/campaign_groups/:id/'],
    exact: true,
    codename: PermissionCodes.viewGoodieBagGroup,
    main: () => <CampaignGroupDetail />,
  },
  {
    path: ['/goodie_bags/create'],
    exact: true,
    codename: PermissionCodes.addGoodieBag,
    main: () => <CreateGoodieBag />,
  },
  {
    path: ['/goodie_bags/:id/entitlement/'],
    exact: true,
    name: 'Entitlement',
    codename: PermissionCodes.goodiebagEntitlement,
    main: () => <GoodieBagEntitlement />,
  },
  {
    path: ['/goodie_bags/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeGoodieBag,
    main: () => <CreateGoodieBag />,
  },
  {
    path: ['/goodie_bags/:id/'],
    exact: true,
    codename: PermissionCodes.viewGoodieBag,
    main: () => <GoodieBagDetail />,
  },
  {
    path: ['/goodie_bag_groups/create', '/goodie_bag_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeGoodieBagGroup,
    main: () => <CreateGoodieBagGroup />,
  },
  {
    path: ['/goodie_bag_groups/:id/'],
    exact: true,
    codename: PermissionCodes.viewGoodieBagGroup,
    main: () => <GoodieBagGroupDetail />,
  },
  {
    path: ['/coupons/create', '/coupons/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCouponTemplate,
    main: () => <CreateCoupon />,
  },
  {
    path: ['/coupons/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTemplate,
    main: () => <CouponSetDetail />,
  },
  {
    path: [
      '/coupons/couponSet/:couponSetId/singleCoupon/:singleCouponId',
      '/coupons/:id/:singleCouponId/edit',
    ],
    exact: true,
    codename: PermissionCodes.viewCoupon,
    main: () => <SingleCouponDetail />,
  },
  {
    name: 'Create Earning Rule',
    path: '/earns/create',
    exact: true,
    codename: PermissionCodes.addEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Edit Earning Rule',
    path: ['/earns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Create Segment',
    path: ['/segments/create/'],
    exact: true,
    codename: PermissionCodes.addSegment,
    main: () => <CreateSegment />,
  },
  {
    path: ['/segments/:id/'],
    exact: true,
    codename: PermissionCodes.viewSegment,
    main: () => <SegmentDetail />,
  },
  {
    path: ['/segments/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeSegment,
    main: () => <CreateSegment />,
  },

  {
    path: ['/customers/:id/'],
    exact: true,
    codename: PermissionCodes.viewCustomer,
    main: () => <CustomerOverviewDetail />,
  },
  {
    path: ['/customers/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomer,
    main: () => <CustomerEditForm />,
  },
  {
    name: 'Update Point record',
    path: ['/point_records/create', '/point_records/:id/edit'],
    exact: true,
    codename: PermissionCodes.changePointtransaction,
    main: () => <CreatePointRecord />,
  },
  {
    name: 'View Point record',
    path: ['/point_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewPointtransaction,
    main: () => <PointTransactionOverview />,
  },
  {
    name: 'View Coupon records',
    path: ['/coupon_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTransaction,
    main: () => <CouponTransactionOverview />,
  },
  {
    name: 'Create Transaction',
    path: ['/transactions/create'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'Edit Transaction',
    path: ['/transactions/:id/edit'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'View transaction',
    path: ['/transactions/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <TransactionOverView />,
  },
  {
    name: 'Create Administrators group',
    path: ['/administrator_groups/create'],
    exact: true,
    codename: PermissionCodes.addAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    path: ['/administrator_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    name: 'Create Administrators',
    path: ['/administrators/create'],
    exact: true,
    codename: PermissionCodes.addAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    path: ['/administrators/:id/'],
    exact: true,
    codename: PermissionCodes.viewAdministrator,
    main: () => <AdminDetail />,
  },

  {
    path: ['/administrators/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    name: 'Brand',
    path: ['/brands/create'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    path: ['/brands/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    path: ['/brands/:id/'],
    exact: true,
    codename: PermissionCodes.viewBrand,
    main: () => <BrandOverView />,
  },
  {
    name: 'Create Message',
    path: ['/messages/create'],
    exact: true,
    codename: PermissionCodes.addMessage,
    main: () => <CreateMessage />,
  },
  {
    path: ['/messages/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeMessage,
    main: () => <CreateMessage />,
  },
  {
    name: 'View Message',
    path: ['/messages/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <MessageOverview />,
  },
  {
    name: 'Create campaign category',
    path: ['/campaign_category/create'],
    exact: true,
    codename: PermissionCodes.addCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaignCategory,
    main: () => <CampaignCategoryDetail />,
  },

  {
    name: 'Create store',
    path: ['/stores/create'],
    exact: true,
    codename: PermissionCodes.addStore,
    main: () => <CreateStore />,
  },
  {
    path: ['/stores/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStore,
    main: () => <CreateStore />,
  },
  {
    path: ['/stores/:id/'],
    exact: true,
    codename: PermissionCodes.viewStore,
    main: () => <StoreDetail />,
  },

  {
    name: 'Create store category',
    path: ['/store_category/create'],
    exact: true,
    codename: PermissionCodes.addStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    path: ['/store_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewSorecategory,
    main: () => <StoreCategoryDetail />,
  },
  {
    path: ['/store_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    name: 'Create Customer Group',
    path: ['/customer_group/create'],
    exact: true,
    codename: PermissionCodes.addCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/customer_group/:id/', '/customer_group/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/banners/create', '/banners/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/home_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <FeaturedCampaignDetail />,
  },
  {
    path: ['/logo_label/create', '/logo_label/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeLogoLabel,
    main: () => <CreateLogoLabel />,
  },
  {
    path: ['/logo_label/:id/'],
    exact: true,
    codename: PermissionCodes.viewLogoLabel,
    main: () => <LogoLabelDetail />,
  },
  {
    path: ['/markets/create', '/markets/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeTargetMarket,
    main: () => <CreateMarket />,
  },
  {
    path: ['/markets/:id/'],
    exact: true,
    codename: PermissionCodes.viewTargetMarket,
    main: () => <MarketDetail />,
  },
  {
    path: ['/channels/create', '/channels/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeChannel,
    main: () => <CreateChannel />,
  },
  {
    path: ['/channels/:id/'],
    exact: true,
    codename: PermissionCodes.viewChannel,
    main: () => <ChannelDetail />,
  },
  {
    path: ['/languages/create', '/languages/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeLanguage,
    main: () => <CreateLangauge />,
  },
  {
    path: ['/languages/:id/'],
    exact: true,
    codename: PermissionCodes.viewLanguage,
    main: () => <LanguageDetail />,
  },
  {
    path: ['/survey_forms/create', '/survey_forms/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeSurveyForm,
    main: () => <CreateSurveyForm />,
  },
  {
    path: ['/survey_forms/:id/'],
    exact: true,
    codename: PermissionCodes.viewSurveyForm,
    main: () => <SurveyFormDetail />,
  },
  {
    path: ['/offer_type_labels/create', '/offer_type_labels/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeOfferTypeLabel,
    main: () => <CreateOfferTypeLabel />,
  },
  {
    path: ['/offer_type_labels/:id/'],
    exact: true,
    codename: PermissionCodes.viewOfferTypeLabel,
    main: () => <OfferTypeLabelDetail />,
  },
  {
    path: ['/tags/create', '/tags/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeTagKey,
    main: () => <CreateAttributeTag />,
  },
  {
    path: ['/tags/:id/'],
    exact: true,
    codename: PermissionCodes.viewTagKey,
    main: () => <AttributeTagDetail />,
  },
  {
    path: ['/tour_cards/create', '/tour_cards/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeTourCard,
    main: () => <CreateTourCard />,
  },
  {
    path: ['/tour_cards/:id/'],
    exact: true,
    codename: PermissionCodes.viewTourCard,
    main: () => <TourCardDetail />,
  },
  {
    path: ['/terms_and_conditions/create', '/terms_and_conditions/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeTermsAndConditions,
    main: () => <CreateTermsAndConditions />,
  },
  {
    path: ['/terms_and_conditions/:id/'],
    exact: true,
    codename: PermissionCodes.viewTermsAndConditions,
    main: () => <TermsAndConditionsDetail />,
  },
  {
    path: ['/payment_settlement/byMonthly/:reportName/'],
    exact: true,
    codename: PermissionCodes.paymentSettlement,
    main: () => <PaymentSettlementReportList />,
  },
  {
    path: ['/payment_settlement/byMerchant/:merchantAdministrators/'],
    exact: true,
    codename: PermissionCodes.paymentSettlement,
    main: () => <PaymentSettlementReportList />,
  },
  {
    path: ['/payment_settlement/detail/:id/'],
    exact: true,
    codename: PermissionCodes.paymentSettlement,
    main: () => <PaymentSettlementReportDetail />,
  },
  {
    path: ['/interest_preferences/create', '/interest_preferences/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeInterestPreference,
    main: () => <CreateInterestPreference />,
  },
  {
    path: ['/interest_preferences/:id/'],
    exact: true,
    codename: PermissionCodes.viewInterestPreference,
    main: () => <InterestPreferenceDetail />,
  },
  {
    path: ['/general_alert'],
    name: 'General Alert',
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <GeneralAlertList />,
  },
  {
    path: ['/general_alert/create', '/general_alert/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeMessage,
    main: () => <CreateGeneralAlert />,
  },
  {
    path: ['/general_alert/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <GeneralAlertDetail />,
  },
  {
    path: ['/otp_channel/:id/'],
    exact: true,
    codename: PermissionCodes.viewOtpChannel,
    main: () => <OTPChannelDetail />,
  },
  {
    path: ['/otp_channel/create', '/otp_channel/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeOtpChannel,
    main: () => <CreateOTPChannel />,
  },
  {
    path: ['/working_teams/create', '/working_teams/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeWorkingTeam,
    main: () => <CreateWorkingTeam />,
  },
  {
    path: ['/working_teams/:id/'],
    exact: true,
    codename: PermissionCodes.viewWorkingTeam,
    main: () => <WorkingTeamDetail />,
  },
];
