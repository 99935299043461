import React, { useEffect, useState } from 'react';
import './CustomerList.scss';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import Filter from '../../../components/customer/Filter';
import { ImportResource } from '../../../models/DownloadImportModel';
import { createAction, getHashKeyString } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import DateRangeCompare from '../../../components/dashboard/DateRangeCompare';
import DateRangeSelection from '../../../components/dashboard/DateRangeSelection';
import CustomerOverview from '../../../components/customer/CustomerOverview';
import {
  DELETE_RELATED_SECTIONS,
  LanguageTransLation,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import CustomFileUploader from '../../../components/base/CustomFileUploader';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { TimeFormater, formatDate } from '../../../utils/TimeFormatUtil';
import CustomRadios from '../../../components/base/CustomRadios';
import { Row } from 'react-bootstrap';

const TAB_OVERVIEW = 'Overivew';
const TAB_LIST_OF_CUSTOMER = 'List of Customers';

function CustomerList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);
  const [datePicker, setDatePicker] = useState();
  const activeTab = getHashKeyString(location.hash) || TAB_LIST_OF_CUSTOMER;
  const [promptConfig, setPromptConfig] = useState();
  const [includeFilter] = useState({ isInclude: false });
  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
    filters,
    importedFile,
    isSuperuser,
    userPermissions,
  } = useSelector((state) => ({
    // levelTabList: state.levels.levelTabList || [
    //   { key: 'all', name: 'All Types' },
    // ],
    listFields: state.customerList.listDisplayFields,
    dataList: state.customerList.pagedCustomerList,
    pageInfo: state.customerList.pageInfo,
    totalPage: state.customerList.totalPage,
    totalCount: state.customerList.totalCount,
    checkedList: state.customerList.checkedList,
    filters: state.customerList.filters,
    importedFile: state.uploadFiles.importedFile,
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const hiddenFileInput = React.useRef(null);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    console.log('@@77');
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };
  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const exportData = {};
    if (parsedSearch.interest_preference) {
      exportData.interest_preference =
        parsedSearch.interest_preference.split(',');
    }
    if (parsedSearch.age) {
      exportData.age = parsedSearch.age;
    }
    if (parsedSearch.start_date) {
      exportData.from_date = formatDate(
        parsedSearch.start_date,
        TimeFormater.GTMFormat,
      );
    }
    if (parsedSearch.end_date) {
      exportData.to_date = formatDate(
        parsedSearch.end_date,
        TimeFormater.GTMFormat,
      );
    }
    if (parsedSearch.search) {
      exportData.search = parsedSearch.search;
    }

    const related = !includeFilter.isInclude
      ? {}
      : {
          filterConditions: JSON.stringify(exportData),
        };

    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: { from: ImportResource.customer, related: related },
    });
    includeFilter.isInclude = false;
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Export .csv"
          action={() => setPromptConfig(exportConfig)}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title={'Import .csv'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => setPromptConfig(exportConfig),
          content: 'Export .csv',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import .csv',
        },
      ]}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCustomer,
    },
  ];

  const tabs = [
    isSuperuser ||
    userPermissions?.filter(
      (val) => val.split('_')[1] === PermissionCodes.customer,
    ).length > 0
      ? {
          name: TAB_LIST_OF_CUSTOMER,
          content: (
            <BaseTabListContainer
              hideTab={true}
              tabs={[]}
              groupActions={groupActions}
              // defaultTabKey={levels}
              pageInfo={pageInfo}
              totalCount={totalCount}
              filter={{
                hasFilter: true,
                componment: Filter,
              }}
              searchPlaceholder="Search by membership ID"
              listContent={
                <BaseListContainer
                  fields={listFields}
                  dataList={dataList}
                  totalPage={totalPage}
                  model={'customerList'}
                  permissionGroup={PermissionCodes.customer}
                  deleteInfo={{
                    data: [],
                    title: 'customers',
                    relatedName: '',
                    relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER,
                    onComfirm: {},
                  }}
                  hasOtherActions={true}
                  customActions={{
                    deactive: (item) => {
                      dispatch(
                        createAction('customerList/updateCustomerActive')({
                          data: {
                            isForcedInactive: item.isForcedInactive,
                            id: item.pk,
                          },
                          afterAction: () => history.push(location),
                        }),
                      );
                    },
                  }}
                />
              }
            />
          ),
        }
      : {},
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  // useEffect(() => {
  //   // dispatch(createAction('levels/getLevelList')({}));
  //   // dispatch(
  //   //   createAction('customerList/getPagedCustomers')({
  //   //     page: 0,
  //   //     reverse: true,
  //   //     search: '',
  //   //   }),
  //   // );
  // }, [dispatch]);

  useEffect(() => {
    // console.log('@@202: ', filters);
    if (activeTab === TAB_LIST_OF_CUSTOMER) {
      const parsedSearch = queryString.parse(location.search);

      const searchKey = parsedSearch['search'] || '';
      const stringRank = parsedSearch['rank'] || 'true';
      const stringPage = parsedSearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      console.log('@@202: ', parsedSearch);
      dispatch(
        createAction('customerList/getPagedCustomers')({
          ...parsedSearch,
          page,
          reverse: rank,
          search: searchKey,
        }),
      );
    }
  }, [dispatch, location, activeTab]);

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.customer,
          file: importedFile,
        }),
      );
    }
  }, [dispatch, importedFile]);

  const { importError } = useSelector((state) => ({
    importError: state.downloadAndImport.error || {},
  }));

  const importConfig = {
    title: importError.title,
    content: importError.content,
    closeAction: () => {
      setPromptConfig(null);
      dispatch({
        type: 'downloadAndImport/updateState',
        payload: { error: {} },
      });
    },
    leftButton: {
      text: 'Cancel',
      action: () => {
        setPromptConfig(null);
        dispatch({
          type: 'downloadAndImport/updateState',
          payload: { error: {} },
        });
      },
    },
    rightButton: {
      text: 'Import again',
      action: () => {
        handleImportClick();
        setPromptConfig(null);
        dispatch({
          type: 'downloadAndImport/updateState',
          payload: { error: {} },
        });
      },
      requires: PermissionCodes.addImportjob,
    },
  };

  console.log('setIncludeFilterRender:', includeFilter);

  const exportFilterChoice = (
    <>
      <label className={`create-section-label landing-title-margin-bottom `}>
        {'Select the export range'}
      </label>
      <Row style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}>
        <CustomRadios
          onChange={(value) => {
            console.log('CustomRadios:', value);
            includeFilter.isInclude = value;
          }}
          default={includeFilter.isInclude}
          options={[
            {
              label: 'All items',
              value: false,
            },
            {
              label: 'All filtered items from the list',
              value: true,
            },
          ]}
        />
      </Row>
    </>
  );

  const exportConfig = {
    title: 'Export Customer (.csv)',
    content: importError.content,
    closeAction: () => {
      setPromptConfig(null);
      includeFilter.isInclude = false;
    },
    leftButton: {
      text: 'Cancel',
      action: () => {
        setPromptConfig(null);
        includeFilter.isInclude = false;
      },
    },
    rightButton: {
      text: 'Export',
      action: () => {
        exportCSVAction();
        setPromptConfig(null);
      },
      requires: PermissionCodes.addExportjob,
    },
    otherBody: () => {
      return <div>{exportFilterChoice}</div>;
    },
  };

  useEffect(() => {
    if (Object.keys(importError).length > 0) {
      setPromptConfig(importConfig);
    }
  }, [importError]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All the customers are listed here, and you can view their details and records. You can learn about the user usage of the app here.',
          title: NavBarNames.customer,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
        // onTabChange={(key) => {
        //   // setActiveTab(key);
        //   history.push({ pathname: location.pathname, hash: key });
        // }}
      />

      <DeletePrompt
        data={checkedList}
        title={'customers'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('customerList/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />

      <BasePrompt
        show={!!promptConfig}
        title={promptConfig?.title}
        description={promptConfig?.description}
        closeAction={promptConfig?.closeAction}
        leftButton={promptConfig?.leftButton}
        rightButton={promptConfig?.rightButton}
        otherBody={promptConfig?.otherBody}
      />
    </>
  );
}

export default CustomerList;
