import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { BannerLocation, BannerType } from '../../../models/HomeBannersModel';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['banner_type'] || '';
  const searchPlatform = parsedSearch['platform'] || '';
  const searchLandingPage = parsedSearch['landing_page'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [type, setType] = useState(ArrayStringData(searchType));
  const [platform, setPlatform] = useState(ArrayStringData(searchPlatform));
  const [landingPage, setLandingPage] = useState(searchLandingPage);
  const [status, setStatus] = useState(searchStatus);

  const content = [
    {
      title: 'Banner type',
      data: [
        { pk: BannerType.HOME_PAGE, name: 'Homepage' },
        { pk: BannerType.GOODIE_BAG, name: 'My Reward page for Goodie bag' },
        {
          pk: BannerType.GOODIE_BAG_PROMPT,
          name: 'Prompt for Goodie bag',
        },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Platform',
      data: [
        { pk: BannerLocation.HOME_PAGE, name: 'e-Solution website' },
        { pk: BannerLocation.WECHAT, name: 'e-Solution mini program' },
      ],
      value: platform,
      setValue: setPlatform,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Landing page',
      data: [
        { name: 'YES', pk: 'MANUAL_INPUT_URL' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'NO', pk: 'NO_LANDING_PAGE' },
      ],
      value: landingPage,
      setValue: setLandingPage,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
