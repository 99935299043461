import React, { useState, useRef, useEffect } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import AlertInfoPrompt from '../base/prompt/AlertInfoPrompt';
import { addDomainToImage } from '../../utils';
import { Link } from 'react-router-dom';
import CustomViewer from '../base/CustomViewer';
import './MembershipInfoCard.scss';

function MembershipInfoCard({ title, data, fields, extraDataFields }) {
  const tableRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [promptInfo, setPromptInfo] = useState({});
  const [tableWidth, setTableWidth] = useState(800);
  const tableOffset = tableRef.current?.offsetWidth;
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableOffset]);

  const getDisplayValue = (field) => {
    let value = data?.[field];
    if (Array.isArray(value)) {
      // value = value.slice(0, 10).join('\r\n');
      value = value.slice(0, 10).map((v) => <li>{v}</li>);
    }

    if (field === 'mobileNumber') {
      value = value?.replace('\r\n', ' ');
    }

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
      if (Array.isArray(value)) {
        value = value.slice(0, 10).join('\r\n');
      }
    }

    return value || '-';
  };

  const needShowMore = (field) => {
    if (field === 'goodieBagCouponCampaigns') {
      return false;
    }
    let value = data?.[field];
    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }
    return Array.isArray(value) && value.length > 10;
  };

  const showMore = (field) => {
    let title, subTitle, content;
    if (field === 'coupons') {
      title = 'Coupon';
      subTitle = 'The following coupon belong to this customer';
    }

    if (field === 'availableCampaign') {
      title = 'Campaign';
      subTitle = 'The user can see the following activities';
    }

    if (field === 'displayPermissions') {
      title = 'Permission';
      subTitle = 'The user has the following permissions';
    }

    if (field === 'tags') {
      title = 'Linked with Attribute Tag';
      subTitle = 'The following attribute tags belong to this category';
    }

    let value = data[field];

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }

    content = (
      <ul>
        {value.map((item, index) =>
          item.id ? (
            <a
              className="channels-show-more-content"
              href={
                field === 'relatedCampaigns' ? `/campaigns/${item.id}/` : '#'
              }
            >
              <li key={index}>{item.name}</li>
            </a>
          ) : (
            <li key={index}>{item}</li>
          ),
        )}
      </ul>
    );

    setPromptInfo({ title, subTitle, content });
    setShowPrompt(true);
  };

  const textBody = (column) => {
    if (column.linkTo) {
      if (data[column.field]?.length > 0) {
        return data[column.field]?.slice(0, 10).map((item) => (
          <Link
            className="detail-page-link"
            to={`/${column.linkTo}/${item.id}/`}
          >
            <div className="dot dot-blue" />
            <label className="link-label-with-dot">{`[ID: ${item.id}] ${item.name}`}</label>
          </Link>
        ));
      }
      return '-';
    }
    if (column.link) {
      return (
        <a
          style={{ textDecoration: 'underline' }}
          className="custom-record-normal-font custom-timeline-time-color list-new-line"
          href={column.link}
        >
          {getDisplayValue(column.field)}
        </a>
      );
    }
    if (column.isHtml) {
      return <CustomViewer initValue={getDisplayValue(column.field)} />;
    }

    const getColumnValue = (column) => {
      if (
        (column.title.includes('image') || column.title === 'Image') &&
        getDisplayValue(column.field) !== '-'
      ) {
        if (Array.isArray(data[column.field])) {
          return (
            <>
              {data[column.field].map((item) => {
                return (
                  <img
                    alt="img"
                    src={addDomainToImage(item)}
                    style={{
                      maxWidth: 191,
                      maxHeight: 191,
                      marginRight: '20px',
                      marginBottom: '20px',
                    }}
                  />
                );
              })}
            </>
          );
        } else {
          return (
            <img
              alt="img"
              src={addDomainToImage(getDisplayValue(column.field))}
              style={{ maxWidth: 191, maxHeight: 191 }}
              className={column?.customClass}
            />
          );
        }
      } else if (
        column.title === 'Tour card' &&
        getDisplayValue(column.field) !== '-'
      ) {
        return (
          <div className="membershipInfo-tourCard">
            {data[column.field].map((item, index) => {
              return (
                <div
                  key={index}
                  className="membershipInfo-tourCard-titleWithPhoto"
                >
                  <img
                    alt="img"
                    src={addDomainToImage(item.coverPhoto)}
                    style={{ maxWidth: 191, maxHeight: 191 }}
                  />
                  <label className="membershipInfo-tourCard-title">
                    {item.title}
                  </label>
                </div>
              );
            })}
          </div>
        );
      } else if (
        (column.title === 'Description' ||
          column.title.toLowerCase().indexOf('short description') >= 0 ||
          column.title === 'Offer detail content' ||
          column.title === 'Instruction section content' ||
          column.title === 'Merchant Terms & Conditions Content' ||
          column.title === 'Generic Terms & Conditions Content') &&
        getDisplayValue(column.field) !== '-'
      ) {
        return <CustomViewer initValue={data[column.field]} />;
      } else if (column.title === 'Link to coupon campaign') {
        if (Object.values(data?.[column.field] || {})?.flat()?.length <= 0) {
          return '-';
        }

        // if (data?.[column.field]?.length <= 0) {
        //   return '-';
        // }
        return (
          <div
            className="goodie-bag-view-linked-coupon-campaign"
            onClick={() => {
              setPromptInfo({
                title: 'Linked coupon campaigns',
                subTitle: null,
                content: (
                  <>
                    {Object.keys(data?.[column.titleField] || {})?.map(
                      (key, index) => {
                        return (
                          <>
                            <CustomTitleLabel
                              title={`Slot ${index + 1} - ${
                                data?.[column.titleField]?.[key].en.name || ''
                              }`}
                            />

                            {data?.[column.field]?.[key]?.length > 0 ? (
                              data?.[column.field]?.[key]?.map((item) => (
                                <div className="goodie-bag-view-linked-coupon-campaign-single">
                                  [{item?.pk}] {item?.name}
                                </div>
                              ))
                            ) : (
                              <div className="goodie-bag-view-linked-coupon-campaign-single">
                                -
                              </div>
                            )}
                          </>
                        );
                      },
                    )}
                    {/* {data?.[column.field]?.map((item) => (
                      <div className="goodie-bag-view-linked-coupon-campaign-single">
                        [{item?.pk}] {item?.name}
                      </div>
                    ))} */}
                  </>
                ),
              });
              setShowPrompt(true);
            }}
          >
            View linked coupon campaign
          </div>
        );
      } else {
        return getDisplayValue(column.field);
      }
    };

    return (
      <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
        {getColumnValue(column)}
      </label>
    );
  };

  return (
    <>
      <label className="create-section-title">{title}</label>

      <table style={{ tableLayout: 'fixed', width: '100%' }} ref={tableRef}>
        {fields.map((row) => {
          return (
            <tr>
              {row?.map((column) => {
                if (column.componment) {
                  return column.componment;
                }

                const textMain = textBody(column);
                return (
                  <td
                    style={{
                      display: 'inline-grid',
                      width: `${tableWidth / row.length}px`,
                      wordBreak: `break-word`,
                    }}
                  >
                    <CustomTitleLabel title={column.title} />
                    {column.title === 'Brand' &&
                    column.showTips === undefined ? (
                      <label className="tips-message">
                        Once set, cannot be changed.
                      </label>
                    ) : null}
                    {textMain}
                    {needShowMore(column.field) ? (
                      <button
                        type="button"
                        className="btn-link reset-button show-more-button channel-show-more-button"
                        onClick={() => {
                          showMore(column.field, data[column.field]);
                        }}
                      >
                        show more
                      </button>
                    ) : null}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
      <AlertInfoPrompt
        show={showPrompt}
        onCancel={() => setShowPrompt(false)}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => setShowPrompt(false)}
        title={promptInfo.title}
        subTitle={promptInfo.subTitle}
        content={promptInfo.content}
      />
    </>
  );
}

export default MembershipInfoCard;
