import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { GoodieBagRewardType } from '../../../config/CustomEnums';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { GOODIE_BAG_ENTITLEMENT_METHOD_TYPE } from '../../../models/GoodieBagsModel';
import { useSelector, useDispatch } from 'react-redux';
import { ArrayStringData } from '../../../utils/index';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { goodieBagGroupList } = useSelector((state) => ({
    goodieBagGroupList: state.goodieBagGroups.notPagedAllList,
  }));

  const parsedSearch = queryString.parse(location.search);
  const searchGoodieBagRewardType = parsedSearch['type'] || '';
  const searchCouponOutOfStock = parsedSearch['coupon_out_of_stock'] || '';
  const searchMethod = parsedSearch['entitlement_method'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchVisiblePeriod = parsedSearch['visible_period'] || '';
  const searchCouponAcquiredPeriodType =
    parsedSearch['coupon_acquired_period'] || '';
  const searchAbsolutePeriod = parsedSearch['absolutePeriod'] || '';
  const searchRelativeDay = parsedSearch['relativeDay'] || '';
  const searchCreateAt = parsedSearch['create_at'] || '';
  const searchLastModified = parsedSearch['last_modified'] || '';
  const searchStatus = parsedSearch['status'] || '';
  const searchGoodieBagGroup = parsedSearch['goodie_bag_group'] || '';

  const [goodieBagRewardType, setGoodieBagRewardType] = useState(
    searchGoodieBagRewardType,
  );
  const [couponOutOfStock, setCouponOutOfStock] = useState(
    searchCouponOutOfStock,
  );
  const [method, setMethod] = useState(searchMethod);
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visiblePeriod, setVisiblePeriod] = useState(searchVisiblePeriod);
  const [couponAcquiredPeriodType, setCouponAcquiredPeriodType] = useState(
    searchCouponAcquiredPeriodType,
  );
  const [absolutePeriod, setAbsolutePeriod] = useState(searchAbsolutePeriod);
  const [relativeDay, setRelativeDay] = useState(searchRelativeDay);
  const [createAt, setCreateAt] = useState(searchCreateAt);
  const [lastModified, setLastModified] = useState(searchLastModified);
  const [status, setStatus] = useState(searchStatus);
  const [goodieBagGroupData, setGoodieBagGroupData] = useState(null);
  const [goodieBagGroup, setGoodieBagGroup] = useState(
    ArrayStringData(searchGoodieBagGroup),
  );

  useEffect(() => {
    dispatch({
      type: 'goodieBagGroups/getAllList',
      payload: {},
    });
  }, []);

  useEffect(() => {
    setGoodieBagGroupData(goodieBagGroupList);
  }, [goodieBagGroupList]);

  const content = [
    {
      displayTitle: 'Goodie bag reward type',
      title: 'type',
      data: [
        { pk: GoodieBagRewardType.cashVoucher, name: 'Cash voucher' },
        { pk: GoodieBagRewardType.gift, name: 'Goodie bag coupon' },
        {
          pk: GoodieBagRewardType.both,
          name: 'Both cash voucher and goodie bag coupon',
        },
        {
          pk: GoodieBagRewardType.fortuneBag,
          name: 'Goodie Bag Offer (Direct Assign)',
        },
      ],
      value: goodieBagRewardType,
      setValue: setGoodieBagRewardType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      displayTitle: 'Goodie bag stock status',
      title: 'Coupon out of stock',
      data: [
        { pk: 'true', name: 'Out-of-stock Goodie Bag Offer (Direct Assign)' },
      ],
      value: couponOutOfStock,
      setValue: setCouponOutOfStock,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Entitlement method',
      data: [
        { pk: GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionA, name: 'Option A' },
        { pk: GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB, name: 'Option B' },
        { pk: GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionC, name: 'Option C' },
      ],
      value: method,
      setValue: setMethod,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Goodie bag group',
      data: goodieBagGroupData ? goodieBagGroupData : goodieBagGroupList,
      value: goodieBagGroup,
      setValue: setGoodieBagGroup,
      placeholder: 'Search by ID, Name',
      componment: FilterTypeEnum.searchableChoice,
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = goodieBagGroupList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setGoodieBagGroupData(newData);
      },
    },
    {
      title: 'Active Period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible Period',
      value: visiblePeriod,
      setValue: setVisiblePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Coupon acquired period',
      data: [
        { name: 'All time', pk: 'allTime', subComponment: null },
        {
          name: 'Time Range',
          pk: 'absolutePeriod',
          subComponment: {
            value: absolutePeriod,
            setValue: setAbsolutePeriod,
            componment: FilterTypeEnum.dateRange,
          },
        },
        {
          name: 'Since acquiring',
          pk: 'relativeDay',
          subComponment: {
            value: relativeDay,
            label: 'days',
            setValue: setRelativeDay,
            componment: FilterTypeEnum.inputWithLabel,
          },
        },
      ],
      value: couponAcquiredPeriodType,
      setValue: setCouponAcquiredPeriodType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Create at',
      value: createAt,
      setValue: setCreateAt,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Last modified',
      value: lastModified,
      setValue: setLastModified,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
