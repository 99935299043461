import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import './CouponTransaction.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ADMIN_TYPE } from '../../../config/CustomEnums';
// import ExportCouponView, {
//   EXPORT_BY,
// } from '../../../components/coupon/couponList/ExportCouponView';
import BatchFilterExportPrompt from '../../../components/base/prompt/BatchFilterExportPrompt';

function CouponTransaction() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    tableFields,
    merchantTableFields,
    dataList,
    pageInfo,
    totalCount,
    totalPage,
    currentPage,
    userAdminType,
  } = useSelector((state) => ({
    tableFields: state.couponTransactions.listDisplayFields,
    merchantTableFields: state.couponTransactions.merchantListDisplayFields,
    dataList: state.couponTransactions.currentPageCouponTransactionList,
    pageInfo: state.couponTransactions.pageInfo,
    totalCount: state.couponTransactions.totalCount,
    totalPage: state.couponTransactions.totalPage,
    currentPage: state.couponTransactions.currentPage,
    userAdminType: state.users.adminType,
  }));

  const [showExportView, setShowExportView] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    // const couponTransactionType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('couponTransactions/getCurrentPageCouponTransactions')({
        ...searchDict,
        // couponTransactionType,
        searchKey,
        rank,
        page,
      }),
    );
  }, [dispatch, location, queryString]);
  const viewCouponSetAction = () => {
    history.push({
      pathname: '/coupons',
      search: queryString.stringify({
        page: 1,
        rank: true,
        searchKey: '',
      }),
    });
  };

  const getQueryInfo = () => {
    const search = queryString.parse(location.search);
    const searchDict = {};
    if (search.searchKey || search.search) {
      searchDict['search'] = search.searchKey || search.search;
    }

    if (search.brand) {
      // searchString += `, brandIn: "${search.brand}"`;
      // searchDict['brand_in'] = search.brand.split(',');
      searchDict['brand_in'] = search.brand;
    }
    if (search.campaign) {
      // searchString += `, campaignIn: "${search.campaign}"`;
      // searchDict['campaign_in'] = search.campaign.split(',');
      searchDict['campaign_in'] = search.campaign;
    }
    if (search.campaign_active_period) {
      const campaignActivePeriod = search.campaign_active_period.split(',');
      if (campaignActivePeriod[0]) {
        searchDict['campaign_active_gte'] = campaignActivePeriod[0];
      }
      if (campaignActivePeriod[1]) {
        searchDict['campaign_active_lte'] = campaignActivePeriod[1];
      }
    }
    if (search.campaign_active_status) {
      searchDict['campaign_is_expired'] = search.campaign_active_status;
    }
    if (search.coupon_set) {
      // searchDict['coupon_set_in'] = search.coupon_set.split(',');
      searchDict['coupon_set_in'] = search.coupon_set;
    }
    if (search.coupon_valid_period) {
      const couponValidPeriod = search.coupon_valid_period.split(',');
      if (couponValidPeriod[0]) {
        searchDict['absolute_gte'] = couponValidPeriod[0];
      }
      if (couponValidPeriod[1]) {
        searchDict['absolute_lte'] = couponValidPeriod[1];
      }
    }

    if (search.redemption_method) {
      // searchDict['redemption_method_in'] = search.redemption_method.split(',');
      searchDict['redemption_method_in'] = search.redemption_method;
    }

    if (search.report_period) {
      const reportPeriod = search.report_period.split(',');
      if (reportPeriod[0]) {
        searchDict['report_date_gte'] = reportPeriod[0];
      }
      if (reportPeriod[1]) {
        searchDict['report_date_lte'] = reportPeriod[1];
      }
    }
    if (search.single_coupon) {
      // searchDict['single_coupon_in'] = search.single_coupon.split(',');
      searchDict['serial_number_icontains'] = search.single_coupon;
    }
    if (search.single_coupon_status) {
      // searchDict['single_coupon_status_in'] =
      //   search.single_coupon_status.split(',');
      searchDict['single_coupon_status_in'] = search.single_coupon_status;
    }
    if (search.transaction_type) {
      // searchDict['type_in'] = search.transaction_type.split(',');
      searchDict['type_in'] = search.transaction_type;
    }
    if (search.starred_campaign) {
      searchDict['campaign_is_starred'] = search.starred_campaign;
    }
    if (search.reference_id) {
      searchDict['reference_id'] = search.reference_id;
    }
    if (search.coupon_medium) {
      searchDict['coupon_medium_in'] = search.coupon_medium;
    }

    if (search.campaign_type) {
      searchDict['campaign_type_in'] = search.campaign_type;
    }
    if (search.goodie_bag_reward_type) {
      searchDict['reward_type_in'] = search.goodie_bag_reward_type;
    }

    return searchDict;
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Export .csv"
          customClass="btn-back-button-common btn-download"
          action={() => setShowExportView(true)}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      // extraPopContent={[
      //   {
      //     requires: PermissionCodes.addExportjob,
      //     action: exportCSVAction,
      //     content: 'Export .csv',
      //   },
      // ]}
      primaryButton={
        <AuthButton
          title="View Coupon Set"
          action={viewCouponSetAction}
          requires={PermissionCodes.viewCoupon}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.viewCoupon,
        action: viewCouponSetAction,
        content: 'View Coupon Set',
      }}
    />,
  ];

  const couponRecordTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'EARN', name: 'Customer earned' },
    { key: 'BUY', name: 'Customer Acquired' },
    { key: 'GRANT', name: 'Admin added' },
    { key: 'RECLAIM', name: 'Admin removed' },
  ];

  const tableTabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={couponRecordTypeTabs}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          filter={{ hasFilter: true, componment: Filter }}
          searchPlaceholder="Search by membership ID"
          listContent={
            <BaseListContainer
              hideCheckbox
              fields={
                userAdminType === ADMIN_TYPE.MERCHANT_ADMIN
                  ? merchantTableFields
                  : tableFields
              }
              model={'couponTransactions'}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View details',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All coupon records are listed here, you can view the coupon transactions of each customer.',
          title: 'Coupon records',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        tabs={tableTabs}
        hideTab={true}
      />
      {/* <ExportCouponView
        show={showExportView}
        onHide={() => {
          setShowExportView(false);
        }}
        exportBy={EXPORT_BY.campaign}
      /> */}
      <BatchFilterExportPrompt
        show={showExportView}
        onClose={() => setShowExportView(false)}
        title={'Export report'}
        otherBody={{
          // rangeTitle: 'Select “General Message campaign” and “Coupon campaign”',
          reportSperate: true,
        }}
        exportSource={ImportResource.couponRecord}
        filterRelated={getQueryInfo()}
        sperateBy={'Coupon set'}
        total={totalCount}
      />
    </>
  );
}

export default CouponTransaction;
