import React, { useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import './CustomDropDownButton.scss';
import NarrowUp from '../../assets/images/narrowBlue.svg';

export function CustomDropDownButton({
  title,
  items,
  customToogleAfter = true,
  customClass,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      className={customClass}
      onToggle={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      <Dropdown.Toggle
        bsPrefix={`custom-dropdown-toggle ${
          customToogleAfter ? 'hide-after' : ''
        }`}
      >
        <>
          <span>{title}</span>
          {customToogleAfter ? (
            <Image
              src={NarrowUp}
              className={`toogle-area-indicator ${!isOpen ? 'rtoate180' : ''}`}
            />
          ) : null}
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix="custom-dropdown-menu">
        {items.map((item) => {
          return (
            <Dropdown.Item
              bsPrefix="custom-dropdown-item"
              onClick={() => item.action()}
            >
              {item.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
