import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';
import { hasError } from '../base/ErrorFieldMessage';
import NumberInput from './NumberInput';

function AssignedCreditQuotaPerEntitlementSection() {
  const { watch, setValue, formState, getValues, control } = useFormContext();
  const params = useParams();
  const errors = formState.errors;
  // const watchCredit = watch('assignCreditPerLink');
  // const watchQuota = watch('assignQuotaPerLink');

  const slotCashCampaigns = watch('slotCashCampaigns') || { newCash0: [] };
  const slotGiftCampaigns = watch('slotGiftCampaigns') || { newGift0: [] };

  const [isPublished, rewardType] = getValues(['isPublished', 'rewardType']);

  return (
    <>
      <label className="create-section-title">
        Assigned credit/quota per entitlement
      </label>
      <label className="section-short-description">
        Must assign either credit only / quota only or both for each goodie bag.
      </label>

      {rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            Credit
          </label>
          {Object.keys(slotCashCampaigns).map((key, index) => (
            <>
              {index > 0 ? <div style={{ height: '8px' }}></div> : null}
              <Controller
                control={control}
                name={`assignReward.${key}`}
                render={({ field: { value } }) => (
                  <NumberInput
                    disabled={isPublished && key.indexOf('new') !== 0}
                    labelText=""
                    // type="Per link"
                    type={`for Slot ${index + 1}`}
                    valueChange={(value) => {
                      setValue(`assignReward.${key}`, value, {
                        shouldDirty: true,
                      });
                    }}
                    defaultValue={value}
                    errors={errors}
                    onError={hasError(errors, `assignReward.${key}`)}
                    errorFieldName={`assignReward.${key}`}
                  />
                )}
              />
            </>
          ))}
        </>
      ) : (
          <></>
        )}

      {rewardType &&
        rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value ? (
          <>
            <label className="create-section-label create-section-label-bottom-space">
              Quota
          </label>
            {Object.keys(slotGiftCampaigns).map((key, index) => (
              <>
                {index > 0 ? <div style={{ height: '8px' }}></div> : null}
                <Controller
                  control={control}
                  name={`assignReward.${key}`}
                  render={({ field: { value } }) => (
                    <NumberInput
                      disabled={isPublished && key.indexOf('new') !== 0}
                      labelText=""
                      // type="Per link"
                      type={`for Slot ${
                        index +
                        1 +
                        (rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
                          ? Object.keys(slotCashCampaigns).length
                          : 0)
                        }`}
                      valueChange={(value) => {
                        setValue(`assignReward.${key}`, value, {
                          shouldDirty: true,
                        });
                      }}
                      defaultValue={value}
                      errors={errors}
                      onError={hasError(errors, `assignReward.${key}`)}
                      errorFieldName={`assignReward.${key}`}
                    />
                  )}
                />
              </>
            ))}
          </>
        ) : (
          <></>
        )}
    </>
  );
}

export default AssignedCreditQuotaPerEntitlementSection;
