import { CampaignErrorHandleField } from '../../../components/campaign/campaignCreation/CreateCampaignHandleError';
import { isValidHttpUrl, reducer } from '../../../utils';
import { CampaignType } from '../../../config/CustomEnums';
import {
  isShowCouponRelated,
  isGoodieBagType,
} from '../../../models/CreateCampaignModel';

function firstStepValidate({ getValues, setError }) {
  const [channels, markets] = getValues(['channels', 'markets']);
  const finnalChannel = channels?.filter((channel) => !!channel.pk);
  console.log('channels:', channels);
  console.log('markets:', markets);
  if (finnalChannel?.length && !markets?.length) {
    setError('markets', {
      type: 'manual',
      message: CampaignErrorHandleField.markets.message,
    });
    return false;
  }
  return true;
}

function secondStepValidate({ getValues, setError }) {
  const [
    name,
    description,
    campaignType,
    landingName,
    landingPageUrl,
    haveLandingPage,
    linkedStores,
    coverPhoto,
    storeEditMode,
    linkedBrand,
    isRedirectionCampaign,
    countryCodes,
    showStatementWordingForCountryCodeLimit,
    statementWordingForCountryCodeLimit,
  ] = getValues([
    'translations.en.generalName',
    'translations.en.shortDescription',
    'campaignType',
    'translations.en.landingPageButtonName',
    'translations.en.landingPageUrl',
    'haveLandingPage',
    'linkedStores',
    'translations.en.coverPhoto',
    'storeEditMode',
    'linkedBrand',
    'isRedirectionCampaign',
    'countryCodes',
    'showStatementWordingForCountryCodeLimit',
    'translations.en.statementWordingForCountryCodeLimit',
  ]);
  const errorList = [];
  if (!name || name.length > 200) {
    errorList.push({
      name: 'translations.en.generalName',
      message: CampaignErrorHandleField.generalName.message,
    });
  }

  if (campaignType === CampaignType.fortuneBagForGoodieBagCampaign) {
    errorList.forEach((error) => {
      setError(error.name, {
        type: 'manual',
        message: error.message,
      });
    });
    return !errorList.length;
  }

  if (
    campaignType !== CampaignType.fortuneBagCampaign &&
    campaignType !== CampaignType.generalMessageCampaign &&
    !description
  ) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: CampaignErrorHandleField.shortDescription.message,
    });
  }
  if (campaignType !== CampaignType.fortuneBagCampaign && !coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: CampaignErrorHandleField.coverPhoto.message,
    });
  }
  if (haveLandingPage && !landingName && !isRedirectionCampaign) {
    errorList.push({
      name: 'translations.en.landingPageButtonName',
      message: CampaignErrorHandleField.landingPageButtonName.message,
    });
  }
  if (isRedirectionCampaign && !linkedBrand?.pk) {
    errorList.push({
      name: 'linkedBrand',
      message: CampaignErrorHandleField.linkedBrand.message,
    });
  }

  if (
    (haveLandingPage || isRedirectionCampaign) &&
    !isValidHttpUrl(landingPageUrl)
  ) {
    errorList.push({
      name: 'translations.en.landingPageUrl',
      message: CampaignErrorHandleField.landingPageUrl.message,
    });
  }
  if (
    countryCodes?.length > 0 &&
    showStatementWordingForCountryCodeLimit &&
    !statementWordingForCountryCodeLimit
  ) {
    errorList.push({
      name: 'statementWordingForCountryCodeLimit',
      message:
        CampaignErrorHandleField.statementWordingForCountryCodeLimit.message,
    });
  }

  // const store = linkedStores || [];
  // const isCouponRelated = isShowCouponRelated(campaignType);
  // if (isCouponRelated && storeEditMode && store.length <= 0) {
  //   errorList.push({
  //     name: 'linkedStores',
  //     message: CampaignErrorHandleField.applicableStore.message,
  //   });
  // }
  console.log('errors2:', errorList);
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const goodieBagTimeValid = ({
  errorList,
  startDate,
  endDate,
  pairStartDate,
  pairEndDate,
  key,
}) => {
  const startDateName = `${key}StartDate`;
  const endDateName = `${key}EndDate`;
  const invalidName = `${key}EndDateInvalid`;
  if (startDate?.getTime() >= endDate?.getTime()) {
    errorList.push({
      name: endDateName,
      message: CampaignErrorHandleField[endDateName].message,
    });
    return;
  }
  if (!pairEndDate) {
    return;
  }
  if (pairStartDate?.getTime() > startDate?.getTime()) {
    // console.log('pairStartDate:', pairStartDate);
    // console.log('startDate:', startDate);
    // console.log('pairStartDate?.getTime():', pairStartDate?.getTime());
    // console.log('startDate?.getTime():', startDate?.getTime());
    // console.log('result:', pairStartDate?.getTime() > startDate?.getTime());
    errorList.push({
      name: startDateName,
      message: CampaignErrorHandleField[invalidName].message,
    });
  }
  if (pairEndDate?.getTime() < endDate?.getTime()) {
    errorList.push({
      name: endDateName,
      message: CampaignErrorHandleField[invalidName].message,
    });
  }
};

export const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
};

function thirdStepValidate({
  getValues,
  setError,
  couponAcquired,
  sourcePerHeadLimit,
}) {
  const errorList = [];
  const [
    campaignType,
    categories,
    isAlwaysVisiblePeriod,
    visibleStartDate,
    visibleEndDate,
    isAlwaysActivePeriod,
    activeStartDate,
    activeEndDate,
    perHeadOwnedLimit,
    perHeadLimit,
    perHeadDownloadLimit,
    channelLimit,
    linkedCoupon,
    isRedirectionCampaign,
    goodieBagReward,
    goodieBag,
    isFollowGoodieBagActivePeriod,
    isFollowGoodieBagVisiblePeriod,
    editAllocation,
    perHeadDownloadForEachTime,
  ] = getValues([
    'campaignType',
    'categories',
    'isAlwaysVisiblePeriod',
    'visibleStartDate',
    'visibleEndDate',
    'isAlwaysActivePeriod',
    'activeStartDate',
    'activeEndDate',
    'perHeadOwnedLimit',
    'perHeadLimit',
    'perHeadDownloadLimit',
    'channelLimit',
    'linkedCoupon',
    'isRedirectionCampaign',
    'goodieBagReward',
    'goodieBag',
    'isFollowGoodieBagActivePeriod',
    'isFollowGoodieBagVisiblePeriod',
    'editAllocation',
    'perHeadDownloadForEachTime',
  ]);
  console.log('visibleStartDate:', visibleStartDate);
  const isGoodieBag = isGoodieBagType(campaignType);
  const parseVisibleStartDate =
    typeof visibleStartDate === 'string'
      ? new Date(visibleStartDate)
      : visibleStartDate;
  const parseVisibleEndDate =
    typeof visibleEndDate === 'string'
      ? new Date(visibleEndDate)
      : visibleEndDate;

  if (campaignType !== CampaignType.fortuneBagCampaign) {
    if (!categories || categories.length <= 0) {
      errorList.push({
        name: 'categories',
        message: CampaignErrorHandleField.campaignCategory.message,
      });
    }

    if (isGoodieBag) {
      const isUsingGoodieTime =
        goodieBag.couponAcquirePeriodType === 'ALL_TIME' ||
        goodieBag.couponAcquirePeriodType === 'RELATIVE';
      if (!isFollowGoodieBagActivePeriod) {
        const startTime = !isUsingGoodieTime
          ? goodieBag.absoluteStartDate
          : goodieBag.startDate;
        const endDate = !isUsingGoodieTime
          ? goodieBag.absoluteExpiryDate
          : goodieBag.endDate;
        const goodieBagStartDate = new Date(startTime);
        const goodieBagEndDate = endDate ? new Date(endDate) : null;
        console.log('goodieBagEndDate:', goodieBagEndDate, endDate);
        goodieBagTimeValid({
          errorList,
          startDate: activeStartDate,
          endDate: activeEndDate,
          pairStartDate: goodieBagStartDate,
          pairEndDate: goodieBagEndDate,
          key:
            campaignType === CampaignType.fortuneBagForGoodieBagCampaign
              ? 'fortunebagActive'
              : 'active',
        });
      }
    }

    if (
      [
        CampaignType.cashVoucherCampaign,
        CampaignType.fortuneBagForGoodieBagCampaign,
      ].indexOf(campaignType) >= 0 &&
      (!goodieBagReward || goodieBagReward < 1)
    ) {
      console.log('@@296: ', goodieBagReward);
      errorList.push({
        name: 'goodieBagReward',
        message: CampaignErrorHandleField.rewards.message,
      });
    }

    if (
      campaignType === CampaignType.fortuneBagForGoodieBagCampaign &&
      errorList.length > 0
    ) {
      console.log('@@297: ', errorList);
      errorList.forEach((error) => {
        setError(error.name, {
          type: 'manual',
          message: error.message,
        });
      });
      return false;
    }

    if (isGoodieBag) {
      if (!isFollowGoodieBagVisiblePeriod) {
        const goodieBagDisplayStart = new Date(goodieBag.displayStartDate);
        const goodieBagDisplayEnd = goodieBag.displayEndDate
          ? new Date(goodieBag.displayEndDate)
          : null;
        goodieBagTimeValid({
          errorList,
          startDate: parseVisibleStartDate,
          endDate: parseVisibleEndDate,
          pairStartDate: goodieBagDisplayStart,
          pairEndDate: goodieBagDisplayEnd,
          key: 'visible',
        });
        /*  if (
           parseVisibleStartDate?.getTime() >= parseVisibleEndDate?.getTime()
         ) {
           errorList.push({
             name: 'visibleEndDate',
             message: CampaignErrorHandleField.visibleEndDate.message,
           });
         } else if (
           goodieBagDisplayStart?.getTime() > parseVisibleStartDate?.getTime() ||
           goodieBagDisplayEnd?.getTime() < parseVisibleEndDate?.getTime()
         ) {
           errorList.push({
             name: 'visibleEndDate',
             message: CampaignErrorHandleField.visibleEndDateInvalid.message,
           });
         } */
      }
    } else if (
      !isAlwaysVisiblePeriod &&
      parseVisibleStartDate?.getTime() >= parseVisibleEndDate?.getTime()
    ) {
      errorList.push({
        name: 'visibleEndDate',
        message: CampaignErrorHandleField.visibleEndDate.message,
      });
    } else if (
      !isRedirectionCampaign &&
      !isAlwaysActivePeriod &&
      activeStartDate?.getTime() >= activeEndDate?.getTime()
    ) {
      errorList.push({
        name: 'activeEndDate',
        message: CampaignErrorHandleField.activeEndDate.message,
      });
    }
  }

  const isCouponRelated = isShowCouponRelated(campaignType);
  if (isCouponRelated) {
    const couponStock = linkedCoupon?.totalNubmerOfAvailableCoupons || 0;

    if (perHeadLimit) {
      console.log('@@321');
    }

    if (
      sourcePerHeadLimit !== perHeadLimit &&
      sourcePerHeadLimit?.toString() !== perHeadLimit.toString()
    ) {
      if (
        perHeadLimit &&
        (perHeadLimit > couponStock || parseInt(perHeadLimit) === 0)
      ) {
        errorList.push({
          name: 'perHeadLimit',
          message: CampaignErrorHandleField.perHeadLimit.message,
        });
      }
    }

    if (
      perHeadOwnedLimit &&
      (parseInt(perHeadOwnedLimit) === 0 ||
        (perHeadLimit &&
          parseInt(perHeadOwnedLimit) > (parseInt(perHeadLimit) || 0)))
    ) {
      errorList.push({
        name: 'perHeadOwnedLimit',
        message: CampaignErrorHandleField.perHeadOwnedLimit.message,
      });
    }

    if (perHeadDownloadLimit) {
      if (parseInt(perHeadDownloadLimit) === 0) {
        errorList.push({
          name: 'perHeadDownloadLimit',
          message: CampaignErrorHandleField.perHeadDownloadLimit.zeroMessage,
        });
      } else if (
        perHeadOwnedLimit &&
        parseInt(perHeadDownloadLimit) > (parseInt(perHeadOwnedLimit) || 0)
      ) {
        errorList.push({
          name: 'perHeadDownloadLimit',
          message:
            CampaignErrorHandleField.perHeadDownloadLimit.lessThanOwnedMessage,
        });
      } else if (
        !perHeadOwnedLimit &&
        perHeadLimit &&
        parseInt(perHeadDownloadLimit) > (parseInt(perHeadLimit) || 0)
      ) {
        errorList.push({
          name: 'perHeadDownloadLimit',
          message:
            CampaignErrorHandleField.perHeadDownloadLimit
              .lessThanPerHeadMessage,
        });
      }
    } else {
      errorList.push({
        name: 'perHeadDownloadLimit',
        message: CampaignErrorHandleField.perHeadDownloadLimit.requiredMessage,
      });
    }

    if (perHeadDownloadForEachTime) {
      if (
        perHeadDownloadForEachTime < 1 ||
        (perHeadDownloadLimit &&
          perHeadDownloadLimit < perHeadDownloadForEachTime)
      ) {
        errorList.push({
          name: 'perHeadDownloadForEachTime',
          message:
            CampaignErrorHandleField.perHeadDownloadForEachTimeLimit.message,
        });
      }
    } else {
      errorList.push({
        name: 'perHeadDownloadForEachTime',
        message:
          CampaignErrorHandleField.perHeadDownloadForEachTimeLimit
            .requiredMessage,
      });
    }

    const channelLimitList = Object.keys(channelLimit || {}).map((key) => {
      const channelPK = key ? `channel${key}` : null;
      if (!editAllocation) {
        return 0;
      }
      const perChannelLimit = parseInt(channelLimit[key]?.overallLimit || 0);
      if (
        !isEmpty(channelLimit[key]?.overallLimit) &&
        (channelLimit[key]?.overallLimit || 0) <
          (couponAcquired?.[channelPK]?.acquired || 0)
      ) {
        errorList.push({
          name: `${key}-overallLimit`,
          message: CampaignErrorHandleField.overallLimitPer.message,
        });
      }

      if (!channelLimit[key]?.enableMarketLimit) {
        return perChannelLimit;
      }
      const marketLimitList = Object.values(
        channelLimit[key]?.perTargetMarketLimit || {},
      ).map((value) => {
        const marketPK = value.pk ? `market${value.pk}` : null;
        if (
          !isEmpty(value.overallLimit) &&
          (value.overallLimit || 0) <
            (couponAcquired?.[channelPK]?.markets?.[marketPK]?.acquired || 0)
        ) {
          errorList.push({
            name: `${key}-${value.pk}-limit`,
            message: CampaignErrorHandleField.overallLimitPer.message,
          });
        }

        if (
          couponAcquired?.[channelPK]?.markets?.[marketPK]?.acquired &&
          (value.overallLimit || 0) <
            (couponAcquired?.[channelPK]?.markets?.[marketPK]?.acquired || 0)
        ) {
          errorList.push({
            name: `${key}-${value.pk}-limit`,
            message: CampaignErrorHandleField.overallLimitPer.message,
          });
        }

        return parseInt(value.overallLimit || 0);
      });

      let marketOverallLimit = 0;
      if (marketLimitList?.length > 0) {
        marketOverallLimit = marketLimitList?.reduce(reducer, 0);
      }
      if (marketOverallLimit && marketOverallLimit > perChannelLimit) {
        Object.values(channelLimit[key]?.perTargetMarketLimit || {}).forEach(
          (value) => {
            errorList.push({
              name: `${key}-${value.pk}-limit`,
              message: CampaignErrorHandleField.overallLimit.message,
            });
          },
        );
      }

      return perChannelLimit;
    });

    let channelOverallLimit = 0;
    if (channelLimitList?.length > 0) {
      channelOverallLimit = channelLimitList?.reduce(reducer, 0);
    }

    if (
      editAllocation &&
      channelOverallLimit &&
      channelOverallLimit > couponStock
    ) {
      Object.keys(channelLimit || {}).forEach((key) => {
        errorList.push({
          name: `${key}-overallLimit`,
          message: CampaignErrorHandleField.overallLimit.message,
        });
      });
    }
  }

  console.log('errors3:', errorList);
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({
  getValues,
  setError,
  clearErrors,
  step,
  isBack,
  couponAcquired,
  sourcePerHeadLimit,
}) {
  console.log('campaignValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({
        getValues,
        setError,
        couponAcquired,
        sourcePerHeadLimit,
      });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
