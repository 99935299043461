import {
  importCustomers,
  createDownloadTask,
  downloadFile,
  getSystemTasks,
  getScheduledTasks,
  updateSystemTask,
  cancelSystemTask,
} from '../services/DownloadAndImportAPIHelper';
import { getFileNameFromUrl } from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import { DownloadTag } from '../config/CustomEnums';
import { ToastType } from './NavBarModel';

export const ImportResource = {
  customer: 'CUSTOMER',
  couponTemplate: 'COUPON_TEMPLATE',
  coupon: 'COUPON',
  updateCouponStatus: 'UPDATE_COUPON_STATUS',
  transaction: 'TRANSACTION',
  couponRecord: 'COUPON_RECORD',
  pointRecotd: 'POINTS_RECORD',
  generateCodeCoupon: 'GENERATE_CODE_COUPON',
  generateGenericCoupon: 'GENERATE_GENERIC_COUPON',
  generateQRCodeCoupon: 'GENERATE_QR_CODE_COUPON',
  generateImageCoupon: 'GENERATE_IMAGE_COUPON',
  exportCouponTemplate: 'COUPON_TEMPLATE',
  optionBEntitlementCustomers: 'FORTUNE_BAG_OPTION_B_FILE',
  optionBGoodieBagEntitlementCustomers: 'GOODIE_BAG_OPTION_B_FILE',
  paymentAndSettlementReport: 'PAYMENT_AND_SETTLEMENT_REPORT',
  store: 'STORE',
  customExport: 'CUSTOMER_REPORT',
  couponRakingExport: 'COUPON_RANKING_REPORT',
  campaignPerformanceExport: 'CAMPAIGN_PERFORMANCE_REPORT',
  storeQRCodeExport: 'STORE_QR_CODE',
  leftoverCoupon: 'COUPON_LEFTOVER',
  campaignReference: 'CAMPAIGN_REFERENCE',
  couponTemplateReference: 'COUPON_TEMPLATE_REFERENCE',
  goodieBag: 'GOODIE_BAG',
  goodieBagParticipationRecord: 'GOODIE_BAG_PARTICIPATION_RECORD',
  goodieBagConfig: 'GOODIE_BAG_CONFIG',
  physicalCoupon: 'PHYSICAL_COUPON',
  physicalCouponTemplate: 'PHYSICAL_COUPON_TEMPLATE_PREVIEW',

  campaign: 'CAMPAIGN',
  channel: 'CHANNEL',
  targetMarket: 'TARGET_MARKET',
  brand: 'BRAND',
  termsAndConditionsTemplate: 'TERMS_AND_CONDITIONS_TEMPLATE',
  offerTypeLabel: 'OFFER_TYPE_LABEL',
  attributeTag: 'ATTRIBUTE_TAG',
  campaignCategory: 'CAMPAIGN_CATEGORY',
  surveyForm: 'SURVEYFORM',

  tourCard: 'TOUR_CARD',
  logoLabel: 'LOGO_LABEL',
  workingTeam: 'WORKING_TEAM',

  simpleCustomer: 'SIMPLE_CUSTOMER_REPORT',
  simpleCampaign: 'SIMPLE_CAMPAIGN_PERFORMANCE_REPORT',
};

const TASK_JOB = {
  export: 'EXPORT_JOB',
  import: 'IMPORT_JOB',
};

const parseStatus = (item) => {
  if (
    item.resource === ImportResource.updateCouponStatus &&
    item.status === 'COMPLETED'
  ) {
    return DownloadTag.updated;
  }
  if (item.status === 'SCHEDULED') {
    return DownloadTag.scheduledImport;
  }
  if (item.resource === ImportResource.generateCodeCoupon) {
    switch (item.status) {
      case 'COMPLETED':
        if (item.type === TASK_JOB.export) return DownloadTag.completed;
        return DownloadTag.generated;
      case 'PENDING':
        if (item.type === TASK_JOB.export) return DownloadTag.pendingForExport;
        return DownloadTag.pendingForGenerate;
      case 'PROCESSING':
        if (item.type === TASK_JOB.export) return DownloadTag.downloading;
        return DownloadTag.generating;
      case 'ERROR':
        return DownloadTag.error;
      case 'CANCELLED':
        return DownloadTag.cancelled;
      default:
        return '';
    }
  }
  switch (item.status) {
    case 'COMPLETED':
      if (item.type === TASK_JOB.export) return DownloadTag.completed;
      return DownloadTag.importCompleted;
    case 'SCHEDULED':
      return DownloadTag.scheduledImport;
    case 'PENDING':
      if (item.type === TASK_JOB.export) return DownloadTag.pendingForExport;
      return DownloadTag.pendingForImport;
    case 'PROCESSING':
      if (item.type === TASK_JOB.export) return DownloadTag.downloading;
      return DownloadTag.importing;
    case 'ERROR':
      return DownloadTag.error;
    case 'CANCELLED':
      return DownloadTag.cancelled;
    default:
      return '';
  }
};

function taskDetail(item) {
  // if (
  //   item.node.errors?.numberOfImported !== undefined &&
  //   item.node.errors.numberOfImported !== null
  // ) {
  //   item.node.status = 'COMPLETED';
  // }
  if (
    item.node.status === 'COMPLETED' &&
    (item.node?.resource === ImportResource.generateCodeCoupon ||
      item.node?.resource === ImportResource.generateGenericCoupon ||
      item.node?.resource === ImportResource.coupon)
  ) {
    item.node.couponLink = {
      pathname: `/coupons/${item.node?.related?.templateId}`,
      hash: '#Single%20Coupons%20Generated',
    };
  }
  let nameTag = item.node?.type === TASK_JOB.export ? 'Export' : 'Import';
  if (
    item.node.resource === ImportResource.generateCodeCoupon ||
    item.node.resource === ImportResource.generateGenericCoupon
  ) {
    nameTag = 'Generate';
  }
  return {
    ...item.node,
    name: `[${nameTag}] ${item.node?.fileName}`,
    displayStatus: parseStatus(item.node),
  };
}

export default {
  namespace: 'downloadAndImport',
  state: {
    customerDownloads: [],
    customerImports: [],
    systemTasks: [],
    downloadingCount: 0,
    scheduledDownloadingCount: 0,
    error: null,
    success: false,
    showTasks: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    showTasks(state, { payload }) {
      return {
        ...state,
        showTasks: true,
      };
    },
    hideTasks(state, { payload }) {
      return {
        ...state,
        showTasks: false,
      };
    },
  },
  effects: {
    getSystemTasks: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getSystemTasks];
        function* onSuccess(data) {
          // const isScheduledTask = (item) =>
          //   item.node?.scheduleType === 'SCHEDULED';
          // const isNotScheduledTask = (item) => !isScheduledTask(item);

          yield put({
            type: 'updateState',
            payload: {
              systemTasks: data?.systemTasks.edges
                // .filter(isNotScheduledTask)
                .map(taskDetail),
              // secheduledTask: data?.systemTasks.edges
              //   .filter(isScheduledTask)
              //   .map(taskDetail),
              downloadingCount: data?.systemTasks.pendingAndProcessingCount,
              // scheduledDownloadingCount:
              //   data?.systemTasks.scheduledPendingAndProcessingCount,
            },
          });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getScheduledTasks: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getScheduledTasks];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              // systemTasks: data?.systemTasks.edges
              //   .filter(isNotScheduledTask)
              //   .map(taskDetail),
              secheduledTask: data?.systemTasks.edges
                // .filter(isScheduledTask)
                .map(taskDetail),
              // downloadingCount: data?.systemTasks.pendingAndProcessingCount,
              scheduledDownloadingCount:
                data?.systemTasks.scheduledPendingAndProcessingCount,
            },
          });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    importFile: [
      function* ({ payload }, { call, put }) {
        const data = {
          resource: payload.from,
          file: getFileNameFromUrl(payload.file?.import),
          related: payload.related,
        };

        const serviceArgs = [importCustomers, data];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });

          yield put({
            type: 'uploadFiles/updateState',
            payload: {
              importedFile: '',
            },
          });

          yield put({
            type: 'updateState',
            payload: {
              error: {},
            },
          });
          if (payload.successAction) {
            payload.successAction();
          }
          // yield put({
          //   type: 'navBars/updateState',
          //   payload: {
          //     saveDiscardToastShowing: {
          //       value: true,
          //       type: ToastType.importSuccess,
          //     },
          //   },
          // });
        }

        function* onFailed(data) {
          yield put({
            type: 'uploadFiles/updateState',
            payload: {
              importedFile: '',
            },
          });
          if (data.errors) {
            yield put({
              type: 'updateState',
              payload: {
                error: {
                  title: `Invalid ${payload.from.toLowerCase()} csv. file`,
                  content: `This file does not match the ${payload.from.toLowerCase()}.  Please check and try again.`,
                },
              },
            });
          }
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    createDownloadTask: [
      function* ({ payload }, { call, put }) {
        const afterAction = payload.afterAction || (() => {});
        const data = {
          resource: payload.from,
          related: payload.related,
        };
        const serviceArgs = [createDownloadTask, data];
        function* onSuccess(data) {
          if (data?.createExportJob?.node?.resource === 'SIMPLE_DATA') {
            yield put({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: ToastType.downloading,
                },
              },
            });
          }
          yield put({ type: 'getSystemTasks' });
          afterAction();
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    downloadFile: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [downloadFile, payload.id];
        function* onSuccess(data) {
          // yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateTask: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [
          updateSystemTask,
          { id: payload.pk, type: payload.type, status: payload.status },
        ];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    cancelTask: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [
          cancelSystemTask,
          { id: payload.pk, type: payload.type },
        ];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
