import React from 'react';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import {
  GOODIE_BAG_ENTITLEMENT_METHOD_TYPE,
  GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY,
  GOODIE_BAG_REWARD_TYPE_OPTIONS,
} from '../../models/GoodieBagsModel';
import './StepFourEntitlementSection.scss';

function StepFourEntitlementSection({ data, fromDetail }) {
  console.log('@@5', data, fromDetail);
  const batchesGoodieBag = data?.batchesGoodieBag || {};
  // const validGoodiebagLinks = Math.min(
  //   ...[
  //     (data.quantityOfCreated || 0) + (data.overallAmount || 0),
  //     Math.min(...(latestValidCoupopnAmount?.total || [])),
  //   ],
  // );

  const getOptionType = () => {
    if (!fromDetail) {
      const type = data?.ota?.[0]?.fortuneBagOption || data?.entitlementMethod;
      return type;
    }
    return data?.entitlementMethod;
  };

  const optionType = getOptionType();
  console.log('@@11', optionType);

  return (
    <>
      <label className="create-section-title">
        entitlement -{' '}
        {GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY?.[optionType]?.title}
      </label>
      <table
        border="1"
        className="content-section-table-container goodie-bag-detail-table"
      >
        {fromDetail ? (
          <>
            <thead>
              <tr>
                <td>Batch | Last updated time</td>
                {data.rewardType ===
                GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ? (
                  <td>Number of valid goodie bag added</td>
                ) : null}
                <td
                  style={
                    data.rewardType ===
                    GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
                      ? { width: '20%' }
                      : {}
                  }
                >
                  Number of Goodie bag added
                </td>
                {optionType === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionC ? (
                  <td>Target Merchant (OTA) | No. of bags</td>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {Object.keys(batchesGoodieBag)
                .sort(function (a, b) {
                  return b - a;
                })
                .map((batchKey, index) => {
                  const batch = batchesGoodieBag[batchKey];
                  return (
                    <tr>
                      <td>
                        <label className="batch-no">{parseInt(batchKey)}</label>
                        <label className="batch-dselimiter">|</label>
                        <label className="batch-updatetime">
                          {formatDate(
                            batch.lastModifiedDate,
                            TimeFormater.monthDayYearTime,
                          )}
                        </label>
                      </td>
                      {data.rewardType ===
                      GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ? (
                        <td>
                          {data.isPublished ? batch.validFortuneBagQuantity : 0}
                          {/* {fromDetail
                            ? data.isPublished
                              ? batch.validFortuneBagQuantity
                              : 0
                            : validGoodiebagLinks} */}
                        </td>
                      ) : null}

                      <td
                        style={
                          data.rewardType ===
                          GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
                            ? { width: '20%' }
                            : {}
                        }
                      >
                        {data.isPublished ? batch.fortuneBagQuantity : 0}
                      </td>
                      {optionType ===
                      GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionC ? (
                        <td>
                          {batch.targetMerchants?.map((item) => {
                            return (
                              <li>
                                {item.merchant?.name || ''} |{' '}
                                {item.fortuneBagQuantity}
                              </li>
                            );
                          })}
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
            </tbody>
          </>
        ) : (
          <>
            <thead>
              <tr>
                <td>Target Merchant (OTA)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {[...(data?.ota || []), ...(data?.batchOta || [])]?.map(
                    (item) => {
                      return <li>{item.name}</li>;
                    },
                  )}
                </td>
              </tr>
            </tbody>
          </>
        )}
      </table>
    </>
  );
}

export default StepFourEntitlementSection;
