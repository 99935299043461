import { extraQueryParameterString } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

// fortuneBagQuantityInfo {
//   quantityOfGenerate
//   quantityOfRemaining
//   quantityOfAcquired
//   quantityOfUsed
//   quantityOfLeft
// }

const CAMPAIGN_DETAIL = `startDate
endDate
displayStartDate
displayEndDate
goodieBag {
  id
  pk
  name
}
isAllFollowCouponTemplate
isFollowCouponTemplateName
creationDate
isPublished
lastModifiedDate
publicationDate
displayPriority
accessCampaignCopyUrl
status
isRedirectionCampaign
coverPhoto
couponCampaignTypeCouponTemplate {
  id
  pk
  name
  brand {
    pk
    name
  }
  stock
}
stores {
  edges {
    node {
      pk
      name
    }
  }
}
numberOfCustomersVisible
messageChannels
translations {
  edges {
    node {
      language
    }
  }
}
publishChannels {
  edges {
    node {
      name
    }
  }
}

batches {
  edges {
    node {
      index
      fortuneBagQuantity
      fortuneBagBrands {
        edges {
          node {
            pk
            name
            handleFortuneBagEntitlement
            handleOption
          }
        }
      }
    }
  }
}

`;
// groups {
//   edges {
//     node {
//       pk
//       name
//     }
//   }
// }
const getQueryString = (filter) => {
  const { others, reverse } = filter;

  let userFilter = '';
  if (filter?.filterName) {
    userFilter += `, nameIcontains:"${filter.filterName}"`;
  }
  if (filter?.ssoUid) {
    userFilter = `, isAvailable: true, customerSsoUid: "${filter?.ssoUid}"`;
  }

  if (!filter?.ssoUid && filter?.isAvailable) {
    userFilter = `, isAvailable: true`;
  }

  // if (others?.moreSearch) {
  //   userFilter += ', ' + others?.moreSearch;
  // }
  if (others?.search || filter?.search) {
    userFilter += `, search: "${others?.search || filter?.search}"`;
  }
  if (others?.status) {
    userFilter += `, isPublished: ${others?.status}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others?.expired}`;
  }
  if (others?.message_channel) {
    userFilter += `, messageChannelsIn: "${others?.message_channel}"`;
  }
  if (others?.brand) {
    userFilter += `, brandIn: "${others?.brand}"`;
  }
  if (others?.typeIn) {
    userFilter += `, typeIn: "${others?.typeIn}"`;
  }
  if (
    others?.isLinkedGoodieBag === false ||
    others?.isLinkedGoodieBag === true
  ) {
    userFilter += `, isLinkedGoodieBag: ${others?.isLinkedGoodieBag}`;
  }
  if (others?.active_period) {
    userFilter += `, activeTimeRange: "${others?.active_period}"`;
  }
  if (others?.visible_period) {
    userFilter += `, displayTimeRange: "${others?.visible_period}"`;
  }
  if (others?.for_goodie_bag) {
    userFilter += `, goodieBagIn: "${others?.for_goodie_bag}"`;
  }
  if (others?.target_customer) {
    if (others[others?.target_customer])
      userFilter += `, ${others?.target_customer}: "${
        others[others?.target_customer]
      }"`;
  }

  let orderBy = reverse ? '-pk' : 'pk';
  if (others?.is_featured) {
    orderBy = 'displayPriority';
    userFilter += `, isFeatured: ${others?.is_featured}`;
  }
  if (others?.sort) {
    orderBy = others?.sort;
  }

  if (orderBy) {
    userFilter += `, orderBy: "${orderBy}"`;
  }

  if (others?.redirection_campaign) {
    userFilter += `, isRedirectionCampaign: ${others?.redirection_campaign}`;
  }

  if (others?.tags) {
    userFilter += `, attributeTagsIn: "${others?.tags}"`;
  }

  if (others?.available_coupon) {
    userFilter += `, hasAvailableCoupon: ${others?.available_coupon}`;
  }
  if (others?.used_coupon) {
    userFilter += `, hasUsedCoupon: ${others?.used_coupon}`;
  }
  if (others?.acquired_coupon) {
    userFilter += `, hasAcquiredCoupon: ${others?.acquired_coupon}`;
  }
  if (others?.['generated_coupon']) {
    userFilter += `, hasGeneratedCoupon: ${others?.['generated_coupon']}`;
  }

  if (others?.starred_campaign) {
    userFilter += `, isStarred: ${others?.starred_campaign}`;
  }

  if (others?.activeCampaignIn) {
    userFilter += `, activeCampaignIn: "${others?.activeCampaignIn}"`;
  }

  // if (others?.working_team) {
  //   userFilter += `, workingTeamsIn: "${others?.working_team}"`;
  // }

  if (others?.workingTeamsIn || others?.working_team) {
    userFilter += `, workingTeamsInAndNull: "${
      others?.workingTeamsIn || others?.working_team
    }"`;
  }

  if (others?.getAll) {
    userFilter += `, getAll: ${others?.getAll}`;
  }

  if (others?.termsAndConditionsTemplateIn) {
    userFilter += `, termsAndConditionsTemplateIn: "${others?.termsAndConditionsTemplateIn}"`;
  }

  if (others?.goodieBagCampaignSlot) {
    userFilter += `, goodieBagCampaignSlotIn: "${others?.goodieBagCampaignSlot}"`;
  }

  if (others?.groupIn || others?.fortune_bag_group) {
    userFilter += `, groupIn: "${others?.groupIn || others.fortune_bag_group}"`;
  }

  if (others?.campaign_category) {
    userFilter += `, categoriesIn: "${others?.campaign_category}"`;
  }

  if (others?.coupon_medium_in) {
    userFilter += `, couponMediumIn: "${others?.coupon_medium_in}"`;
  }

  if (others?.goodiebagRewardType) {
    userFilter += `, rewardTypeIn: "${others?.goodiebagRewardType}"`;
  }

  userFilter += `, first: ${
    others?.isAll || filter?.isAll ? filter?.pageSize || 1000 : 20
  }`;

  return userFilter;
};

export const getCampaignList = (afterCursor, filter) => {
  const { type, others } = filter;
  const userFilter = getQueryString(filter);
  console.log('@@82: ', filter, userFilter);
  const query = `{
      campaigns(after:"${afterCursor}", type: "${
    type === 'AllTypes' || !type ? '' : type
  }"${userFilter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            type
            name
            brand {
              pk
              name
            }
            ${filter?.filterAllNeedFields || ''}
            ${
              filter?.isAll ||
              others?.isAll ||
              others?.isSelectorLoad ||
              filter?.isSelectorLoad
                ? `isAllFollowCouponTemplate
                isFollowCouponTemplateName
                 ${
                   filter.from === 'goodiebagDetail'
                     ? `
                   couponCampaignTypeCouponTemplate {
                    pk
                    name 
                    stock 
                    totalNubmerOfGeneratedCoupons                
                  }
                  isPublished
                  startDate
                  endDate
                  goodieBagReward`
                     : `couponCampaignTypeCouponTemplate {
                      name                  
                    }`
                 }`
                : CAMPAIGN_DETAIL
            }
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaign = (id) => {
  const query = `{
    campaign(id: "${id}") {
      id
      pk
      type
      name
      creationDate
      lastModifiedDate
      shortDescription
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      isFollowGoodieBagActivePeriod
      isFollowGoodieBagVisiblePeriod
      goodieBag {
        id
        pk
        name
        startDate
        endDate
        displayStartDate
        displayEndDate
        absoluteStartDate
        absoluteExpiryDate
        couponAcquirePeriodType
        numberOfDaysToExpireAfterAcquisition
        isPublished
        rewardType
      }
      campaignSlots {
        edges {
          node {
            pk
            name
            rewardType
          }
        }
      }
      goodieBagReward
      merchantTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content 
              pk
            }
          }
        }
      }
      isFollowMerchantTermsAndConditionsTemplate
      merchantTermsAndConditionsTitle
      merchantTermsAndConditionsContent
      genericTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content 
              pk
            }
          }
        }
      }
      isFollowGenericTermsAndConditionsTemplate
      genericTermsAndConditionsTitle
      genericTermsAndConditionsContent
      startDate
      endDate
      isRedirectionCampaign
      displayStartDate
      displayEndDate
      isFeatured
      showInWechatHomepage
      isStarred
      showLowStockLabel
      showOutOfStockLabel
      isExclusive
      isPublished
      publicationDate
      coverPhoto
      squareCoverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      language
      tourCardSectionTitle
      displayPriority
      isAllFollowCouponTemplate
      isFollowCouponTemplateStores
      isFollowCouponTemplateTourCard
      isFollowCouponTemplateName
      isFollowCouponTemplateShortDescription
      isFollowCouponTemplateCoverPhoto
      isFollowCouponTemplateDetailPhotos
      isFollowCouponTemplateLogoLabels
      isFollowCouponTemplateOfferSection
      isFollowCouponTemplateInstructionSection
      isFollowCouponTemplateMerchantTerms
      isFollowCouponTemplateGenericTerms
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      categories {
        edges {
          node{
            pk
            name
            id
          }
        }
      }

      translations {
        edges {
          node {
            id
            pk
            name
            shortDescription
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            merchantTermsAndConditionsTitle
            merchantTermsAndConditionsContent
            genericTermsAndConditionsTitle
            genericTermsAndConditionsContent
            coverPhoto
            squareCoverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            language
            tourCardSectionTitle
            logoLabels {
              edges {
                node {
                  pk
                  name
                  icon
                }
              }
            }
            landingPageUrl
            landingPageButtonName
            offerDetailTitle
            offerDetailContent
            statementWordingForCountryCodeLimit
          }
        }
      }
      couponCampaignTypeCouponTemplate {
          id
          pk
          name
          stock
          totalNubmerOfGeneratedCoupons
          tourCards {
            edges {
              node {
                pk
                tourCard {
                  pk
                  title
                  description
                  coverPhoto
                }
              }
            }
          }
          workingTeams {
            edges {
              node {
                pk
              }
            }
          }
      }
      couponCampaignTypePerHeadLimit
      couponCampaignTypePerHeadDownloadLimit
      couponCampaignTypePerHeadOwnedLimit
      couponCampaignTypePerHeadDownloadForEachTime
      brand {
        id
        pk
        name
        icon
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      stores(first: 100) {
        edges {
          node {
            pk
            name
          }
        }
      }
      offerTypeLabels {
        pk
        name
      }
      attributeTags {
        edges {
          node {
            pk
            name
            tagKey {
              pk
              name
              showInWechatCampaignFilterLevel1
              showInGoodieBagCampaignFilterLevel1
            }
          }
        }
      }
      logoLabels {
        edges {
          node {
            pk
            name
            icon
          }
        }
      }
      publishChannels {
        edges {
          node {
            pk
            name
          }
        }
      }
      targetMarkets {
        edges {
          node {
            pk
            name
            channels {
              pk
              name
            }
            languages {
              edges {
                node {
                  pk
                  name
                  parameter
                  code
                }
              }
            }
          }
        }
      }
      acquiredCouponsFromThisCampaign
      perChannelLimits {
        edges {
          node {
            pk
            publishChannel {
              pk
              name
            }
            enableMarketLimit
            perCampaignDownloadLimit
            perTargetMarketLimits {
              edges {
                node {
                  pk
                  targetMarket {
                    pk
                    name
                  }
                  perCampaignDownloadLimit
                }
              }
            }
          }
        }
      }
      haveLandingPage
      landingPageUrl
      landingPageButtonName
      offerDetailTitle
      offerDetailContent

      showTourCard
      accessCampaignCopyUrl
      isAgeRestricted
      showCouponDownloadConfirmation
      tourCards {
        edges {
          node {
            pk
            tourCard {
              pk
              title
              description
              coverPhoto
              translations {
                edges {
                  node {
                    language
                    title
                    description
                    coverPhoto
                  }
                }
              }
            }
            displayPriority
          }
        }
      }
      
      workingTeams {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      batches {
        edges {
          node {
            index
            lastModifiedDate
            fortuneBagQuantity
            fortuneBagBrands {
              edges {
                node {
                  pk
                  name
                  handleFortuneBagEntitlement
                  handleOption
                }
              }
            }
            couponSlots{
              edges {
                node {
                  id
                  index
                  linkCoupons {
                    edges {
                      node {
                        pk
                        name
                        stock
                        workingTeams {
                          edges {
                            node {
                              pk
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }            
          }
        }
      }
      showStatementWordingForCountryCodeLimit
      statementWordingForCountryCodeLimit
      fortuneBagCampaignTypePerHeadLimit
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignListWithCouponStock = (afterCursor, filter) => {
  const { type, others } = filter;
  const userFilter = getQueryString(filter);

  const query = `{
    campaignsWithCouponStock(after:"${afterCursor}", type: "${
    type === 'AllTypes' || !type ? '' : type
  }"${userFilter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            type
            name
            brand {
              pk
              name
            }${
              filter?.isAll ||
              others?.isAll ||
              others?.isSelectorLoad ||
              filter?.isSelectorLoad
                ? `isAllFollowCouponTemplate
                isFollowCouponTemplateName
                couponCampaignTypeCouponTemplate {
                  name                  
                }`
                : `
                couponCampaignInfo {
                  quantityOfGenerate
                  quantityOfCouponGenerate
                  quantityOfPhysicalCouponGenerate
                  quantityOfRemaining
                  quantityOfAcquired
                  quantityOfUsed
                  quantityOfPhysicalCouponUsed
                  quantityOfLeft
                }  
                ${CAMPAIGN_DETAIL}`
            }
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignAllListWithCouponStock = (afterCursor, filter) => {
  const { type, others } = filter;
  const userFilter = getQueryString(filter);

  // couponCampaignInfo {
  //   quantityOfGenerate
  //   quantityOfCouponGenerate
  //   quantityOfPhysicalCouponGenerate
  //   quantityOfRemaining
  //   quantityOfAcquired
  //   quantityOfUsed
  //   quantityOfPhysicalCouponUsed
  //   quantityOfLeft
  // }
  const query = `{
    campaignsWithCouponStock(after:"${afterCursor}", type: "${
    type === 'AllTypes' || !type ? '' : type
  }"${userFilter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            type
            name
            brand {
              pk
              name
            }
            isPublished
            couponCampaignTypeCouponTemplate {
              pk
              stock
              totalNubmerOfGeneratedCoupons
            }
            goodieBagReward
            startDate
            endDate
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignWithCouponStock = (afterCursor, filter) => {
  const { type, others } = filter;
  const userFilter = getQueryString(filter);
  console.log('@@82: ', userFilter);
  const query = `{
    campaignWithCouponStock(after:"${afterCursor}", type: "${
    type === 'AllTypes' || !type ? '' : type
  }"${userFilter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            type
            name
            brand {
              pk
              name
            }${
              filter?.isAll ||
              others?.isAll ||
              others?.isSelectorLoad ||
              filter?.isSelectorLoad
                ? `isAllFollowCouponTemplate
                isFollowCouponTemplateName
                couponCampaignTypeCouponTemplate {
                  name                  
                }`
                : `
                couponCampaignInfo {
                  quantityOfGenerate
                  quantityOfCouponGenerate
                  quantityOfPhysicalCouponGenerate
                  quantityOfRemaining
                  quantityOfAcquired
                  quantityOfUsed
                  quantityOfPhysicalCouponUsed
                  quantityOfLeft
                }  
                ${CAMPAIGN_DETAIL}`
            }
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getPreviewCampaign = (id) => {
  const query = `{
    campaign(id: "${id}") {
      id
      pk
      type
      name
      creationDate
      lastModifiedDate
      shortDescription
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      isFollowGoodieBagActivePeriod
      isFollowGoodieBagVisiblePeriod
      goodieBag {
        id
        pk
        name
        startDate
        endDate
        displayStartDate
        displayEndDate
        absoluteStartDate
        absoluteExpiryDate
        couponAcquirePeriodType
        numberOfDaysToExpireAfterAcquisition
        isPublished
        rewardType
      }
      goodieBagReward
      merchantTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content 
              pk
            }
          }
        }
      }
      isFollowMerchantTermsAndConditionsTemplate
      merchantTermsAndConditionsTitle
      merchantTermsAndConditionsContent
      genericTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content 
              pk
            }
          }
        }
      }
      isFollowGenericTermsAndConditionsTemplate
      genericTermsAndConditionsTitle
      genericTermsAndConditionsContent
      startDate
      endDate
      isRedirectionCampaign
      displayStartDate
      displayEndDate
      isFeatured
      showInWechatHomepage
      isStarred
      showLowStockLabel
      showOutOfStockLabel
      isExclusive
      isPublished
      publicationDate
      coverPhoto
      squareCoverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      language
      tourCardSectionTitle
      displayPriority
      isAllFollowCouponTemplate
      isFollowCouponTemplateStores
      isFollowCouponTemplateTourCard
      isFollowCouponTemplateName
      isFollowCouponTemplateShortDescription
      isFollowCouponTemplateCoverPhoto
      isFollowCouponTemplateDetailPhotos
      isFollowCouponTemplateLogoLabels
      isFollowCouponTemplateOfferSection
      isFollowCouponTemplateInstructionSection
      isFollowCouponTemplateMerchantTerms
      isFollowCouponTemplateGenericTerms
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      categories {
        edges {
          node{
            pk
            name
            id
          }
        }
      }

      translations {
        edges {
          node {
            id
            pk
            name
            shortDescription
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            merchantTermsAndConditionsTitle
            merchantTermsAndConditionsContent
            genericTermsAndConditionsTitle
            genericTermsAndConditionsContent
            coverPhoto
            squareCoverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            language
            tourCardSectionTitle
            logoLabels {
              edges {
                node {
                  id
                  pk
                  name
                  icon
                }
              }
            }
            
            landingPageUrl
            landingPageButtonName
            offerDetailTitle
            offerDetailContent
            statementWordingForCountryCodeLimit
          }
        }
      }
      couponCampaignTypeCouponTemplate {
          id
          pk           
      }
      couponCampaignTypePerHeadLimit
      couponCampaignTypePerHeadDownloadLimit
      couponCampaignTypePerHeadOwnedLimit
      couponCampaignTypePerHeadDownloadForEachTime
      brand {
        id
        pk
        name
        icon
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      
      offerTypeLabels {
        pk
        name
      }

      logoLabels {
        edges {
          node {
            id
            pk
            name
            icon
          }
        }
      }
      
      targetMarkets {
        edges {
          node {
            pk
            name
            channels {
              pk
              name
            }
            languages {
              edges {
                node {
                  pk
                  name
                  parameter
                  code
                }
              }
            }
          }
        }
      }
      acquiredCouponsFromThisCampaign
      
      haveLandingPage
      landingPageUrl
      landingPageButtonName
      offerDetailTitle
      offerDetailContent

      showTourCard
      accessCampaignCopyUrl
      isAgeRestricted
      showCouponDownloadConfirmation
      tourCards {
        edges {
          node {
            pk
            tourCard {
              pk
              title
              description
              coverPhoto
              translations {
                edges {
                  node {
                    language
                    title
                    description
                    coverPhoto
                  }
                }
              }
            }
            displayPriority
          }
        }
      }    
      
      showStatementWordingForCountryCodeLimit
      statementWordingForCountryCodeLimit
      fortuneBagCampaignTypePerHeadLimit
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaignCategory = (input) => {
  const query = `mutation CreateCampaignCategory($input: CreateCampaignCategoryInput!) {
    createCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaign = (campaign) => {
  const query = `mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishCampaign = (input) => {
  console.log('@@506: ', input);
  const query = `mutation PublishCampaign($input: PublishCampaignInput!) {
    publishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishCampaign = (input) => {
  const query = `mutation UnpublishCampaign($input: UnpublishCampaignInput!) {
    unpublishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCampaign = (ids) => {
  console.log('@@408: ', ids);
  const query = `mutation DeleteCampaigns($input: DeleteCampaignsInput!) {
    deleteCampaigns(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateCampaign = (id) => {
  const query = `mutation duplicateCampaign($input: DuplicateCampaignInput!) {
    duplicateCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaign = (input) => {
  const query = `mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      success
      node {
        id
        pk
        name
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      id
      pk
      name
      type
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      birthdayTypePeriodStartDate
      birthdayTypePeriodEndDate
      translations {
        edges {
          node {
            language
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCoupon = (id) => {
  const query = `
  {
    couponStockTemplate(id: "${id}") {
      id
      pk
      name
      shortDescription
      offerDetailTitle
      offerDetailContent
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      merchantTermsAndConditionsTitle
      merchantTermsAndConditionsContent
      genericTermsAndConditionsTitle
      genericTermsAndConditionsContent
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      tourCardSectionTitle
      stock
      totalNubmerOfAvailableCoupons
      totalNubmerOfGeneratedCoupons
      totalNubmerOfAcquiredCoupons 
      totalNubmerOfExpiredCoupons 
      totalNubmerOfDeactivatedCoupons 
      redemptionMethod
      showTourCard
      translations {
        edges {
          node {
            name
            language
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            shortDescription
            offerDetailTitle
            offerDetailContent
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            merchantTermsAndConditionsTitle
            merchantTermsAndConditionsContent
            genericTermsAndConditionsTitle
            genericTermsAndConditionsContent
            tourCardSectionTitle
            logoLabels {
              edges {
                node {
                  id
                  pk
                  name
                  icon
                }
              }
            }
          }
        }
      }
      brand {
        id
        name
        pk
        icon
        translations {
          edges {
            node {
              language
              name
              id
            }
          }
        }
      }
      applicableStores {
        edges {
          node {
            pk
            name
          }
        }
      }
      logoLabels {
        edges {
          node {
            id
            pk
            name
            icon
          }
        }
      }
      tourCards {
        edges {
          node {
            pk
            tourCard {
              pk
              title
              description
              coverPhoto
              translations {
                edges {
                  node {
                    language
                    title
                    description
                    coverPhoto
                  }
                }
              }
            }
            displayPriority
          }
        }
      }
      workingTeams {
        edges {
          node {
            pk
            name
          }
        }
      }
      merchantTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content 
              pk
            }
          }
        }
      }
      genericTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content 
              pk
            }
          }
        }
      }
      couponMedium      
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getEntitlements = ({ campaignId, goodieBagId }) => {
  console.log('@1042', campaignId, goodieBagId);
  let extra = '';
  if (campaignId) {
    extra = `, campaign:${campaignId}`;
  }
  if (goodieBagId) {
    extra = `, goodieBag:${goodieBagId}`;
  }
  const query = `
  {
    entitlements(first: 100, after: ""${extra}, orderBy:"-pk",) {
      edges {
        cursor
        node {
          id
          pk
          creationDate
          lastModifiedDate
          method
          merchant {
            pk
            name
          }
          merchantScretkey
          staticLink
          wechatStaticLink
          entitledCustomerFileName
          fortuneBagUrls
          fortuneBagQuantity
          batch {
            index
            fortuneBagQuantity
          }
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const entitlementCustomers = (afterCursor, payload) => {
  let extra = '';
  if (payload.campaignId) {
    extra = `campaign:${payload.campaignId}`;
  }
  if (payload.goodieBagId) {
    extra = `goodieBag:${payload.goodieBagId}`;
  }
  const order = payload.sort ? payload.sort : 'pk';
  var extraPama = '';
  if (payload.search) {
    extraPama = extraQueryParameterString('customer', `"${payload.search}"`);
  }
  const query = `
  {
    entitlementCustomers(first: 5, after:"${afterCursor}"${extra}, orderBy:"${order}" ${extraPama}) {
        totalCount
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        edges {
            cursor
            node {
                id
                pk
                entitlement {
                    pk
                }
                customer
                customerType
                amount
                status
                creationDate
                lastModifiedDate
                administrator {
                    username
                }
            }
        }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeEntitlementCustomers = (inputBody) => {
  const query = `
  mutation ActiveEntitlementCustomer($input: ActiveEntitlementCustomerInput!) {
    activeEntitlementCustomer(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteEntitlementCustomers = (ids) => {
  const query = `mutation DeleteEntitlementCustomers($input: DeleteEntitlementCustomersInput!) {
    deleteEntitlementCustomers(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignParticipationRecordsList = (id, afterCursor) => {
  const query = `{
    campaignParticipationRecords(first: 1000, after: "${afterCursor}", campaign: ${id} ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          pk
          desiredCouponQuantity
          desiredCouponPublishChannel {
            pk
            name
          }
          desiredCouponTargetMarket {
            pk
            name
          }
        }
      }
    }
  }
`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignStarSetting = () => {
  const query = `{
    starredCampaignLimitSettings {
        pk
        perHeadOwnedLimit
    }
}
`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const setCampaignStarLimit = (limit) => {
  const query = `mutation UpdateStarredCampaignLimitSettings($input: UpdateStarredCampaignLimitSettingsInput!) {
    updateStarredCampaignLimitSettings(input: $input) {
      success
      node {
          pk
          perHeadOwnedLimit
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = { input: { perHeadOwnedLimit: limit } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaignSettings = (input) => {
  const query = `mutation UpdateCampaignSettings($input: UpdateCampaignSettingsInput!) {
    updateCampaignSettings(input: $input) {
      success
      node {
        pk
        showCampaignCategoryFilter
        showBrandFilter
        showOfferTypeLabelFilter
        showLocationFilter
        hideOutOfStockCampaign
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = { input: { ...input } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignSettings = (input) => {
  const query = `{
    campaignSettings {
      pk
      showCampaignCategoryFilter
      showBrandFilter
      showOfferTypeLabelFilter
      showLocationFilter
      hideOutOfStockCampaign
    }
  }
  `;
  const variables = { input: { ...input } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
