import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';

import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';

import DeletePrompt from '../../../components/base/DeletePrompt';
import { NavBarNames } from '../../../config/NavBarNameList';
import SearchArea from '../../../components/checkOtp/SearchArea';
import './CheckOtpList.scss';
import { useDeviceDetect } from '../../engagement/campaign/preview/DHKPreviewBody';
import FlatList from 'flatlist-react/lib';
import SearchAreaMobile from '../../../components/checkOtp/SearchAreaMobile';
import NoDataPage from '../../../components/base/NoDataPage';
import Loading from '../../../components/base/Loading';
import OTPCodeCard from '../../../components/checkOtp/OTPCodeCard';
import CountComponent from '../../../components/base/CountComponent';
import LoadingIcon from '../../../assets/images/loadingNew.gif';
import { APIStatus } from '../../../config/CustomEnums';

export default function CheckOtpList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useDeviceDetect(768);

  const {
    totalCount,
    pageInfo,
    totalPage,
    displayFields,
    otpCodeList,
    isRegister,
    hasSearched,
    countryCodeList,
    pagedOtpCodeList,
    searchMembershipId,
    loadData,
    status,
  } = useSelector((state) => ({
    totalCount: state.otpCode.totalCount,
    pageInfo: state.otpCode.pageInfo,
    totalPage: state.otpCode.totalPage,
    displayFields: state.otpCode.listDisplayFields,
    otpCodeList: state.otpCode.otpCodeList,
    isRegister: state.otpCode.isRegister,
    hasSearched: state.otpCode.hasSearched,
    countryCodeList: state.countryCode.notPagedAllList,
    pagedOtpCodeList: state.otpCode.pagedList,
    searchMembershipId: state.otpCode.searchMembershipId,
    loadData: state.otpCode.loadData,
    status: state.loading.status,
  }));

  const formatCountryCodeList = countryCodeList?.map((item) => ({
    label: `${item?.pk}`,
    value: `${item?.pk}`,
  }));

  const noRecordView = () => {
    if ((loadData && isMobile) || (status === APIStatus.calling && !isMobile)) {
      return <Loading customClass={'check-otp-loading-area'} />;
    }
    return hasSearched ? (
      isRegister ? (
        <div className="search-no-result-page">
          {'No record found.\nThis registered member has no OTP records.'}
        </div>
      ) : (
        <div className="search-no-result-page">
          {
            'No record found.\nThe provide mobile number has not been registered.'
          }
        </div>
      )
    ) : (
      <div className="search-to-show-page">Search to show records</div>
    );
  };

  useEffect(() => {
    dispatch({ type: 'countryCode/getAllList' });
    return () => {
      dispatch({ type: 'otpCode/clearState' });
      dispatch({ type: 'countryCode/clearData' });
    };
  }, []);

  useEffect(() => {
    if (!hasSearched) {
      return;
    }
    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const countryCode = querySearch['countryCode'];
    const mobileNumber = querySearch['mobileNumber'];
    const stringPage = querySearch['page'] || 1;
    const page = parseInt(stringPage);
    const mobile = '+' + countryCode + mobileNumber;
    if (isMobile) {
      dispatch({
        type: 'otpCode/loadMoreOtpCodes',
        payload: {
          ...querySearch,
          page,
          mobile,
        },
      });
    } else {
      dispatch({
        type: 'otpCode/getList',
        payload: {
          ...querySearch,
          page,
          mobile,
        },
      });
    }
  }, [dispatch, location, hasSearched]);

  useEffect(() => {
    if (searchMembershipId && pagedOtpCodeList?.length > 0) {
      pagedOtpCodeList.forEach((item) => {
        item['membershipId'] = searchMembershipId;
      });
    }
  }, [searchMembershipId, pagedOtpCodeList]);

  useEffect(() => {
    if (searchMembershipId && otpCodeList?.length > 0) {
      otpCodeList.forEach((item) => {
        item['membershipId'] = searchMembershipId;
      });
    }
  }, [searchMembershipId, otpCodeList]);

  const tabs = [
    {
      name: 'List',
      content: (
        <>
          <BaseTabListContainer
            hideSearch
            hideTab
            hideTabNav
            pageInfo={pageInfo}
            totalCount={totalCount || 0}
            filter={{ hasFilter: false, componment: null }}
            hideActionHeader
            needMobile
            listContent={
              <>
                <SearchArea
                  countryCodeList={formatCountryCodeList}
                  isRegister={isRegister}
                  searchMembershipId={searchMembershipId}
                />
                <BaseListContainer
                  fields={displayFields}
                  model={'otpCode'}
                  permissionGroup={PermissionCodes.viewCheckOtp}
                  dataList={pagedOtpCodeList}
                  totalPage={totalPage}
                  deleteInfo={{}}
                  hideCheckbox
                  noRecordView={noRecordView()}
                  hideActions
                />
              </>
            }
            mobileListContent={
              <div>
                <SearchAreaMobile
                  countryCodeList={formatCountryCodeList}
                  isRegister={isRegister}
                  searchMembershipId={searchMembershipId}
                />

                <div className="check-otp-count-container">
                  <label className="check-otp-count-text">
                    Showing {totalCount || 0} entries
                  </label>
                </div>

                <FlatList
                  list={otpCodeList}
                  renderItem={(item, index) => (
                    <OTPCodeCard key={index} otpCode={item} />
                  )}
                  renderWhenEmpty={noRecordView}
                  pagination={{
                    hasMore: pageInfo.hasNextPage,
                    loadMore: () => {
                      const searchDict = queryString.parse(location.search);
                      const stringPage = searchDict['page'] || 1;
                      const page = parseInt(stringPage);
                      history.push({
                        pathname: location.pathname,
                        search: queryString.stringify({
                          ...searchDict,
                          page: page + 1,
                        }),
                      });
                    },
                    loadingIndicator: (
                      <div>
                        <Loading
                          customClass={'otp-code-record-loading '}
                          src={LoadingIcon}
                        />
                      </div>
                    ),
                    loadingIndicatorPosition: 'center',
                  }}
                  wrapperHtmlTag="div"
                  style={{
                    overflow: 'auto',
                    height: 'calc(100vh - 177px - 16px)',
                    backgroundColor: '#f8f9fc',
                  }}
                />
              </div>
            }
          />
        </>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          title: NavBarNames.checkOtp,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        tabs={tabs}
        hideTab={true}
        hideHeader={isMobile}
      />
    </>
  );
}
