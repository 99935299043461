import React from 'react';
import { Link } from 'react-router-dom';
function CustomLinkableName(props) {
  const to = props.to;
  const text = props.text;

  return (
    <div
      className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-name`}
    >
      {props.isHtml ? (
        <Link
          to={to}
          className="all-list-scrollable-area-name-text all-list-scrollable-area-text-common"
          dangerouslySetInnerHTML={{ __html: text }}
        ></Link>
      ) : (
        <Link
          to={to}
          className="all-list-scrollable-area-name-text all-list-scrollable-area-text-common list-new-line"
        >
          {text || '-'}
        </Link>
      )}
    </div>
  );
}
export default CustomLinkableName;
