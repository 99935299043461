import React, { useEffect, useState } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

function MembershipInfoEditCard({ title, customer }) {
  const dispatch = useDispatch();
  const { customerCoupons } = useSelector((state) => ({
    customerCoupons: state.singleCoupon.pagedList,
  }));
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchRemovedCoupons = watch('removedCoupons') || [];
  const watchReferrerUser = watch('referrerUser');
  const watchIsForcedInactive = watch('isForcedInactive');
  const watchIsPreviewTester = watch('isPreviewTester');

  return (
    <div>
      <label className="create-section-title">{title}</label>
      <Controller
        control={control}
        name="removedCoupons"
        render={() => (
          <BaseMutipleSelectorV2
            title={'Owned coupons(Optional)'}
            data={{
              sourceData: customerCoupons.map((item) => ({
                name: item.template.name,
                pk: item.pk,
              })), //customer?.ownedCoupons,
              targetData: watchRemovedCoupons,
              targetChange: (value) => {
                setValue('removedCoupons', value, { shouldDirty: true });
              },
            }}
            addButton={{ title: 'Add Single Coupons ', link: '/coupons' }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="removedCoupons" />
      <Controller
        control={control}
        name="referrerUser"
        render={() => (
          <CustomerFilterableDropdown
            title={'Referrer(Optional)'}
            defaultSelectedCustomer={watchReferrerUser}
            selectCustomer={(value) => {
              setValue('referrerUser', value, { shouldDirty: true });
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="referrerUser" />
      <Controller
        control={control}
        name="isPreviewTester"
        render={() => (
          <>
            <CustomTitleWithSwitch
              title={'Test User'}
              defaultValue={watchIsPreviewTester}
              setValue={(value) => {
                setValue('isPreviewTester', value, { shouldDirty: true });
              }}
            />
            <label className="tips-message">
              If On, Can view saved “Goodie bag list”, “List page of cash
              voucher, goodie bag coupon ”, “detail page of cash voucher,
              limited offer” and coupon campaign.
            </label>
          </>
        )}
      />
      <Controller
        control={control}
        name="isForcedInactive"
        render={() => (
          <CustomTitleWithSwitch
            title={'Is forced inactive'}
            defaultValue={watchIsForcedInactive}
            setValue={(value) => {
              setValue('isForcedInactive', value, { shouldDirty: true });
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="isForcedInactive" />
    </div>
  );
}

export default MembershipInfoEditCard;
