import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import CustomRadios from '../../base/CustomRadios';
import { Button, Row } from 'react-bootstrap';
import './CouponDateTimeSelector.scss';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import {
  DISCOUNT_CODE_FORMAT,
  VALID_PERIOD_TYPE,
  checkValidTimePeriods,
  displayDiscountCodeFormat,
} from '../../../models/CreateCouponModel';
import {
  COUPON_SET_TYPES,
  COUPON_FORMATS,
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
  COUPON_LOW_STOCK,
  CouponMediumType,
} from '../../../config/CustomEnums';
import ErrorFieldMessage, {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import SurveyFormSelectDropdown from './SurveyFormSelectDropdown';
import BasePrompt from '../../base/prompt/BasePrompt';
import CouponBarcodeSelectDropdown from './CouponBarcodeSelectDropDown';
import {
  CustomNumberInputWithUnit,
  CustomTitleLabel,
  CustomTitleWithRadio,
} from '../../earning/CustomBaseComponments';
import { connect } from 'react-redux';
import DeleteIcon from '../../../assets/images/coupon/delete.svg';
import EditIcon from '../../../assets/images/coupon/edit.svg';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';

import { TimeFormater, formatDate } from '../../../utils/TimeFormatUtil';
import { weekdays } from '../../../models/StoreModel';
import { removeElementFromArray, useCompare } from '../../../utils';

const CouponDatetimeOptions = [
  { label: 'All the time', value: VALID_PERIOD_TYPE.ALL_TIME },
  { label: 'In a period', value: VALID_PERIOD_TYPE.ABSOLUTE },
  {
    label: 'Numbers of day since acquiring',
    value: VALID_PERIOD_TYPE.RELATIVE,
  },
];

const WeekDaysShort = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

const RedemptionPeriodType = {
  DAYS_OF_WEEK: 'DaysOfWeek',
  SPECIAL_DATE: 'SpecialDate',
  BLACKOUT_DATE: 'BlackoutDate',
};

const RedemptionPeriodTitles = {
  DaysOfWeek: {
    addNewTitle: 'Add Weekly Recurrence Rule',
    editTitle: 'Edit Days of the Week',
    deleteTitle: 'Confirm to delete this weekly recurrence rule?',
    editContent: (data, inSave, callback, excludeDays) => (
      <DaysOfWeekRuleSet
        data={data}
        inSave={inSave}
        excludeDays={excludeDays}
        callback={callback}
      />
    ),
  },
  SpecialDate: {
    addNewTitle: 'Add Special Date',
    editTitle: 'Edit Special Date',
    deleteTitle: 'Confirm to delete this special date rule?',
    editContent: (data, inSave, callback, validPeriod) => (
      <SpecialDateSet
        data={data}
        inSave={inSave}
        callback={callback}
        validPeriod={validPeriod}
      />
    ),
  },
  BlackoutDate: {
    addNewTitle: 'Add Blackout Date',
    editTitle: 'Edit Blackout Date Exclusion',
    deleteTitle: 'Confirm to delete this blackout date rule?',
    editContent: (data, inSave, callback, validPeriod) => (
      <SpecialDateSet
        data={data}
        inSave={inSave}
        callback={callback}
        validPeriod={validPeriod}
      />
    ),
  },
};

function TimePeriodSet({
  data,
  saveData,
  inSave,
  setTimePeriodsError,
  errors,
}) {
  const [isWholeDay, setIsWholeDay] = useState(
    data?.isWholeDay === undefined ? true : data?.isWholeDay,
  );

  const [timePeriods, setTimePeriods] = useState(data?.timePeriods || []);

  // const [errors, setErrors] = useState();

  // useEffect(() => {
  //   if (data) {
  //     setTimePeriods(data.timePeriods);
  //   }
  // }, [data]);

  useEffect(() => {
    // setTimePeriodsError(false);
    saveData({ isWholeDay, timePeriods });
  }, [isWholeDay, timePeriods]);
  console.log('@@305-1: ', inSave);
  // useEffect(() => {
  //   console.log('@@305-1: ', inSave);
  //   if (inSave) {
  //     let hasError = false;
  //     if (isWholeDay === false) {
  //       (timePeriods || []).forEach((element, index) => {
  //         const start = new Date(element.start);
  //         start.setSeconds(0);
  //         const end = new Date(element.end);
  //         end.setSeconds(59);
  //         if (end < start) {
  //           // errors.push(index)
  //           setErrors([...(errors || []), index]);
  //           hasError = true;
  //         }
  //       });
  //     }

  //     if (!hasError) {
  //       setErrors([]);

  //       saveData({ isWholeDay, timePeriods });
  //     } else {
  //       setTimePeriodsError(true);
  //     }
  //   }
  // }, [inSave]);

  return (
    <>
      <CustomTitleWithRadio
        title={'Time period'}
        options={[
          { label: 'Whole day', value: true },
          { label: 'Custom time period', value: false },
        ]}
        defaultValue={isWholeDay}
        setValue={(v) => {
          setIsWholeDay(v);
          if (!v && timePeriods?.length <= 0) {
            setTimePeriods([
              ...timePeriods,
              {
                start: new Date().setHours(0, 0, 0, 0),
                end: new Date().setHours(0, 0, 0, 0),
              },
            ]);
          }
        }}
      />
      {!isWholeDay &&
        timePeriods.map((period, index) => (
          <>
            <div className="edit-time-period">
              <div>
                <label className="create-section-label title-space">
                  From:
                </label>
                <CustomDateTimeSelect
                  hideDate
                  defaultTime={period.start}
                  onTimeChange={(date) => {
                    const timeString = formatDate(
                      date,
                      TimeFormater.yearMonthDayTimeSeconds,
                    );

                    const newData = timePeriods.map((oldPeriods, oldIndex) => {
                      if (index === oldIndex) {
                        return {
                          start: timeString,
                          end: formatDate(
                            oldPeriods.end,
                            TimeFormater.yearMonthDayTimeSeconds,
                          ),
                        };
                      }
                      return oldPeriods;
                    });

                    setTimePeriods(newData);
                  }}
                  error={errors?.includes(index)}
                />
              </div>
              <div className="sperate">-</div>
              <div>
                <div className="one-time-period">
                  <label className="create-section-label title-space">
                    To:
                  </label>
                  <div
                    className="period-item-title-buttons-delete"
                    onClick={() => {
                      const result = timePeriods.filter((item, num) => {
                        return num !== index;
                      });

                      setTimePeriods(result);
                    }}
                  >
                    <img src={DeleteIcon} />
                    <label>Delete</label>
                  </div>
                </div>

                <CustomDateTimeSelect
                  hideDate
                  defaultTime={period.end}
                  onTimeChange={(date) => {
                    const timeString = formatDate(
                      date,
                      TimeFormater.yearMonthDayTimeSeconds,
                    );

                    const newData = timePeriods.map((oldPeriods, oldIndex) => {
                      if (index === oldIndex) {
                        return {
                          start: formatDate(
                            oldPeriods.start,
                            TimeFormater.yearMonthDayTimeSeconds,
                          ),
                          end: timeString,
                        };
                      }
                      return oldPeriods;
                    });
                    console.log('@@167: ', newData);
                    setTimePeriods(newData);
                  }}
                  error={errors?.includes(index)}
                />
              </div>
            </div>
            <label className="tips">
              Note: 59 minutes represents 59 minutes and 59 seconds.
            </label>
            {errors?.includes(index) ? (
              <ErrorFieldMessage
                id={'time-period'}
                error={'end time must larger than start time'}
                message={'end time must larger than start time'}
              />
            ) : null}
          </>
        ))}
      {isWholeDay ? null : (
        <Button
          className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common left-button tips-message-margin"
          onClick={() => {
            setTimePeriods([
              ...timePeriods,
              {
                start: new Date().setHours(0, 0, 0, 0),
                end: new Date().setHours(0, 0, 0, 0),
              },
            ]);
          }}
        >
          Add time period
        </Button>
      )}
    </>
  );
}

function DaysOfWeekRuleSet({ data, inSave, excludeDays, callback }) {
  const [selectDays, setSelectDays] = useState(data?.weekdays || []);

  const [timePeriods, setTimePeriods] = useState();

  const [error, setError] = useState();
  const [timePeriodsError, setTimePeriodsError] = useState();
  const changed = useCompare({ timePeriods, timePeriodsError });
  const daysChanged = useCompare({ selectDays });
  // useEffect(() => {
  //   console.log('@@309: ', inSave, changed, daysChanged);
  //   if (inSave) {
  //     if (selectDays.length <= 0) {
  //       setError(true);
  //       callback(null);
  //     } else {
  //       setError(false);
  //       console.log('@@305: ', inSave, timePeriods, selectDays);
  //       if (changed || (timePeriods && !timePeriodsError)) {
  //         callback({ selectDays, timePeriods }, data?.index);
  //       } else if (timePeriodsError) {
  //         callback(null);
  //       }
  //     }
  //   }
  // }, [inSave, changed, selectDays]);

  useEffect(() => {
    console.log('@@309: ', inSave, changed, daysChanged);
    if (inSave) {
      let hasError = false;
      if (selectDays.length <= 0) {
        setError(true);
        callback(null);
        hasError = true;
      } else {
        setError(false);
      }

      if (timePeriods?.isWholeDay === false) {
        let hasTimePeriodsError = [];
        (timePeriods?.timePeriods || []).forEach((element, index) => {
          const start = new Date(element.start);
          start.setSeconds(0);
          const end = new Date(element.end);
          end.setSeconds(59);
          if (end < start) {
            // errors.push(index)
            // setTimePeriods([...(timePeriods || []), index]);
            hasTimePeriodsError.push(index);
          }
        });

        hasError = hasError ? hasError : hasTimePeriodsError.length;

        setTimePeriodsError(hasTimePeriodsError);
      }

      if (hasError) {
        callback(null);
      } else {
        console.log('@@305: ', inSave, timePeriods, selectDays);
        callback({ selectDays, timePeriods }, data?.index);
        // callback(null);
      }
    }
  }, [inSave, timePeriods, selectDays]);

  return (
    <div className="d-flex flex-column edit-period-area">
      <BaseMutipleSelectorV2
        // size={['shorter']}
        title={'Select the Day of the week'}
        searchPlaceholder={'Search by name'}
        data={{
          sourceData: weekdays.filter((day) => !excludeDays.includes(day.key)),
          targetData: selectDays,
          targetChange: setSelectDays,
        }}
        custom={{
          // customItem: (value) => `[${value?.pk}] ${value?.name}`,
          customFilter: (inputValue, option) => {
            const inputIgnoreCase = inputValue?.toLowerCase();
            const optionIgnoreCase = option?.name?.toLowerCase();
            return optionIgnoreCase?.includes(inputIgnoreCase);
          },
        }}
        error={{
          error: error,
          message: 'please select at least one day',
          id: 'weekdays',
        }}
      />
      <TimePeriodSet
        data={data}
        saveData={setTimePeriods}
        inSave={inSave}
        setTimePeriodsError={setTimePeriodsError}
        errors={timePeriodsError}
      />
    </div>
  );
}

function SpecialDateSet({ data, inSave, callback, validPeriod }) {
  const [selectDate, setSelectDate] = useState(data?.date);

  const [timePeriods, setTimePeriods] = useState();
  const [error, setError] = useState();
  const [timePeriodsError, setTimePeriodsError] = useState();

  const changed = useCompare({ timePeriods, selectDate });

  useEffect(() => {
    // if (inSave && changed) {
    //   console.log(
    //     '@@484-0 ',
    //     inSave,
    //     changed,
    //     timePeriodsError,
    //     timePeriods,
    //     selectDate,
    //   );
    //   if (timePeriodsError || !selectDate) {
    //     callback(null);
    //     return;
    //   }
    //   if (
    //     timePeriods &&
    //     !checkValidTimePeriods(
    //       { date: selectDate, ...timePeriods },
    //       validPeriod,
    //     )
    //   ) {
    //     setError(true);
    //     callback(null);
    //   } else if (timePeriods) {
    //     callback({ selectDate, timePeriods }, data?.index);
    //   }
    // }

    if (inSave) {
      let hasError = false;
      if (timePeriods?.isWholeDay === false) {
        let hasTimePeriodsError = [];
        (timePeriods?.timePeriods || []).forEach((element, index) => {
          const start = new Date(element.start);
          start.setSeconds(0);
          const end = new Date(element.end);
          end.setSeconds(59);
          if (end < start) {
            // errors.push(index)
            // setTimePeriods([...(timePeriods || []), index]);
            hasTimePeriodsError.push(index);
          }
        });

        hasError = hasTimePeriodsError.length > 0;

        setTimePeriodsError(hasTimePeriodsError);
      }

      if (
        !hasError &&
        timePeriods &&
        !checkValidTimePeriods(
          { date: selectDate, ...timePeriods },
          validPeriod,
        )
      ) {
        setError(true);
        hasError = true;
      }

      if (hasError) {
        callback(null);
      } else {
        console.log('@@305: ', inSave, timePeriods, selectDate);
        callback({ selectDate, timePeriods }, data?.index);
      }
    }
  }, [inSave, changed]);

  return (
    <div className="d-flex flex-column edit-period-area">
      <CustomDateTimeSelect
        hideTime
        defaultTime={selectDate || data?.date}
        onTimeChange={(date) => {
          setSelectDate(date);
        }}
        error={error}
      />
      {error ? (
        <ErrorFieldMessage
          id={'time-period'}
          error={
            "Please select a date and time within the 'Coupon valid period'."
          }
          message={
            "Please select a date and time within the 'Coupon valid period'."
          }
        />
      ) : null}

      <TimePeriodSet
        data={data}
        saveData={setTimePeriods}
        inSave={inSave}
        setTimePeriodsError={setTimePeriodsError}
        errors={timePeriodsError}
      />
    </div>
  );
}

function RedemptionPeriodItem({
  item,
  showButtons = true,
  deleteAction,
  editAction,
  error,
}) {
  return (
    <div className="period-item">
      <div className="period-item-title">
        <label>
          {item.weekdays
            ? item.weekdays.map((day) => WeekDaysShort[day.pk]).join(', ')
            : formatDate(item.date, TimeFormater.date)}
        </label>
        {showButtons ? (
          <div className="period-item-title-buttons">
            <div
              className="period-item-title-buttons-delete"
              onClick={() => deleteAction(item)}
            >
              <img src={DeleteIcon} />
              <label>Delete</label>
            </div>
            <div
              className="period-item-title-buttons-edit"
              onClick={() => editAction(item)}
            >
              <img src={EditIcon} />
              <label>Edit</label>
            </div>
          </div>
        ) : null}
      </div>
      <div className="period-item-times">
        {item.isWholeDay ? (
          <li>
            <span>Full day</span>
          </li>
        ) : (
          item.timePeriods.map((period) => {
            return (
              <li>
                <span>
                  {formatDate(new Date(period.start), TimeFormater.timePeriod)}{' '}
                  - {formatDate(new Date(period.end), TimeFormater.timePeriod)}
                </span>
              </li>
            );
          })
        )}
      </div>

      {error ? (
        <label className={`error-field-message-style`}>
          Please select a date and time within the 'Coupon valid period'.
        </label>
      ) : null}
    </div>
  );
}

function RedemptionPeriod({
  title,
  data,
  redemptionPeriodType,
  fieldName,
  setValue,
  validPeriod,
  errors,
}) {
  // const [sourceData, setSourceData] = useState(data);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [selectItem, setSelectItem] = useState();
  const [inSave, setInSave] = useState();

  const selectedDays = useMemo(
    () =>
      redemptionPeriodType === RedemptionPeriodType.DAYS_OF_WEEK &&
      data?.map((item, index) => item.weekdays).flat(),
    [data, redemptionPeriodType],
  );

  const callBackSave = (newData, index) => {
    console.log(newData, index);
    if (!newData) {
      setInSave(false);
      return;
    }

    setShowEdit(false);

    if (newData.id || index !== undefined) {
      const savedData = data.map((item, num) => {
        if ((newData.id && item.id === newData.id) || index === num) {
          return redemptionPeriodType === RedemptionPeriodType.DAYS_OF_WEEK
            ? { weekdays: newData.selectDays, ...newData.timePeriods }
            : { date: newData.selectDate, ...newData.timePeriods };
        }

        return item;
      });

      setValue(fieldName, savedData, { shouldDirty: true });
    } else {
      console.log('@@484: ', data, newData);
      setValue(
        fieldName,
        [
          ...data,
          redemptionPeriodType === RedemptionPeriodType.DAYS_OF_WEEK
            ? { weekdays: newData.selectDays, ...newData.timePeriods }
            : { date: newData.selectDate, ...newData.timePeriods },
        ],
        { shouldDirty: true },
      );
    }
    setValue(
      fieldName.indexOf('physical') >= 0
        ? 'physicalRedeemPeriodUpdated'
        : 'redeemPeriodUpdated',
      true,
      { shouldDirty: true },
    );

    setInSave(false);
  };

  return (
    <>
      <div className="d-flex flex-column">
        <CustomTitleLabel title={title} />

        <div className="redeemPeriodArea">
          {data?.map((item, index) => (
            <>
              {index > 0 > 0 ? <div className="sperate-line2" /> : null}
              <RedemptionPeriodItem
                item={item}
                deleteAction={(item) => {
                  setSelectItem({ ...item, index });
                  setShowDelete(true);
                  setInSave(false);
                }}
                editAction={(item) => {
                  setSelectItem({ ...item, index });
                  setShowEdit(true);
                  setInSave(false);
                }}
                error={hasError(
                  errors,
                  `${
                    redemptionPeriodType === RedemptionPeriodType.SPECIAL_DATE
                      ? 'dateRules'
                      : redemptionPeriodType ===
                        RedemptionPeriodType.BLACKOUT_DATE
                      ? 'excludeDateRules'
                      : ''
                  }-${index}`,
                )}
              />
            </>
          ))}

          {redemptionPeriodType === RedemptionPeriodType.DAYS_OF_WEEK &&
          [...new Set(selectedDays || [])].length >= 7 ? null : (
            <>
              {data?.length > 0 ? <div className="sperate-line2" /> : null}
              <Button
                className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common left-button"
                onClick={() => {
                  setShowEdit(true);
                  setInSave(false);
                  setSelectItem();
                }}
              >
                {RedemptionPeriodTitles[redemptionPeriodType].addNewTitle}
              </Button>
            </>
          )}
        </div>
      </div>
      <BasePrompt
        show={showEdit}
        basePromptButtonsClassName="redemption-period-prompt-buttons"
        closeAction={() => {
          setShowEdit(false);
          setInSave(false);
          setSelectItem();
          // setCallBack();
        }}
        rightButton={{
          text: 'Save',
          action: () => {
            setInSave(true);
          },
          disabled: inSave,
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowEdit(false);
            setInSave(false);
            setSelectItem();
          },
        }}
        title={
          selectItem
            ? RedemptionPeriodTitles[redemptionPeriodType].editTitle
            : RedemptionPeriodTitles[redemptionPeriodType].addNewTitle
        }
        otherBody={() => {
          return RedemptionPeriodTitles[redemptionPeriodType].editContent(
            selectItem,
            inSave,
            callBackSave,
            redemptionPeriodType === RedemptionPeriodType.DAYS_OF_WEEK
              ? (data || [])
                  .map((val) =>
                    val.weekdays
                      .filter(
                        (d) =>
                          !(selectItem?.weekdays || [])
                            .map((w) => w.pk)
                            .includes(d.pk),
                      )
                      .map((d) => d.pk),
                  )
                  .flat()
              : validPeriod,
          );
        }}
      />
      <BasePrompt
        show={showDelete}
        closeAction={() => {
          setShowDelete(false);
        }}
        rightButton={{
          text: 'Confirm',
          action: () => {
            // setSourceData(
            //   sourceData.filter((item) => item.id !== selectItem.id),
            // );
            setValue(
              fieldName,
              data.filter((item, index) => index !== selectItem.index),
              { shouldDirty: true },
            );
            setShowDelete(false);
            setValue(
              fieldName.indexOf('physical') >= 0
                ? 'physicalRedeemPeriodUpdated'
                : 'redeemPeriodUpdated',
              true,
              { shouldDirty: true },
            );
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowDelete(false),
        }}
        title={RedemptionPeriodTitles[redemptionPeriodType].deleteTitle}
        otherBody={() => (
          <RedemptionPeriodItem item={selectItem} showButtons={false} />
        )}
      />
    </>
  );
}

function ValidPeriodSection({
  title,
  validPeriodTypeName,
  effectiveDateKey,
  expiryDateKey,
  hasSpecialDaysKey,
  weeklyRulesKey,
  dateRulesKey,
  excludeDateRulesKey,
  hideSpecialDays,
}) {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  const watchValidPeriodType = watch(validPeriodTypeName);
  const watchAbsoluteEffectiveDate = watch(effectiveDateKey);
  const watchAbsoluteExpiryDate = watch(expiryDateKey);

  const watchHasSpecialDays = watch(hasSpecialDaysKey);
  const watchDaysOfWeekSet = watch(weeklyRulesKey);
  const watchSpecialDaysSet = watch(dateRulesKey);
  const watchBlackoutDaysSet = watch(excludeDateRulesKey);

  return (
    <>
      <div>
        <CustomTitleWithRadio
          title={title}
          options={
            validPeriodTypeName.indexOf('physical') >= 0
              ? CouponDatetimeOptions.slice(0, 2)
              : CouponDatetimeOptions
          }
          defaultValue={watchValidPeriodType}
          setValue={(value) => {
            setValue(validPeriodTypeName, value, { shouldDirty: true });
          }}
        />
        <div
          className="second-section"
          hidden={
            watchValidPeriodType === VALID_PERIOD_TYPE.RELATIVE ? false : true
          }
        >
          <div>
            <label className="create-section-label">Required days</label>
            <div className="d-flex">
              <input
                type="text"
                className={`day-input-fields ${
                  hasError(errors, 'numberOfDaysToExpireAfterAcquisition')
                    ? 'error-field'
                    : ''
                }`}
                {...register('numberOfDaysToExpireAfterAcquisition')}
              />
              <label className="days-label">Days</label>
            </div>
            <ReactHookFormErrorMessage
              errors={errors}
              id="numberOfDaysToExpireAfterAcquisition"
            />
          </div>
        </div>
        <div
          hidden={
            watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE ? false : true
          }
        >
          <div>
            <div className="second-section">
              <label className="create-section-label">Start date</label>

              <CustomDateTimeSelect
                defaultTime={watchAbsoluteEffectiveDate}
                onTimeChange={(datetime) => {
                  setValue(effectiveDateKey, datetime, {
                    shouldDirty: true,
                  });
                }}
              />
            </div>
            <div className="second-section">
              <label className="create-section-label">End date</label>
              <CustomDateTimeSelect
                defaultTime={watchAbsoluteExpiryDate}
                error={hasError(errors, expiryDateKey)}
                onTimeChange={(datetime) => {
                  setValue(expiryDateKey, datetime, { shouldDirty: true });
                }}
                seconds={59}
                // disabledTime={true}
              />
              <ReactHookFormErrorMessage errors={errors} id={expiryDateKey} />
            </div>
          </div>
        </div>
      </div>
      {!hideSpecialDays ? (
        <div className="redemption-period">
          <CustomTitleWithSwitch
            title={'Redemption period restriction'}
            tips={
              'Customize the coupon redemption period by specifying specific days and/or hours of the week'
            }
            tipsDetail={
              '3 types of restriction rules: \n• Days of the Week \nLimit coupon redemption to specific weekdays within the defined validity period , allowing users to redeem coupons only on those specified weekdays \n\n• Special Date Inclusion \nInclude specified dates, such as public holidays or other significant occasions, beyond the regular validity period, allowing coupon redemption exclusively on those specific dates \n\n• Blackout Date Exclusion \nExclude specified dates, such as public holidays or other significant occasions, beyond the regular validity period, preventing coupon redemption on those specific dates'
            }
            defaultValue={watchHasSpecialDays}
            setValue={(value) =>
              setValue(hasSpecialDaysKey, !!value, { shouldDirty: true })
            }
          />
          {hasError(errors, 'nonRules') ? (
            <label className={`error-field-message-style`}>
              Please add at least one time rule.
            </label>
          ) : null}

          {watchHasSpecialDays ? (
            <>
              <RedemptionPeriod
                title="Days of the Week"
                data={watchDaysOfWeekSet || []}
                redemptionPeriodType={RedemptionPeriodType.DAYS_OF_WEEK}
                fieldName={weeklyRulesKey}
                setValue={setValue}
                validPeriod={
                  watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE
                    ? [watchAbsoluteEffectiveDate, watchAbsoluteExpiryDate]
                    : null
                }
                errors={errors}
              />
              <RedemptionPeriod
                title="Special Date Inclusion"
                data={watchSpecialDaysSet || []}
                redemptionPeriodType={RedemptionPeriodType.SPECIAL_DATE}
                fieldName={dateRulesKey}
                setValue={setValue}
                validPeriod={
                  watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE
                    ? [watchAbsoluteEffectiveDate, watchAbsoluteExpiryDate]
                    : null
                }
                errors={errors}
              />
              <RedemptionPeriod
                title="Blackout Date Exclusion"
                data={watchBlackoutDaysSet || []}
                redemptionPeriodType={RedemptionPeriodType.BLACKOUT_DATE}
                fieldName={excludeDateRulesKey}
                setValue={setValue}
                validPeriod={
                  watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE
                    ? [watchAbsoluteEffectiveDate, watchAbsoluteExpiryDate]
                    : null
                }
                errors={errors}
              />
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

const NewCouponDateTimeSelector = ({ couponTemplate }) => {
  const { getValues } = useFormContext();
  const { couponSetType, redemptionMethod, couponMedium } = getValues();

  const hasCouponMedium =
    couponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED &&
    redemptionMethod ===
      COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE;

  return (
    <div>
      {couponMedium === CouponMediumType.EWallet ||
      couponMedium === CouponMediumType.Both ||
      !hasCouponMedium ? (
        <ValidPeriodSection
          title={hasCouponMedium ? 'Valid period for eWallet' : 'Valid period'}
          validPeriodTypeName="validPeriodType"
          effectiveDateKey="absoluteEffectiveDate"
          expiryDateKey="absoluteExpiryDate"
          hasSpecialDaysKey="hasSpecialDays"
          weeklyRulesKey="weeklyRules"
          dateRulesKey="dateRules"
          excludeDateRulesKey="excludeDateRules"
          hideSpecialDays={
            redemptionMethod ===
            COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE
          }
        />
      ) : null}
      {(couponMedium === CouponMediumType.Physical ||
        couponMedium === CouponMediumType.Both) &&
      hasCouponMedium ? (
        <ValidPeriodSection
          title={'Valid period for physical'}
          validPeriodTypeName="physicalValidPeriodType"
          effectiveDateKey="physicalAbsoluteEffectiveDate"
          expiryDateKey="physicalAbsoluteExpiryDate"
          hasSpecialDaysKey="physicalHasSpecialDays"
          weeklyRulesKey="physicalWeeklyRules"
          dateRulesKey="physicalDateRules"
          excludeDateRulesKey="physicalExcludeDateRules"
          hideSpecialDays={true}
        />
      ) : null}
    </div>
  );
};

const mapPropsToState = (state) => ({
  couponTemplate: state.createCoupon.couponTemplate,
});
export default connect(mapPropsToState)(NewCouponDateTimeSelector);

