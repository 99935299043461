import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import {
  COUPON_REDEMPTION_METHOD,
  CampaignType,
  CouponMediumType,
  DisplayCouponMediumType,
} from '../../../config/CustomEnums';
import moment from 'moment';
import { TimeFormater } from '../../../utils/TimeFormatUtil';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../../models/GoodieBagsModel';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { brandList, couponSetList, singleCouponList, campaignList } =
    useSelector((state) => ({
      brandList: state.brand.notPagedAllList,
      couponSetList: state.couponList.notPagedAllList,
      singleCouponList: state.singleCoupon.pagedList,
      campaignList: state.campaignList.notPagedAllList,
    }));

  const [sourceCouponSet, setSourceCouponSet] = useState();
  const [sourceBrand, setSourceBrand] = useState();
  // const [sourceSingleCoupon, setSourceSingleCoupon] = useState();
  const [sourceCampaign, setSourceCampaign] = useState();

  const parsedSearch = queryString.parse(location.search);
  const searchReportPeriod = parsedSearch['report_period'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchCouponSet = parsedSearch['coupon_set'] || '';
  const searchSingleCoupon = parsedSearch['single_coupon'] || '';
  const searchRecordType = parsedSearch['single_coupon_status'] || '';
  const searchCampaign = parsedSearch['campaign'] || '';
  const searchCampaignStatus = parsedSearch['campaign_active_status'] || '';
  const searchRedemptionMethod = parsedSearch['redemption_method'] || '';
  const searchTransactionType = parsedSearch['transaction_type'] || '';
  const searchCouponValidPeriod = parsedSearch['coupon_valid_period'] || '';
  const searchCampaignActivePeriod =
    parsedSearch['campaign_active_period'] || '';
  const searchCampaignIsStarred = parsedSearch['starred_campaign'] || '';
  const searchReferenceId = parsedSearch['reference_id'] || '';
  const searchCouponMedium = parsedSearch['coupon_medium'] || '';

  const searchGoodiebagRewardType =
    parsedSearch['goodie_bag_reward_type'] || '';
  const searchCampaignType = parsedSearch['campaign_type'] || '';

  const [reportPeriod, setReportPeriod] = useState(searchReportPeriod);
  const [brand, setBrand] = useState(ArrayStringData(searchBrand));
  const [couponSet, setCouponSet] = useState(ArrayStringData(searchCouponSet));
  const [singleCoupon, setSingleCoupon] = useState(searchSingleCoupon);
  const [singleCouponStatus, setSingleCouponStatus] = useState(
    ArrayStringData(searchRecordType),
  );
  const [campaigns, setCampaigns] = useState(ArrayStringData(searchCampaign));
  const [campaignStatus, setCampaignStatus] = useState(searchCampaignStatus);
  const [redemptionMethod, setRedemptionMethod] = useState(
    ArrayStringData(searchRedemptionMethod),
  );
  const [transactionType, setTransactionType] = useState(
    ArrayStringData(searchTransactionType),
  );
  const [couponValidPeriod, setCouponValidPeriod] = useState(
    searchCouponValidPeriod,
  );
  const [campaignActivePeriod, setCampaignActivePeriod] = useState(
    searchCampaignActivePeriod,
  );
  const [campaignIsStarred, setCampaignIsStarred] = useState(
    searchCampaignIsStarred,
  );
  const [referenceId, setReferenceId] = useState(searchReferenceId);
  const [couponMedium, setCouponMedium] = useState(
    ArrayStringData(searchCouponMedium),
  );

  const [goodiebagRewardType, setGoodiebagRewardType] = useState(
    searchGoodiebagRewardType,
  );
  const [campaignType, setCampaignType] = useState(searchCampaignType);

  useEffect(() => {
    dispatch({ type: 'brand/getAllList', payload: { isAll: true } });
    dispatch({
      type: 'couponList/getAllList',
      payload: {
        isAll: true,
        pageSize: 1000,
        // listName: 'notPagedAllList',
      },
    });
    dispatch({
      type: 'campaignList/getAllList',
      payload: { isAll: true, pageSize: 1000 },
    });

    return () => {
      dispatch({ type: 'brand/clearData' });
      dispatch({ type: 'couponList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
  }, []);

  // useEffect(() => {
  //   if (searchSingleCoupon) {
  //     const singleCouponIds = ArrayStringData(searchSingleCoupon);
  //     dispatch({
  //       type: 'singleCoupon/getSimpleList',
  //       payload: { pageSize: 100, ids: singleCouponIds },
  //     });
  //   }

  //   return () => {
  //     dispatch({ type: 'singleCoupon/clearData' });
  //   };
  // }, [searchSingleCoupon]);

  const content = [
    {
      title: 'Report period',
      value: reportPeriod,
      setValue: setReportPeriod,
      allowEmpty: [true, true],
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Brand',
      data: sourceBrand || brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = brandList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            item.pk.toString().includes(searchKey?.toLowerCase() || '')
          );
        });
        setSourceBrand(newData);
      },
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    },
    {
      title: 'Coupon set',
      data: sourceCouponSet || couponSetList,
      value: couponSet,
      setValue: setCouponSet,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = couponSetList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            item.pk.toString().includes(searchKey?.toLowerCase() || '')
          );
        });
        setSourceCouponSet(newData);
      },
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    },
    {
      title: 'Reference ID',
      value: referenceId,
      setValue: setReferenceId,
      componment: FilterTypeEnum.searchable,
      placeholder: 'Search by reference ID',
    },
    {
      title: 'Coupon medium',
      data: [
        { name: DisplayCouponMediumType.EWALLET, pk: CouponMediumType.EWallet },
        {
          name: DisplayCouponMediumType.PHYSICAL,
          pk: CouponMediumType.Physical,
        },
      ],
      value: couponMedium,
      setValue: setCouponMedium,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    // {
    //   title: 'Single coupon',
    //   data: singleCouponList,
    //   value: singleCoupon,
    //   setValue: setSingleCoupon,
    //   componment: FilterTypeEnum.searchableChoice,
    //   placeholder: 'Search by ID, Serial Number',
    //   filterAction: (searchKey) => {
    //     // const newData = singleCouponList?.filter((item) => {
    //     //   const name = item.name?.toLowerCase() || '';
    //     //   return name.includes(searchKey?.toLowerCase() || '');
    //     // });
    //     // // setGoodieData(newData);
    //     dispatch({ type: 'singleCoupon/clearData' });

    //     dispatch({
    //       type: 'singleCoupon/getSimpleList',
    //       payload: { pageSize: 100, search: searchKey },
    //     });
    //   },
    //   renderDisplayName: (item) => `[ID:${item.pk}] ${item.serialNumber}`,
    // },
    {
      title: 'Single coupon',
      value: singleCoupon,
      setValue: setSingleCoupon,
      componment: FilterTypeEnum.searchable,
      placeholder: 'Search by ID, Serial Number',
      // filterAction: (searchKey) => {
      //   setSingleCoupon(searchKey);
      // },
      // renderDisplayName: (item) => `[ID:${item.pk}] ${item.serialNumber}`,
    },
    {
      title: 'Single coupon status',
      data: [
        { name: 'Acquired', pk: 'BUY' },
        { name: 'Active(Acquired)', pk: 'GRANT' },
        { name: 'Use', pk: 'USE' },
        { name: 'Expired', pk: 'EXPIRED' },
        { name: 'Voided', pk: 'VOID' },
        { name: 'Active', pk: 'ACTIVE' },
        { name: 'Inactive', pk: 'INACTIVE' },
      ],
      // data: [
      //   { name: 'Customer earned', pk: 'EARN' },
      //   { name: 'Customer acquired', pk: 'BUY' },
      //   { name: 'Admin added', pk: 'GRANT' },
      //   { name: 'Admin removed', pk: 'RECLAIM' },
      //   { name: 'Customer use', pk: 'USE' },
      //   { name: 'Coupon expired', pk: 'EXPIRED' },
      // ],
      value: singleCouponStatus,
      setValue: setSingleCouponStatus,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Campaign type',
      data: [
        { pk: CampaignType.couponsCampaign, name: 'Coupon campaign' },
        { pk: CampaignType.cashVoucherCampaign, name: 'Cash Voucher' },
        { pk: CampaignType.giftCampaign, name: 'Goodie Bag Coupon' },
        {
          pk: CampaignType.fortuneBagForGoodieBagCampaign,
          name: 'Goodie bag offer (Direct assign)',
        },
      ],
      value: campaignType,
      setValue: setCampaignType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Campaign',
      data: sourceCampaign || campaignList,
      value: campaigns,
      setValue: setCampaigns,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = campaignList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            item.pk.toString().includes(searchKey?.toLowerCase() || '')
          );
        });
        setSourceCampaign(newData);
      },
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    },
    {
      title: 'Starred campaign',
      data: [
        { name: 'Starred', pk: 'true' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Non-Starred', pk: 'false' },
      ],
      value: campaignIsStarred,
      setValue: setCampaignIsStarred,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Campaign active status',
      data: [
        { name: 'Expired', pk: 'true' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Unexpired', pk: 'false' },
      ],
      value: campaignStatus,
      setValue: setCampaignStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Goodie bag reward type',
      data: [
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
          name: 'Cash voucher',
        },
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value,
          name: 'Goodie bag coupon',
        },
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value,
          name: 'Both cash voucher and goodie bag coupon',
        },
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value,
          name: 'Goodie bag offer (Direct assign)',
        },
      ],
      value: goodiebagRewardType,
      setValue: setGoodiebagRewardType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Redemption Method',
      data: [
        {
          name: 'Merchant app scan Coupon QR code',
          pk: COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE,
        },
        {
          name: 'Users scan Store QR code',
          pk: COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE,
        },
        {
          name: 'Users reveal the coupon (discount code, URL, QR code/Barcode)',
          pk: COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE,
        },
      ],
      value: redemptionMethod,
      setValue: setRedemptionMethod,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Transaction Type',
      data: [
        { name: 'Admin added', pk: 'GRANT' },
        { name: 'Customer acquired', pk: 'BUY' },
        { name: 'Customer use', pk: 'USE' },
        { name: 'Coupon expired', pk: 'EXPIRED' },
        { name: 'Admin removed', pk: 'RECLAIM' },
      ],
      value: transactionType,
      setValue: setTransactionType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Coupon valid period',
      value: couponValidPeriod,
      setValue: setCouponValidPeriod,
      allowEmpty: [true, true],
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Campaign active period',
      value: campaignActivePeriod,
      setValue: setCampaignActivePeriod,
      allowEmpty: [true, true],
      componment: FilterTypeEnum.dateRange,
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      init={() => {
        // setReportPeriod(
        //   `${moment()
        //     .add(-8, 'days')
        //     .format(TimeFormater.yearMonthDay)},${moment()
        //     .add(-1, 'days')
        //     .format(TimeFormater.yearMonthDay)}`,
        // );
      }}
    />
  );
}

export default Filter;
