import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createAction } from '../../../utils';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
import MembershipInfoEditCard from '../../../components/customer/MembershipInfoEditCard';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
    CheckStatus,
    DELETE_RELATED_SECTIONS,
    SavedStatus,
  } from '../../../config/CustomEnums';

const CustomerEditFormStepOne = ({ customer }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const { getValues, trigger, setError, clearErrors, setFocus } =
    useFormContext();

    const sections = [<MembershipInfoEditCard title='Membership Information' customer={customer}/>]

    return (
      <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText="Delete"
        saveText={'Update'}
        backAction={() => history.goBack()}
        saveAction={ async () => {
          let isValid = await trigger(
              [
                'removedCoupons',
                'referrerUser',
                'isForcedInactive',
              ],
              { shouldFocus: true },
            );
          if (isValid) {
            dispatch(
              createAction('customerList/updateCustomer')({
                data: getValues(),
              }),
            );
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteCustomer}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Customer',
          content: 'Did you confirm to delete this customer?',
        }}
        title={'Customer'}
        data={customer}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `customerList/updateState`,
            payload: { checkedList: [customer] },
          });
          dispatch(
            createAction('customerList/delete')({
              afterAction: () => {
                history.push('/customers#List%20of%20Customers');
              },
            }),
          );
        }}
      />
      </>
    )
}

const mapPropsToState = (state) => ({
    customer: state.customerList.customer || {},
});

export default connect(mapPropsToState)(CustomerEditFormStepOne);