import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import { createAction } from '../../../utils';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import CustomTitleWithSwitch from '../../../components/base/CustomTitleWithSwitch';
import { ERROR_MESSAGE } from '../../../utils';
import { LanguageConfig } from '../../../config/CustomEnums';

const CreateOTPChannelContent = ({ languages }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();
  const watchDisplayPriority = watch('displayPriority');
  const watchChannel = watch('channel');

  const validate = async () => {
    clearErrors([`channel`, `translations.en.title`, 'displayPriority']);
    const [channel, title, displayPriority] = getValues([
      'channel',
      'translations.en.title',
      'displayPriority',
    ]);

    let isValid = true;

    if (!id && !channel) {
      isValid = false;
      setError(
        'translations.en.name',
        {
          type: 'require',
          message: ERROR_MESSAGE.MISS_FIELD,
        },
        { shouldFocus: true },
      );
    }

    if (!title) {
      isValid = false;
      setError(
        'translations.en.title',
        {
          type: 'require',
          message: ERROR_MESSAGE.MISS_FIELD,
        },
        { shouldFocus: true },
      );
    }

    if (!displayPriority) {
      isValid = false;
      setError(
        'displayPriority',
        {
          type: 'require',
          message: ERROR_MESSAGE.MISS_FIELD,
        },
        { shouldFocus: true },
      );
    }

    return isValid;
  };

  const renderLanguageTab = (language) => {
    const watchTitle = watch(`translations.${language}.title`);
    const watchDesription = watch(`translations.${language}.description`);
    const watchSmallDescription = watch(
      `translations.${language}.smallDescription`,
    );
    const watchShowInFrontend = watch(
      `translations.${language}.showInFrontend`,
    );
    return (
      <div>
        <div className="first-section-title">General</div>

        {language === LanguageConfig.english ? (
          id ? (
            <>
              <CustomTitleLabel title="OTP channel" />
              <br />
              <label className="viewer-content">{watch('channel')}</label>
            </>
          ) : (
            <Controller
              control={control}
              name="channel"
              render={() => (
                <>
                  <CustomTitleWithInput
                    title={'channel'}
                    defaultValue={watchChannel}
                    setValue={(value) => {
                      setValue(`channel`, value, {
                        shouldDirty: true,
                      });
                    }}
                    error={{
                      error: hasError(errors, `channel`),
                    }}
                  />
                  <ReactHookFormErrorMessage
                    errors={errors}
                    id="channel"
                    extra="channel-errors"
                  />
                </>
              )}
            />
          )
        ) : null}

        <Controller
          control={control}
          name={`translations.${language}.title`}
          render={() => (
            <>
              <CustomTitleWithInput
                title={`Title`}
                defaultValue={watchTitle}
                setValue={(value) => {
                  setValue(`translations.${language}.title`, value, {
                    shouldDirty: true,
                  });
                }}
                error={{
                  error: hasError(errors, `translations.${language}.title`),
                }}
              />
              <ReactHookFormErrorMessage
                errors={errors}
                id={`translations.${language}.title`}
              />
            </>
          )}
        />

        <Controller
          control={control}
          name={`translations.${language}.description`}
          render={() => (
            <CustomTitleWithInput
              title={`Description`}
              defaultValue={watchDesription}
              setValue={(value) => {
                setValue(`translations.${language}.description`, value, {
                  shouldDirty: true,
                });
              }}
            />
          )}
        />

        <Controller
          control={control}
          name={`translations.${language}.smallDescription`}
          render={() => (
            <CustomTitleWithInput
              title={`Small description`}
              defaultValue={watchSmallDescription}
              setValue={(value) => {
                setValue(`translations.${language}.smallDescription`, value, {
                  shouldDirty: true,
                });
              }}
            />
          )}
        />

        {language === LanguageConfig.english ? (
          <>
            <Controller
              control={control}
              name="displayPriority"
              render={() => (
                <>
                  <CustomTitleWithInput
                    title={'Display order'}
                    error={{
                      error: hasError(errors, 'displayPriority'),
                    }}
                    defaultValue={watchDisplayPriority}
                    setValue={(value) => {
                      console.log(value);
                      setValue('displayPriority', value);
                    }}
                    type={'number'}
                    extra={{
                      min: 1,
                      onKeyDown: (event) => {
                        if (
                          event.keyCode === 189 || //-
                          event.keyCode === 69 || //e
                          event.keyCode === 187 //+
                        ) {
                          event.preventDefault();
                          return false;
                        }
                      },
                    }}
                  />
                  <label className="create-message-suggest">
                    {`Minimum value is 1. The smaller value, the higher priority.`}
                  </label>
                  <ReactHookFormErrorMessage
                    errors={errors}
                    id="displayPriority"
                    extra="display-priority-errors"
                  />
                </>
              )}
            />
            <Controller
              control={control}
              name="showInFrontend"
              render={() => (
                <CustomTitleWithSwitch
                  title={'Show in Frontend'}
                  defaultValue={watchShowInFrontend}
                  setValue={(value) => {
                    setValue('showInFrontend', !!value, { shouldDirty: true });
                  }}
                />
              )}
            />
          </>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.goBack();
        }}
        saveText="Save"
        saveAction={async () => {
          //   console.log(getValues());
          const isValid = await validate();
          if (isValid) {
            const data = getValues();
            if (id) {
              dispatch(createAction('otpChannel/createOrUpdate')({ data }));
            } else {
              dispatch(createAction('otpChannel/createOrUpdate')({ data }));
            }
          }
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateOTPChannelContent);
