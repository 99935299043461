import React from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CouponsCardNew.scss';
import { enLocaleNumberFormatter } from '../../utils';
import { ANIMATION_DURATION } from '../../config/DashboardConfig';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import indicatorCoupon from '../../assets/images/coupon_dashboard_indicator.svg';
import CustomTips from '../base/CustomTips';
import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';
import { getValueChangeLabel } from './CustomersCard';
import StarredCampaignFilter from '../../containers/dashboard/StarredCampaignFilter';

const SeparatorLine = () => {
  return <div className="dashboard-coupon-seprator" />;
};

export const SectionItem = ({
  label,
  count,
  percentage,
  countFormat,
  caution,
}) => {
  return (
    <div className="dashboard-coupon-item-container">
      <div>
        <label className="dashboard-coupon-item-label">{label}</label>
        {countFormat ? (
          <CustomTips detail={'Number of coupon used / acquired x 100%'} />
        ) : null}
      </div>
      {caution ? (
        <label className="dashboard-coupon-item-label-caution">{caution}</label>
      ) : null}

      <AnimatedNumber
        className="dashboard-coupon-count-text"
        duration={ANIMATION_DURATION}
        value={count}
        formatValue={(value) => {
          if (countFormat) {
            return `${(value * 100).toFixed(0)}%`;
          }
          return enLocaleNumberFormatter(value.toFixed(0));
        }}
      />
      {getValueChangeLabel(percentage)}
    </div>
  );
};

const CouponsCardNew = () => {
  const {
    totalNumberOfAvailableCouponCampaigns,
    totalNumberOfCouponAcquired,
    totalNumberOfCouponUsed,
    usedCouponsConversionRate,
    endDate,
  } = useSelector((state) => ({
    totalNumberOfAvailableCouponCampaigns:
      state.dashboard.totalNumberOfAvailableCouponCampaigns,
    totalNumberOfCouponAcquired: state.dashboard.totalNumberOfCouponAcquired,
    totalNumberOfCouponUsed: state.dashboard.totalNumberOfCouponUsed,
    usedCouponsConversionRate: state.dashboard.usedCouponsConversionRate,
    endDate: state.dashboard.endDate,
  }));
  return (
    <div className="dashbard-coupon-card">
      <div className="histogram-zoomable-card-chart-title">coupons</div>
      <StarredCampaignFilter
        filterModel="couponFilter"
        includeGeneralMessage={false}
      />
      <img
        src={indicatorCoupon}
        alt={''}
        className="dashboard-coupon-indicator"
      />
      <SectionItem
        label="Available campaign"
        caution={
          endDate
            ? `as of ${formatDate(
                endDate,
                TimeFormater.dayMonthYearTimePeriod,
              )}`
            : null
        }
        count={totalNumberOfAvailableCouponCampaigns?.value}
        percentage={totalNumberOfAvailableCouponCampaigns?.change}
      />
      <SeparatorLine />
      <SectionItem
        label="Coupon acquired"
        count={totalNumberOfCouponAcquired?.value}
        percentage={totalNumberOfCouponAcquired?.change}
      />
      <SeparatorLine />
      <div className="dashboard-coupon-redeem-container">
        <SectionItem
          label="Coupon redeemed"
          count={totalNumberOfCouponUsed?.value}
          percentage={totalNumberOfCouponUsed?.change}
        />
        <div className="dashboard-coupon-redieem-seprator" />
        <SectionItem
          label="Redemption rate"
          count={usedCouponsConversionRate?.value}
          countFormat
          percentage={usedCouponsConversionRate?.change}
        />
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/dashboard_coupon' }}
      >
        {/* Coupon Set Overview */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default CouponsCardNew;
