import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Accordion, Card, Image } from 'react-bootstrap';
import './CollapseNav.scss';
import { getCurrentRouteName } from './CollapseNavUtil';
import DotRedImage from '../../assets/images/dot_red.png';

function CollapseNav(props) {
  const currentLocation = useLocation();
  const history = useHistory();
  const tab = props.tab;
  const [activedSideNav, setActiveSideNav] = useState('');
  const [hasSubMenu, setHasSubMenu] = useState([]);
  const [menus, setMenus] = useState([]);

  const { user, goodiebagOutOfStock } = useSelector((state) => ({
    user: state.users,
    goodiebagOutOfStock: state.goodieBags.existsCouponOutOfStock,
  }));

  const setMenu = useCallback(() => {
    const hasPermissionMenus = [];
    const tabsList = tab.list || [
      ...(tab.codename ? [{ codename: tab.codename }] : []),
    ];
    if (tabsList) {
      tabsList.forEach((item) => {
        const hasPermission =
          user?.userPermissions?.filter(
            (val) => val.split('_')[1] === item.codename,
          ).length > 0;
        if (hasPermission) {
          setHasSubMenu([...hasSubMenu, tab.name]);
          hasPermissionMenus.push(item.name);
        }
      });
    }

    setMenus(hasPermissionMenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const activeTab = getCurrentRouteName(currentLocation.pathname);
    if (!activeTab) {
      history.push('/');
      return;
    }
    console.log('@@46: ', currentLocation.pathname);
    setActiveSideNav(getCurrentRouteName(currentLocation.pathname));
  }, [currentLocation]);

  useEffect(() => {
    if (!user?.isSuperuser) {
      console.log('@@54: ');
      setMenu();
    }
  }, [user, setMenu]);

  return (
    <>
      {user?.isSuperuser || hasSubMenu.includes(tab.name) ? (
        <div className="side-menu-nav-area">
          <Card className={`side-menu-nav-section `}>
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={tab.name}
              className="side-menu-nav-header"
            >
              {tab.location ? (
                <Link
                  to={tab.location}
                  className={`side-menu-nav-title side-menu-nav-title-link ${
                    activedSideNav === tab.name
                      ? 'side-menu-nav-header-focus'
                      : ''
                  }`}
                >
                  {tab.name}
                </Link>
              ) : (
                <label className="side-menu-nav-title">{tab.name}</label>
              )}
            </Accordion.Toggle>
            {tab.list ? (
              <Accordion.Collapse
                eventKey={`${tab.name}-body`}
                className="side-menu-nav-header show"
              >
                <Card.Body className="side-menu-nav-header">
                  {tab.list?.map((route, index) =>
                    menus.includes(route.name) || user?.isSuperuser ? (
                      route.location ? (
                        <Link
                          key={`link${index}`}
                          to={route.location}
                          className={`side-menu-nav-item side-menu-nav-item-second ${
                            activedSideNav === route.name
                              ? 'side-menu-nav-header-focus'
                              : ''
                          }`}
                        >
                          {route.name}
                          {route.name === 'Goodie Bags' &&
                          goodiebagOutOfStock ? (
                            <Image
                              src={DotRedImage}
                              style={{ margin: '0 0 10px 10px' }}
                            />
                          ) : (
                            ''
                          )}
                        </Link>
                      ) : (
                        <label
                          key={`label${index}`}
                          className={`side-menu-nav-item side-menu-nav-item-second`}
                        >
                          {route.name}
                        </label>
                      )
                    ) : null,
                  )}
                </Card.Body>
              </Accordion.Collapse>
            ) : null}
          </Card>
        </div>
      ) : null}
    </>
  );
}
export default CollapseNav;
