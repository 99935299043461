import React from 'react';
import { connect } from 'react-redux';
import { LanguageConfig } from '../../config/CustomEnums';
import { useFormContext } from 'react-hook-form';
import { getImageUrl } from '../../models/CreateCampaignModel';
import MembershipInfoCard from '../../components/customer/MembershipInfoCard';
import i18n from '../../I18n';
import ContentSections from '../../components/base/ContentSections';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';

function StepFourContentSection({ languageList, data }) {
  const { getValues } = useFormContext() || {};
  const goodieBag = data || getValues() || {};
  const translations = goodieBag.translations || {};

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    const datas = valueKeys?.forEach((valueKey) => {
      switch (valueKey) {
        case 'coverPhoto': {
          const url = getImageUrl(translations?.[language]?.[valueKey]);
          if (!url || url === '') {
            data[valueKey] = null;
          }
          data[valueKey] = url;
          break;
        }
        case 'isAgeRestricted':
          data[valueKey] = goodieBag.isAgeRestricted ? 'Yes' : 'No';
          break;
        case 'ageRestrictedPrimaryButtonName':
          data[valueKey] =
            !translations?.[language]?.primaryButton1 &&
            !translations?.[language]?.primaryButton2
              ? '-'
              : (
                  (translations?.[language]?.primaryButton1 || '') +
                  ' ' +
                  (translations?.[language]?.primaryButton2 || '')
                ).trim();
          break;
        case 'ageRestrictedSecondaryButtonName':
          data[valueKey] =
            !translations?.[language]?.secondaryButton1 &&
            !translations?.[language]?.secondaryButton2
              ? '-'
              : (
                  (translations?.[language]?.secondaryButton1 || '') +
                  ' ' +
                  (translations?.[language]?.secondaryButton2 || '')
                ).trim();
          break;
        default: {
          data[valueKey] = translations?.[language]?.[valueKey];
        }
      }
    });

    Object.keys(goodieBag.slotNames || {}).forEach(
      (key) => (data[key] = goodieBag.slotNames[key][language]?.name),
    );

    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'name',
      'shortDescription',
      goodieBag.rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
        ? 'shortDescriptionInCampaignListingPage'
        : null,
      'coverPhoto',
      'genericTermsAndConditionsTitle',
      'genericTermsAndConditionsContent',
      'cashVoucherName',
      'limitedOfferName',
      'isAgeRestricted',
      'ageRestrictedConditionsTitle',
      'ageRestrictedConditionsContent',
      'ageRestrictedPrimaryButtonName',
      'ageRestrictedSecondaryButtonName',
    ]);

    const othersFields = () => {
      return Object.keys(goodieBag.slotNames || {}).map((key, index) => [
        {
          title: `Slot ${index + 1} name`,
          field: key,
        },
      ]);
    };

    return (
      <>
        <MembershipInfoCard
          title={i18n.t('content', { locale: 'en' })}
          data={data}
          fields={[
            [
              {
                title: 'Title',
                field: 'name',
              },
            ],
            [
              {
                title: 'Short Description for Goodie Bag Landing Page',
                field: 'shortDescription',
                isHtml: true,
              },
            ],
            goodieBag.rewardType !==
            GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
              ? [
                  {
                    title:
                      'Short Description for Cash Voucher / Goodie Bag Coupon Listing Page',
                    field: 'shortDescriptionInCampaignListingPage',
                    isHtml: true,
                  },
                ]
              : null,
            [
              {
                title: 'Cover image',
                field: 'coverPhoto',
              },
            ],
            [
              {
                title: 'Generic Terms & Conditions Title',
                field: 'genericTermsAndConditionsTitle',
              },
            ],
            [
              {
                title: 'Generic Terms & Conditions Content',
                field: 'genericTermsAndConditionsContent',
                isHtml: true,
              },
            ],
          ]}
        />
        {goodieBag.rewardType !==
        GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ? (
          <div style={{ marginTop: '30px' }}>
            <MembershipInfoCard
              title={'Others'}
              data={data}
              fields={othersFields()}
            />
          </div>
        ) : (
          <div style={{ marginTop: '30px' }}>
            <MembershipInfoCard
              title={'age restricted'}
              data={data}
              fields={[
                [
                  {
                    title: `Is age restricted`,
                    field: 'isAgeRestricted',
                  },
                ],
                [
                  {
                    title: `Age restricted Terms & Conditions title`,
                    field: 'ageRestrictedConditionsTitle',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: `Age restricted Terms & Conditions content`,
                    field: 'ageRestrictedConditionsContent',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: `Primary button`,
                    field: 'ageRestrictedPrimaryButtonName',
                  },
                ],
                [
                  {
                    title: `Secondary button`,
                    field: 'ageRestrictedSecondaryButtonName',
                  },
                ],
              ]}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <ContentSections
      hidePreview
      languageTabContent={{
        containers: languageList?.map((item) => {
          console.log('@221', item);
          return {
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName || item.name,
          };
        }),
      }}
      activeSection={LanguageConfig.english}
      // sectionStyle=""
      contentStyle="campaign-detail-language-content-sections"
    />
  );
}
const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(StepFourContentSection);
