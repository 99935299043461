import {
  COUPON_ACQUIRED_PERIOD_OPTIONS,
  GOODIE_BAG_REWARD_TYPE_OPTIONS,
} from '../../models/GoodieBagsModel';

export const GoodieBagErrorHandleField = {
  ota: {
    name: 'ota',
    message: 'please select ota',
  },
  name: {
    name: 'name',
    message:
      'Please provide a goodie bag name and ensure the length not more than 200.',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover Image for Desktop.',
  },
  mobilePhoto: {
    name: 'mobilePhoto',
    message: 'Please provide a cover Image for Mobile.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide a short description', //and ensure the length not more than 200.
  },
  endDate: {
    name: 'endDate',
    message:
      'The end date & time of a goodie bag cannot be on or before the start date and time.',
  },
  displayEndDate: {
    name: 'displayEndDate',
    message:
      'The end date & time of a goodie bag cannot be on or before the start date and time.',
  },
  absoluteStartDateBeyondTheRange: {
    name: 'absoluteStartDateBeyondTheRange',
    message: 'The selected time must be within the active period.',
  },
  absoluteExpiryDate: {
    name: 'absoluteExpiryDate',
    message:
      'The end date & time of a goodie bag cannot be on or before the start date and time.',
  },
  absoluteExpiryDateBeyondTheRange: {
    name: 'absoluteExpiryDateBeyondTheRange',
    message: 'The selected time must be within the active period.',
  },
  numberOfDaysToExpireAfterAcquisition: {
    name: 'numberOfDaysToExpireAfterAcquisition',
    message: 'Please input an available number',
  },
  assignCreditPerLink: {
    name: 'assignCreditPerLink',
    message: 'Please input an available number',
  },
  assignQuotaPerLink: {
    name: 'assignQuotaPerLink',
    message: 'Please input an available number',
  },
  overallAmount: {
    name: 'overallAmount',
    message: 'Please input an available number',
  },
  slotName: {
    name: 'slotName',
    message: 'Please input a name',
  },
  ageRestrictedConditionsTitle: {
    name: 'ageRestrictedConditionsTitle',
    message: 'Please provide a age restricted terms & conditions title', //and ensure the length not more than 200.
  },
  ageRestrictedConditionsContent: {
    name: 'ageRestrictedConditionsContent',
    message: 'Please provide a age restricted terms & conditions content', //and ensure the length not more than 200.
  },
  primaryButton: {
    name: 'primaryButton',
    message: 'Please provide a primary button name', //and ensure the length not more than 200.
  },
  secondaryButton: {
    name: 'secondaryButton',
    message: 'Please provide a secondary button name', //and ensure the length not more than 200.
  },
};

function firstStepValidate({ getValues, setError }) {
  const errorList = [];
  const [ota, batchOta] = getValues(['ota', 'batchOta']);
  if (!ota?.length && !batchOta?.length) {
    errorList.push({
      name: 'ota',
      message: GoodieBagErrorHandleField.ota.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const [
    name,
    description,
    coverPhoto,
    mobilePhoto,
    // cashVoucherName,
    // limitedOfferName,
    ageRestrictedConditionsTitle,
    ageRestrictedConditionsContent,
    primaryButton1,
    primaryButton2,
    secondaryButton1,
    secondaryButton2,
    isAgeRestricted,
    rewardType,
    slotNames,
  ] = getValues([
    'translations.en.name',
    'translations.en.shortDescription',
    'translations.en.coverPhoto',
    'translations.en.mobilePhoto',
    // 'translations.en.cashVoucherName',
    // 'translations.en.limitedOfferName',
    'translations.en.ageRestrictedConditionsTitle',
    'translations.en.ageRestrictedConditionsContent',
    'translations.en.primaryButton1',
    'translations.en.primaryButton2',
    'translations.en.secondaryButton1',
    'translations.en.secondaryButton2',
    'isAgeRestricted',
    'rewardType',
    'slotNames',
  ]);
  console.log('@72', name, description, coverPhoto);
  const errorList = [];
  if (!name || name === '' || name.length > 200) {
    errorList.push({
      name: 'translations.en.name',
      message: GoodieBagErrorHandleField.name.message,
    });
  }
  if (!description) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: GoodieBagErrorHandleField.shortDescription.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: GoodieBagErrorHandleField.coverPhoto.message,
    });
  }
  if (!mobilePhoto) {
    errorList.push({
      name: 'translations.en.mobilePhoto',
      message: GoodieBagErrorHandleField.mobilePhoto.message,
    });
  }

  // if (
  //   rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value &&
  //   !cashVoucherName
  // ) {
  //   errorList.push({
  //     name: 'translations.en.cashVoucherName',
  //     message: GoodieBagErrorHandleField.slotName.message,
  //   });
  // }

  // if (
  //   rewardType &&
  //   rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value &&
  //   !limitedOfferName
  // ) {
  //   errorList.push({
  //     name: 'translations.en.limitedOfferName',
  //     message: GoodieBagErrorHandleField.slotName.message,
  //   });
  // }
  if (rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value) {
    Object.keys(slotNames).forEach((key, index) => {
      if (!slotNames[key].en.name) {
        errorList.push({
          name: `slotNames.${key}.en.name`,
          message: GoodieBagErrorHandleField.slotName.message,
        });
      }
    });
  } else if (isAgeRestricted) {
    if (!ageRestrictedConditionsTitle) {
      errorList.push({
        name: `translations.en.ageRestrictedConditionsTitle`,
        message: GoodieBagErrorHandleField.ageRestrictedConditionsTitle.message,
      });
    }

    if (!ageRestrictedConditionsContent) {
      errorList.push({
        name: `translations.en.ageRestrictedConditionsContent`,
        message:
          GoodieBagErrorHandleField.ageRestrictedConditionsContent.message,
      });
    }

    if (!primaryButton1 && !primaryButton2) {
      errorList.push({
        name: `translations.en.primaryButton`,
        message: GoodieBagErrorHandleField.primaryButton.message,
      });
    }
    if (!secondaryButton1 && !secondaryButton2) {
      errorList.push({
        name: `translations.en.secondaryButton`,
        message: GoodieBagErrorHandleField.secondaryButton.message,
      });
    }
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const errorList = [];
  const [
    isPublished,
    rewardType,
    isAlwaysActivePeriod,
    startDate,
    endDate,
    isAlwaysVisiblePeriod,
    displayStartDate,
    displayEndDate,
    couponAcquirePeriodType,
    absoluteStartDate,
    absoluteExpiryDate,
    numberOfDaysToExpireAfterAcquisition,
    // assignCreditPerLink,
    // assignQuotaPerLink,
    overallAmount,
    assignReward,
    ota,
    batchOta,
  ] = getValues([
    'isPublished',
    'rewardType',
    'isAlwaysActivePeriod',
    'startDate',
    'endDate',
    'isAlwaysVisiblePeriod',
    'displayStartDate',
    'displayEndDate',
    'couponAcquirePeriodType',
    'absoluteStartDate',
    'absoluteExpiryDate',
    'numberOfDaysToExpireAfterAcquisition',
    // 'assignCreditPerLink',
    // 'assignQuotaPerLink',
    'overallAmount',
    'assignReward',
    'ota',
    'batchOta',
  ]);
  if (!isAlwaysActivePeriod && startDate.getTime() >= endDate.getTime()) {
    errorList.push({
      name: 'endDate',
      message: GoodieBagErrorHandleField.endDate.message,
    });
  }
  if (
    !isAlwaysVisiblePeriod &&
    displayStartDate.getTime() >= displayEndDate.getTime()
  ) {
    errorList.push({
      name: 'displayEndDate',
      message: GoodieBagErrorHandleField.displayEndDate.message,
    });
  }

  if (rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value) {
    if (!isPublished && (!overallAmount || parseInt(overallAmount) < 0)) {
      errorList.push({
        name: 'overallAmount',
        message: GoodieBagErrorHandleField.overallAmount.message,
      });
    } else if (
      batchOta?.length > 0 &&
      (!overallAmount || parseInt(overallAmount) < 0)
    ) {
      errorList.push({
        name: 'overallAmount',
        message: GoodieBagErrorHandleField.overallAmount.message,
      });
    }

    if (
      couponAcquirePeriodType === COUPON_ACQUIRED_PERIOD_OPTIONS[1].value &&
      absoluteStartDate.getTime() >= absoluteExpiryDate.getTime()
    ) {
      errorList.push({
        name: 'absoluteExpiryDate',
        message: GoodieBagErrorHandleField.absoluteExpiryDate.message,
      });
    }
    if (
      couponAcquirePeriodType === COUPON_ACQUIRED_PERIOD_OPTIONS[1].value &&
      !isAlwaysActivePeriod
    ) {
      if (
        absoluteStartDate.getTime() < startDate.getTime() ||
        absoluteStartDate.getTime() > endDate.getTime()
      ) {
        errorList.push({
          name: 'absoluteStartDateBeyondTheRange',
          message:
            GoodieBagErrorHandleField.absoluteStartDateBeyondTheRange.message,
        });
      }
      if (
        absoluteExpiryDate.getTime() < startDate.getTime() ||
        absoluteExpiryDate.getTime() > endDate.getTime()
      ) {
        errorList.push({
          name: 'absoluteExpiryDateBeyondTheRange',
          message:
            GoodieBagErrorHandleField.absoluteExpiryDateBeyondTheRange.message,
        });
      }
    }
    console.log(
      '@178',
      numberOfDaysToExpireAfterAcquisition,
      parseInt(numberOfDaysToExpireAfterAcquisition),
    );
    if (
      couponAcquirePeriodType === COUPON_ACQUIRED_PERIOD_OPTIONS[2].value &&
      (!numberOfDaysToExpireAfterAcquisition ||
        parseInt(numberOfDaysToExpireAfterAcquisition) <= 0)
    ) {
      errorList.push({
        name: 'numberOfDaysToExpireAfterAcquisition',
        message:
          GoodieBagErrorHandleField.numberOfDaysToExpireAfterAcquisition
            .message,
      });
    }

    Object.keys(assignReward).forEach((key) => {
      if (!assignReward[key] || parseInt(assignReward[key] <= 0)) {
        errorList.push({
          name: `assignReward.${key}`,
          message: GoodieBagErrorHandleField.assignCreditPerLink.message,
        });
      }
    });
  }

  // if (
  //   rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value &&
  //   (!assignCreditPerLink || parseInt(assignCreditPerLink) <= 0)
  // ) {
  //   errorList.push({
  //     name: 'assignCreditPerLink',
  //     message: GoodieBagErrorHandleField.assignCreditPerLink.message,
  //   });
  // }
  // if (
  //   rewardType &&
  //   rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value &&
  //   (!assignQuotaPerLink || parseInt(assignQuotaPerLink) <= 0)
  // ) {
  //   errorList.push({
  //     name: 'assignQuotaPerLink',
  //     message: GoodieBagErrorHandleField.assignQuotaPerLink.message,
  //   });
  // }

  console.log('errors3:', errorList);
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
