import React from 'react';
import './SearchAreaMobile.scss';
import SearchAreaContent from './SearchAreaContent';

function SearchAreaMobile({ countryCodeList, isRegister, searchMembershipId }) {
  return (
    <div className="check-otp-search-container-mobile">
      <label className="list-section-container-header-title">Check OTP</label>
      <SearchAreaContent
        countryCodeList={countryCodeList}
        isRegister={isRegister}
        searchMembershipId={searchMembershipId}
      />
    </div>
  );
}

export default SearchAreaMobile;
