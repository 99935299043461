import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { DatePicker } from 'antd';
import './DateRangeSelection.scss';
import { TimeFormater } from '../../utils/TimeFormatUtil';
import { useDispatch } from 'react-redux';
const { RangePicker } = DatePicker;

const RANGE_TYPES = {
  TODAY: '0',
  LAST_7_DAYS: '1',
  LAST_30_DAYS: '2',
  CUSTOM: '3',
  THIS_MONTH: '4',
};

const START_DATE_DEFAULT_TIME = { hour: 0, minute: 0, second: 0 };
const END_DATE_DEFAULT_TIME = { hour: 23, minute: 59, second: 59 };
const TIME_FORMAT = 'HH:mm';

const getDateTimeApplyTime = ({ hour, minute, second }) => {
  const time = moment()
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second);

  return time;
};

export const getStartEndDate = (range, start, end) => {
  console.log('getStartEndDate:', range, start, end);
  if (range === RANGE_TYPES.TODAY) {
    return {
      rangeType: range,
      startDate: getDateTimeApplyTime(START_DATE_DEFAULT_TIME),
      endDate: getDateTimeApplyTime(END_DATE_DEFAULT_TIME),
    };
  }
  if (range === RANGE_TYPES.LAST_30_DAYS) {
    const today = getDateTimeApplyTime(START_DATE_DEFAULT_TIME);
    return {
      rangeType: range,
      startDate: today.add(-30, 'days'),
      endDate: getDateTimeApplyTime(END_DATE_DEFAULT_TIME).add(-1, 'days'),
    };
  }
  if (range === RANGE_TYPES.THIS_MONTH) {
    return {
      rangeType: range,
      startDate: getDateTimeApplyTime(START_DATE_DEFAULT_TIME).startOf('month'),
      endDate: getDateTimeApplyTime(END_DATE_DEFAULT_TIME).endOf('month'),
    };
  }
  if (range === RANGE_TYPES.CUSTOM) {
    return {
      rangeType: range,
      startDate: moment(start, TimeFormater.dayMonthYearTimePeriod).set(
        'second',
        0,
      ),
      endDate: moment(end, TimeFormater.dayMonthYearTimePeriod).set(
        'second',
        59,
      ),
    };
  }
  return {
    rangeType: RANGE_TYPES.LAST_7_DAYS,
    startDate: getDateTimeApplyTime(START_DATE_DEFAULT_TIME).add(-7, 'days'),
    endDate: getDateTimeApplyTime(END_DATE_DEFAULT_TIME).add(-1, 'days'),
  };
};

const DateRangeSelection = ({
  applyDateRangeEvent,
  clearDataEvent,
  onTab,
  rangeLimitHour,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { range, start, end } = queryString.parse(location.search);
  const { rangeType, startDate, endDate } = getStartEndDate(range, start, end);
  const [tempStartDate, setTempStartDate] = React.useState(startDate);
  const [tempEndDate, setTempEndDate] = React.useState(endDate);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  React.useEffect(() => {
    applyDateRangeEvent && applyDateRangeEvent(startDate, endDate);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    dispatch({
      type: 'dashboardCustomer/updateState',
      payload: {
        startDate,
        endDate,
      },
    });
  }, [range, start, end]);
  console.log('tempStartDate:', tempStartDate);
  React.useEffect(() => {
    if (!datePickerOpen && initialized) {
      history.push({
        pathname: location.pathname,
        search: `?range=${RANGE_TYPES.CUSTOM}&start=${tempStartDate.format(
          TimeFormater.dayMonthYearTimePeriod,
        )}&end=${tempEndDate.format(TimeFormater.dayMonthYearTimePeriod)}`,
        hash: location.hash,
      });
    }
  }, [datePickerOpen]);

  React.useEffect(() => {
    applyDateRangeEvent && applyDateRangeEvent(startDate, endDate);
    setInitialized(true);
    return () => {
      clearDataEvent && clearDataEvent();
    };
  }, []);
  return (
    <div
      className={`list-section-container-header-buttons dashboard-header-right ${
        onTab ? 'dashboard-tab-range-selection' : ''
      }`}
    >
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.TODAY ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.TODAY) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.TODAY}`,
              hash: location.hash,
            });
          }
        }}
      >
        Today
      </div>
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.LAST_7_DAYS ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.LAST_7_DAYS) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.LAST_7_DAYS}`,
              hash: location.hash,
            });
          }
        }}
      >
        Last 7 days
      </div>
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.LAST_30_DAYS ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.LAST_30_DAYS) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.LAST_30_DAYS}`,
              hash: location.hash,
            });
          }
        }}
      >
        Last 30 days
      </div>
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.THIS_MONTH ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.THIS_MONTH) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.THIS_MONTH}`,
              hash: location.hash,
            });
          }
        }}
      >
        This month
      </div>
      <RangePicker
        showTime={{ format: TIME_FORMAT }}
        value={[tempStartDate, tempEndDate]}
        className="date-picker-extra"
        format={TimeFormater.dayMonthYearTimePeriod}
        disabledDate={(current) => current > moment().endOf('day')}
        separator={'-'}
        onChange={(values) => {
          if (!values) {
            return;
          }
          if (
            rangeLimitHour &&
            (values[1] - values[0]) / 1000 / 60 / 60 > rangeLimitHour
          ) {
            return;
          }

          console.log('165: ', values, values[1] - values[0]);
          setTempStartDate(values[0]);
          setTempEndDate(values[1]);
        }}
        onOpenChange={setDatePickerOpen}
      />
    </div>
  );
};

export default DateRangeSelection;
