import React, { useState, useEffect } from 'react';
import './BasePrompt.scss';
import CloseIcon from '../../../assets/images/prompt_close_icon.svg';
import { Modal, Image } from 'react-bootstrap';
import PromptButton from './BaseButtons';
import { BUTTON_TYPES } from './BaseButtons';

/**
 * the `leftButton` and `rightButton` config should be like :
 *  {
 *      text: "close",
 *      action: () => {},
 *      class: "button-class",
 *      disabled: false,
 *      requires: PermissionCodes
 *      type: BUTTON_TYPES
 * }
 *
 * the `otherBody` should be a function which returns a component
 */

function BasePrompt(props) {
  const closeAction = props.closeAction || (() => {});
  const leftButton = props.leftButton;
  const rightButton = props.rightButton;
  const title = props.title;
  const description = props.description;
  const otherBody = props.otherBody || (() => {});
  const customClass = props.customClass || '';
  const customContentStyle = props.customContentStyle || {};
  const customOtherBodyClass = props.customOtherBodyClass || '';
  const basePromptButtonsClassName = props.basePromptButtonsClassName || '';
  const basePromptDescriptionClassName =
    props.basePromptDescriptionClassName || '';
  const centered = props.centered === undefined ? true : props.centered;
  const [show, setShow] = useState(props.show);

  const buttonAction = (button) => {
    if (button?.disabled) {
      return;
    }
    setShow(false);
    if (button?.action) {
      button.action();
    }
  };

  const handleClose = () => {
    setShow(false);
    closeAction();
  };

  if (!show && props.show) {
    setShow(true);
  }

  const getButton = (button, defaultType) => {
    if (!button || !button.text) {
      return null;
    }
    return (
      <PromptButton
        title={button.text}
        action={() => buttonAction(button)}
        requires={button.requires}
        className={button.class}
        type={button.type || defaultType}
        disabled={button.disabled}
      />
    );
  };

  useEffect(() => {
    setShow(props.show);

    if (props.show && props.onShow) {
      props.onShow();
    }
  }, [props.show]);

  const body = () => {
    if (!leftButton && rightButton) {
      rightButton.class = `${rightButton?.class} extra-main-button-margin`;
    }

    return (
      <>
        {title ? <div className="base-prompt-header">{title}</div> : null}

        {description ? (
          <div
            className={`base-prompt-description ${basePromptDescriptionClassName}`}
          >
            {description}
          </div>
        ) : null}
        {otherBody ? (
          <div className={`base-prompt-other-body ${customOtherBodyClass}`}>
            {otherBody()}
          </div>
        ) : null}
        <div className={`base-prompt-buttons ${basePromptButtonsClassName}`}>
          {getButton(leftButton, BUTTON_TYPES.secondary)}
          {getButton(rightButton, BUTTON_TYPES.main)}
        </div>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
      style={customContentStyle}
      dialogClassName={props.dialogClassName}
    >
      <Modal.Body className={`base-prompt-container ${customClass}`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={handleClose}
        />
        {body()}
      </Modal.Body>
    </Modal>
  );
}

export default BasePrompt;
