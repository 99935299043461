import {
  CheckStatus,
  CUSTOMER_ID_TYPE,
  SavedStatus,
  StatusTag,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  activeEntitlementCustomers,
  deleteEntitlementCustomers,
  entitlementCustomers,
  getEntitlements,
} from '../services/CampaignAPIHelper';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { parseCouponSetListDate } from './CouponUtil';
import { loading } from './LoadingUtil';

export const FORTUNEBAG_ENTITLEMENT_OPTIONS = {
  A: 'a',
  B: 'b',
  C: 'c',
};

export const FORTUNEBAG_OPTIONS_BUTTON_TYPE = {
  COPY: 'copy',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
};

const getInitialState = () => ({
  customerList: [],
  customerGroup: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Customer ID', fieldName: 'displayCustomer' },
    { displayName: 'Type', fieldName: 'customerType' },
    { displayName: 'No. of bags', fieldName: 'amount' },
    { displayName: 'Create at', fieldName: 'creationDate' },
    { displayName: 'Last Update', fieldName: 'lastModifiedDate' },
    { displayName: 'Upload by', fieldName: 'administrator' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  pagedCustomerList: [],
  checked: CheckStatus.initOrNotChecked,
  saved: SavedStatus.init,
  activityLogs: [],
  entitlementOptionDatas: [],
  entitlementOption: '',
  merchantTableHeaders: [],
});

const parseEntitlementOptions = (rawDatas) => {
  const optionMapping = {
    OPTION_A: FORTUNEBAG_ENTITLEMENT_OPTIONS.A,
    OPTION_B: FORTUNEBAG_ENTITLEMENT_OPTIONS.B,
    OPTION_C: FORTUNEBAG_ENTITLEMENT_OPTIONS.C,
  };

  let option = optionMapping?.[rawDatas[0]?.node?.method];

  const optionData = rawDatas?.map((item) => {
    return {
      pk: item.node.pk,
      creationDate: item.node.creationDate,
      displayLastModifiedDate: formatDate(
        item.node.lastModifiedDate,
        TimeFormater.monthDayYearTime,
      ),
      entitlementId: item.node.pk,
      merchantName: item.node.merchant.name,
      merchantId: item.node.merchant.pk,
      secretKey: item.node.merchantScretkey,
      merchantStaticLink: item.node.staticLink,
      wechatStaticLink: item.node.wechatStaticLink,
      entitledCustomerFileName: item.node.entitledCustomerFileName,
      fortuneBagUrl: item.node.fortuneBagUrls,
      fortuneBagQuantity: item.node.fortuneBagQuantity
        ? item.node.fortuneBagQuantity
        : 0,
      batch: item.node.batch,
      batchNo: item.node.batch.index,
    };
  });
  return [optionData, option];
};

const parseDetail = (node) => {
  const maskLength = node.customer.length / 3;
  const start = node.customerType === 'EMAIL' ? 0 : 3;

  const displayCustomerId = () => {
    const name = node.customer.replace(
      node.customer.substring(start, start + maskLength),
      '****',
    );

    if (node.customerType === 'EMAIL') {
      return name;
    } else if (name.indexOf('+') !== 0) {
      return `+${name}`;
    }
    return name;
  };

  return {
    ...node,
    displayCustomer: displayCustomerId(),
    customerType:
      node.customerType === 'EMAIL'
        ? CUSTOMER_ID_TYPE.EMAIL
        : CUSTOMER_ID_TYPE.MOBILE_NUMBER,
    creationDate: parseCouponSetListDate(node.creationDate),
    lastModifiedDate: parseCouponSetListDate(node.lastModifiedDate),
    administrator: node.administrator?.username,
    status: ((nodeStatus) => {
      let status;
      switch (nodeStatus) {
        case 'ACTIVE':
          status = StatusTag.active;
          break;
        case 'DEACTIVE':
          status = StatusTag.deactive;
          break;
        case 'ACQUIRED':
          status = StatusTag.acquired;
          break;
        default:
          status = StatusTag.deactive;
          break;
      }
      return status;
    })(node.status),
  };
};

export default createModel({
  namespace: 'fortuneBagEntitlement',
  states: getInitialState(),
  params: {
    sessionKey: SESSION_KEYS.ENTITLEMENT_CUSTOMER_SESSION_KEY,
    dataKey: SESSION_KEYS.ENTITLEMENT_CUSTOMERS_SESSION_KEY,
    listAPI: entitlementCustomers,
    pageCount: 5,
    parse: (data) =>
      data?.entitlementCustomers.edges.map((item) => parseDetail(item.node)),
    deleteAPI: deleteEntitlementCustomers,
  },
  reducers: {},
  effects: {
    getEntitlements: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getEntitlements, payload];
        function* onSuccess(data) {
          console.log('@135', data);
          const [entitlementOptionDatas, entitlementOption] =
            parseEntitlementOptions(data?.entitlements.edges);
          yield put({
            type: 'updateState',
            payload: {
              entitlementOptionDatas,
              entitlementOption,
            },
          });
        }
        function onFailed(data) {
          console.log('@@157: ', data);
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
    ],

    activeEntitlementCustomers: [
      function* ({ payload }) {
        const inputBody = {
          id: payload.data.id,
          isActive: payload.data.isActive,
        };
        const afterAction = payload.afterAction || (() => {});

        const serviceArgs = [activeEntitlementCustomers, inputBody];

        function onSuccess(data) {
          console.log('241', data);
          afterAction();
        }

        function onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
});
