import React from 'react';
import './Loading.scss';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';
import NoDataIcon from '../../assets/images/nodata.svg';

function NoDataPage({ customClass, customImageClass }) {
  return (
    <div className={`loading-area ${customClass}`}>
      <Image src={NoDataIcon} className={`${customImageClass}`}></Image>
    </div>
  );
}

export default NoDataPage;
