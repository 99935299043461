import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import MembershipInfoEditCard from '../../../components/customer/MembershipInfoEditCard';
import PersonalInfoEditCard from '../../../components/customer/PersonalInfoEditCard';
import ContactInfoEditCard from '../../../components/customer/ContactInfoEditCard';
import Loading from '../../../components/base/Loading';
import {
  CheckStatus,
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import CustomerEditFormStepOne from './CustomerEditFormStepOne';

function CustomerEditForm({
  customer,
  hasUpdatedDefaultValues,
  // checked,
  errorFields,
  saved,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  // const [didMount, setDidMount] = useState(false);
  // const [sections, setSections] = useState([<Loading />]);

  // useEffect(() => {
  //   if (checked === CheckStatus.checkedWithSuccess) {
  //     dispatch(createAction('customerList/updateCustomer')({}));
  //   }
  // }, [dispatch, checked]);
  useEffect(() => {
    if (
      saved === SavedStatus.savedWithFail ||
      saved === SavedStatus.savedWithSuccess
    ) {
      history.push('/customers#List%20of%20Customers');
    }
  }, [saved, history]);

  // useEffect(() => setDidMount(true), []);

  useEffect(() => {
    dispatch(createAction('customerList/getOneCustomer')({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      createAction('singleCoupon/getList')({
        ssoUid: customer.ssoUid,
        isAll: true,
        needTemplate: true,
        isExpired: 'false',
        isUsed: 'false',
        orderBy: '-pk',
        pageSize: 100,
      }),
    );
  }, [customer]);

  // useEffect(() => {
  //   if (didMount && customer.pk) {
  //     setSections([
  //       <MembershipInfoEditCard
  //         title="Membership Information"
  //         customer={customer}
  //         errorFields={errorFields}
  //       />,
  // <PersonalInfoEditCard
  //   title="Membership Information"
  //   customer={customer}
  //   errorFields={errorFields}
  // />,
  // <ContactInfoEditCard
  //   title="Contact Information"
  //   customer={customer}
  //   errorFields={errorFields}
  // />,
  //     ]);
  //   }
  // }, [didMount, customer, errorFields]);

  // const sections = [
  //   <MembershipInfoEditCard
  //     title="Membership Information"
  //     customer={customer}
  //   />,
  //   <PersonalInfoEditCard title="Membership Information" customer={customer} />,
  //   <ContactInfoEditCard title="Contact Information" customer={customer} />,
  // ];

  const stepSet = [<CustomerEditFormStepOne />];

  return (
    <BaseForm
      defaultValues={customer}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      formHasSubmitted={saved === SavedStatus.savedWithSuccess ? true : false}
      tempSave={(save) => {
        if (save) {
          dispatch({ type: 'customerList/checkValsValid' });
        }
      }}
      content={stepSet}
      currentStep={0}
      breadcrumb={<CustomBreadcrumb name={customer.name} />}
      caution={{
        detail: '',
        title: customer.name,
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer || {},
  hasUpdatedDefaultValues: state.customerList.hasUpdatedDefaultValues,
  // checked: state.customerList.checked,
  errorFields: state.customerList.errorFields,
  saved: state.customerList.saved,
});

export default connect(mapPropsToState)(CustomerEditForm);
