import React, { useState, useEffect } from 'react';
import './CouponKeyVisual.scss';
import { Controller, useFormContext } from 'react-hook-form';
import ImageUploader from '../../base/ImageUploader';
import { useDispatch, connect } from 'react-redux';
import { createAction, insertKeyToAllLanguages } from '../../../utils';
import { CouponErrorFields } from './CouponHandleError';
import i18n from '../../../I18n';
import {
  COUPON_REDEMPTION_METHOD,
  LanguageConfig,
} from '../../../config/CustomEnums';
import { errorMessage, hasError } from '../../base/ErrorFieldMessage';
import {
  DISCOUNT_CODE_FORMAT,
  DISPLAY_IN_TYPE,
  saveCouponToSessionStorage,
} from '../../../models/CreateCouponModel';
import CustomRadios from '../../base/CustomRadios';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CreateLogoLabelPrompt from '../../logoLabel/CreateLogoLabelPrompt';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { checkPartnerCodeFormat } from '../../../models/CouponUtil';
import { useParams } from 'react-router-dom';

const NewCouponKeyVisual = ({
  language,
  languages,
  focusChange,
  logoLabelAllList,
  newLogoLabelDetail,
  newLogoLabelPk,
  couponTemplate,
}) => {
  const { id } = useParams();
  const isEditMode = !!id;
  const languagesList = languages?.map((item) => item.code);
  const [showPrompt, setShowPrompt] = useState(false);
  const [newLogoLabel, setNewLogoLabel] = useState(false);
  const dispatch = useDispatch();
  const { control, watch, setValue, formState, getValues } = useFormContext();
  const { errors } = formState;
  const watchCoverPhoto = watch(`translations.${language}.coverPhoto`);
  const watchOtherPhoto = watch(`translations.${language}.otherPhoto`);
  const watchLogoLabel = watch(`translations.${language}.logoLabel`) || [];
  const watchDisplayIn = watch('displayIn');
  const watchDiscountCodeFormat =
    watch(`discountCodeFormat`) || DISCOUNT_CODE_FORMAT.CODE;
  const watchShowLandingPage = watch(`showLandingPage`);
  const watchLandingPageButtonName = watch(
    `translations.${language}.landingPageButtonName`,
  );
  const watchLandingPageUrl = watch(`translations.${language}.landingPageUrl`);
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );

  const watchRedemptionMethod = watch('redemptionMethod');

  const isPartnerCodeFormat = checkPartnerCodeFormat(watchDiscountCodeFormat);

  useEffect(() => {
    if (newLogoLabel && newLogoLabelPk) {
      dispatch(
        createAction('logoLabel/getOneDetail')({
          id: newLogoLabelPk,
        }),
      );
    }
  }, [newLogoLabel, newLogoLabelPk]);

  useEffect(() => {
    if (newLogoLabel && newLogoLabelDetail?.pk && watchLogoLabel) {
      const filterLogoLabel = watchLogoLabel.filter(
        (item) => item.pk != newLogoLabelDetail.pk,
      );
      setValue(
        `translations.${language}.logoLabel`,
        [...filterLogoLabel, newLogoLabelDetail],
        {
          shouldDirty: true,
        },
      );
      setNewLogoLabel(false);
    }
  }, [newLogoLabelDetail]);

  useEffect(() => {
    dispatch({ type: 'logoLabel/clearState' });
    dispatch({ type: 'logoLabel/getAllList' });
    return () => {
      dispatch({ type: 'logoLabel/clearState' });
    };
  }, []);

  // console.log("applyDetailPhotosToAllLanguage:", applyDetailPhotosToAllLanguage)

  const getImages = () => {
    console.log('watchOtherPhoto:', language, watchOtherPhoto);
    // return Array.isArray(watchCoverPhoto) ? watchCoverPhoto :  watchCoverPhoto ? [watchCoverPhoto] : []
    return watchCoverPhoto ? [watchCoverPhoto] : [];
  };
  return (
    <div>
      <div className="first-section-title">
        {i18n.t('key_visual', { locale: 'en' })}
      </div>
      <div className="second-section">
        <div className="second-section-title">
          {i18n.t('cover_photo', { locale: 'en' })}
        </div>
        <div className="second-section_description">
          {i18n.t('cover_photo_suggest', { locale: 'en' })}
        </div>
        <span id={`${CouponErrorFields.coverPhotoEmpty.name}-${language}`} />
        <Controller
          control={control}
          name={`translations.${language}.coverPhoto`}
          rules={
            language === LanguageConfig.english
              ? { required: CouponErrorFields.coverPhotoEmpty.message }
              : {}
          }
          render={() => (
            <ImageUploader
              minWidth={634}
              minHeight={476}
              disabled={
                applyCoverPhotoToAllLanguage &&
                language !== LanguageConfig.english
              }
              images={watchCoverPhoto ? [watchCoverPhoto] : []}
              onImageStateChange={(newState) => {
                const applyLanguages = applyCoverPhotoToAllLanguage
                  ? languagesList
                  : [language];
                applyLanguages.forEach((item) => {
                  setValue(
                    `translations.${item}.coverPhoto`,
                    newState ? (newState?.[0] ? newState?.[0] : null) : null,
                    {
                      shouldDirty: true,
                    },
                  );
                });
                saveCouponToSessionStorage(getValues());
              }}
              aspect={634 / 476}
              maxImageNum={1}
              language={language}
              photoSectionTitle={'coupon_cover_photo'}
              uploadImageClicked={() => {
                focusChange('coverPhoto');
              }}
              errorMessage={
                hasError(errors, `translations.${language}.coverPhoto`)
                  ? CouponErrorFields.coverPhotoEmpty.message
                  : ''
              }
            />
          )}
        />
        <ApplySwitch
          disabled={
            language === LanguageConfig.english && !watchCoverPhoto?.value
          }
          show={language === LanguageConfig.english}
          checked={applyCoverPhotoToAllLanguage}
          onChange={(value) => {
            setValue('applyCoverPhotoToAllLanguage', value, {
              shouldDirty: true,
            });
            if (value) {
              insertKeyToAllLanguages({
                languages: languagesList,
                key: 'coverPhoto',
                value: watchCoverPhoto,
                setValue,
                getValues,
              });
            }
          }}
        />
      </div>
      <div className="second-section">
        <div className="second-section-title">
          {i18n.t('other_photos', { locale: 'en' })}
        </div>
        <div className="second-section_description">
          {i18n.t('cover_photo_suggest', { locale: 'en' })}
        </div>
        <Controller
          control={control}
          name={`translations.${language}.otherPhoto`}
          render={() => (
            <ImageUploader
              minWidth={634}
              minHeight={476}
              disabled={
                applyDetailPhotosToAllLanguage &&
                language !== LanguageConfig.english
              }
              images={watchOtherPhoto || []}
              onImageStateChange={(newState) => {
                const applyLanguages = applyDetailPhotosToAllLanguage
                  ? languagesList
                  : [language];
                applyLanguages.forEach((item) => {
                  setValue(`translations.${item}.otherPhoto`, newState, {
                    shouldDirty: true,
                  });
                });
                saveCouponToSessionStorage(getValues());
              }}
              aspect={634 / 476}
              maxImageNum={4}
              language={language}
              photoSectionTitle={'coupon_other_photo'}
              uploadImageClicked={() => {
                focusChange('otherPhotos');
              }}
              errorMessage=""
            />
          )}
        />
        <ApplySwitch
          disabled={
            language === LanguageConfig.english && !watchOtherPhoto?.length
          }
          show={language === LanguageConfig.english}
          checked={applyDetailPhotosToAllLanguage}
          onChange={(value) => {
            setValue('applyDetailPhotosToAllLanguage', value, {
              shouldDirty: true,
            });
            if (value) {
              insertKeyToAllLanguages({
                languages: languagesList,
                key: 'otherPhoto',
                value: watchOtherPhoto,
                setValue,
                getValues,
              });
            }
          }}
        />
      </div>
      <div
        className="second-section"
        onFocus={() => {
          console.log('focus logo label' + language);
        }}
      >
        <label className="create-section-label">
          Display in (in front end My Wallet section)
        </label>
        <div style={{ display: 'flex', marginTop: 8 }}>
          <CustomRadios
            options={[
              {
                label: 'Coupon section',
                value: DISPLAY_IN_TYPE.COUPON_SECTION,
              },
              {
                label: 'Voucher section',
                value: DISPLAY_IN_TYPE.VOUCHER_SECTION,
              },
              {
                label: 'Ticket section',
                value: DISPLAY_IN_TYPE.TICKET_SECTION,
              },
            ]}
            default={watchDisplayIn}
            onChange={(value) => {
              setValue('displayIn', value, { shouldDirty: true });
            }}
          />
        </div>
      </div>
      {watchRedemptionMethod ===
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ? (
        <>
          {language === LanguageConfig.english ? (
            <>
              <div>
                <CustomTitleLabel title="Coupon code display format" />
                <div style={{ display: 'flex', marginTop: 8 }}>
                  <CustomRadios
                    options={[
                      {
                        label: 'Coupon code',
                        value: DISCOUNT_CODE_FORMAT.CODE,
                      },
                      {
                        label: 'Coupon URL',
                        value: DISCOUNT_CODE_FORMAT.URL,
                        disabled:
                          isEditMode &&
                          (couponTemplate?.totalNubmerOfGeneratedCoupons > 0 ||
                            couponTemplate?.totalNubmerOfGeneratedPhysicalCoupons >
                              0) &&
                          couponTemplate.discountCodeFormat !==
                            DISCOUNT_CODE_FORMAT.URL,
                      },
                      {
                        label: 'Partner QR code',
                        value: DISCOUNT_CODE_FORMAT.QRCODE,
                      },
                      {
                        label: 'Partner Barcode',
                        value: DISCOUNT_CODE_FORMAT.BARCODE,
                        disabled:
                          isEditMode &&
                          (couponTemplate?.totalNubmerOfGeneratedCoupons > 0 ||
                            couponTemplate?.totalNubmerOfGeneratedPhysicalCoupons >
                              0) &&
                          couponTemplate.discountCodeFormat ===
                            DISCOUNT_CODE_FORMAT.QRCODE,
                      },
                    ]}
                    default={watchDiscountCodeFormat}
                    onChange={(value) => {
                      setValue('discountCodeFormat', value, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </div>
              </div>
              {watchDiscountCodeFormat !== DISCOUNT_CODE_FORMAT.URL &&
              !isPartnerCodeFormat ? (
                <Controller
                  control={control}
                  name="showLandingPage"
                  render={({ field: { value } }) => (
                    <CustomTitleWithSwitch
                      title="Showing landing page URL"
                      defaultValue={value}
                      setValue={(newValue) => {
                        setValue('showLandingPage', newValue, {
                          shouldDirty: true,
                        });
                        if (newValue && !watchLandingPageButtonName) {
                          setValue(
                            `translations.${language}.landingPageButtonName`,
                            i18n.t('book_now2', { locale: language }),
                            {
                              shouldDirty: true,
                            },
                          );
                        }
                      }}
                    />
                  )}
                />
              ) : null}
            </>
          ) : null}

          {(watchShowLandingPage ||
            watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL) &&
          !isPartnerCodeFormat ? (
            <Controller
              control={control}
              name={`translations.${language}.landingPageButtonName`}
              render={({ field: { value } }) => (
                <CustomTitleWithInput
                  title={`Landing page button name`}
                  defaultValue={
                    value || i18n.t('book_now2', { locale: language })
                  }
                  useDefaultValue={false}
                  setValue={(newValue) => {
                    setValue(
                      `translations.${language}.landingPageButtonName`,
                      newValue,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  error={{
                    id: `translations.${language}.landingPageButtonName`,
                    error: hasError(
                      errors,
                      `translations.${language}.landingPageButtonName`,
                    ),
                    message: errorMessage(
                      errors,
                      `translations.${language}.landingPageButtonName`,
                    ),
                  }}
                />
              )}
            />
          ) : null}

          {watchShowLandingPage &&
          watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.CODE ? (
            <Controller
              control={control}
              name={`translations.${language}.landingPageUrl`}
              render={({ field: { value } }) => (
                <CustomTitleWithInput
                  title="Landing page URL"
                  defaultValue={value}
                  setValue={(newValue) => {
                    setValue(
                      `translations.${language}.landingPageUrl`,
                      newValue,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  error={{
                    id: `translations.${language}.landingPageUrl`,
                    error: hasError(
                      errors,
                      `translations.${language}.landingPageUrl`,
                    ),
                    message: errorMessage(
                      errors,
                      `translations.${language}.landingPageUrl`,
                    ),
                  }}
                  multipleLines={true}
                  customClass="muiltpleLinesInput"
                />
              )}
            />
          ) : null}
        </>
      ) : null}

      <div
        onFocus={() => {
          focusChange('logoLabel');
        }}
      >
        <Controller
          control={control}
          name={`translations.${language}.logoLabel`}
          render={() => (
            <>
              <BaseMutipleSelectorV2
                title="Logo label (optional)"
                namespace="logoLabel"
                onFocusFunc={() => {
                  focusChange('logoLabel');
                }}
                data={{
                  sourceData: logoLabelAllList,
                  targetData: watchLogoLabel || [],
                  targetChange: (value) => {
                    setValue(`translations.${language}.logoLabel`, value, {
                      shouldDirty: true,
                    });
                  },
                }}
                addButton={{
                  action: () => {
                    setShowPrompt(true);
                  },
                  title: 'Add logo label',
                  requires: PermissionCodes.addLogoLabel,
                  customClass: 'general-section-add-new btn-add-button-common',
                }}
              />
            </>
          )}
        />
      </div>
      <CreateLogoLabelPrompt
        show={showPrompt}
        onClose={() => {
          setShowPrompt(false);
        }}
        onConfirm={() => {
          setNewLogoLabel(true);
        }}
      />
    </div>
  );
};
const mapPropsToState = (state) => ({
  logoLabelAllList: state.logoLabel.notPagedAllList,
  newLogoLabelDetail: state.logoLabel.detail,
  newLogoLabelPk: state.logoLabel?.newLogoLabelPk,
  couponTemplate: state.createCoupon.couponTemplate,
});

export default connect(mapPropsToState)(NewCouponKeyVisual);
