import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignType } from '../../../config/CustomEnums';
import { Tooltip } from 'antd';
import './Filter.scss';

function LabelWithToolTips({ title, className }) {
  return (
    <Tooltip placement="topLeft" title={title}>
      <label className={className}>{title}</label>
    </Tooltip>
  );
}

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    brandList,
    customerGroupList,
    segmentList,
    levelList,
    goodieBags,
    attributeTags,
    workingTeamList,
    // campaignGroupList,
  } = useSelector((state) => ({
    brandList: state.brand.notPagedAllList,
    customerGroupList: state.customerGroup.groupList,
    segmentList: state.segments.segmentList,
    levelList: state.levels.levelList,
    goodieBags: state.goodieBags.notPagedAllList,
    attributeTags: state.attributeTag.notPagedAllList,
    workingTeamList: state.workingTeam.notPagedAllList,
    // campaignGroupList: state.campaignGroups.notPagedAllList,
  }));

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchPublishStatus = parsedSearch['status'] || '';
  const searchExpiredStatus = parsedSearch['expired'] || '';
  const searchMessageChannel = parsedSearch['message_channel'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchVisablePeriod = parsedSearch['visible_period'] || '';

  const searchTargetCustomer = parsedSearch['target_customer'] || '';
  const searchGroups = parsedSearch['groupsIn'] || '';
  const searchSegments = parsedSearch['segmentsIn'] || '';
  const searchLevels = parsedSearch['levelsIn'] || '';
  const searchIsRedirect = parsedSearch['redirection_campaign'];
  const searchGoodie = parsedSearch['for_goodie_bag'] || '';
  const searchAttributeTag = parsedSearch['tags'] || '';
  const searchAvailableCoupon = parsedSearch['available_coupon'];
  const searchUsedCoupon = parsedSearch['used_coupon'];
  const searchAcquiredCoupon = parsedSearch['acquired_coupon'];
  const searchGeneratedCoupon = parsedSearch['generated_coupon'];

  const searchStarredCampaign = parsedSearch['starred_campaign'];

  const [type, setType] = useState(searchType);
  const [status, setStatus] = useState(searchPublishStatus);
  const [expired, setExpired] = useState(searchExpiredStatus);
  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchMessageChannel),
  );
  const [brand, setBrand] = useState(ArrayStringData(searchBrand));
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visablePeriod, setVisablePeriod] = useState(searchVisablePeriod);
  const [targetCustomer, setTargetCustomer] = useState(searchTargetCustomer);
  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  const [levels, setLevels] = useState(ArrayStringData(searchLevels));
  const [isRedirect, setIsRedirect] = useState(searchIsRedirect);
  const [goodie, setGoodie] = useState(ArrayStringData(searchGoodie));
  const [tags, setTags] = useState(ArrayStringData(searchAttributeTag));

  const [goodieData, setGoodieData] = useState();
  const [sourceTagsData, setSourceTagsData] = useState();
  const [tagsData, setTagsData] = useState();
  const [hasAvailableCoupon, setHasAvailableCoupon] = useState(
    searchAvailableCoupon,
  );
  const [hasUsedCoupon, setHasUsedCoupon] = useState(searchUsedCoupon);
  const [hasAcquiredCoupon, setHasAcquiredCoupon] =
    useState(searchAcquiredCoupon);
  const [hasGeneratedCoupon, setHasGeneratedCoupon] = useState(
    searchGeneratedCoupon,
  );
  const [searchBrandData, setSearchBrandData] = useState();

  const [isStarredCampaign, setIsStarredCampaign] = useState(
    searchStarredCampaign,
  );

  const searchWorkingTeam = parsedSearch['working_team'] || '';
  const [workingTeam, setWorkingTeam] = useState(
    ArrayStringData(searchWorkingTeam),
  );
  const [searchWorkingTeamData, setSearchWorkingTeamData] = useState();
  const searchCampaignGroup = parsedSearch['fortune_bag_group'] || '';
  const [campaignGroupData, setCampaignGroupData] = useState(null);
  const [campaignGroup, setCampaignGroup] = useState(
    ArrayStringData(searchCampaignGroup),
  );

  useEffect(() => {
    dispatch({ type: 'goodieBags/getAllList', payload: { isSimplify: true } });

    dispatch(createAction('brand/getAllList')({ isAll: true }));

    dispatch({ type: 'workingTeam/getAllList', payload: {} });

    // dispatch(createAction('customerGroup/getGroupList')({}));
    // dispatch(
    //   createAction('segments/getSegmentsList')({
    //     type: 'all',
    //     isSelectorLoad: true,
    //   }),
    // );
    // dispatch(createAction('levels/getLevelList')({}));
    dispatch({
      type: 'attributeTag/getAllList',
      payload: { linkedCampaign: true },
    });
    // dispatch({
    //   type: 'campaignGroups/getAllList',
    //   payload: {},
    // });

    return () => {
      dispatch({ type: 'brand/clearData' });
      dispatch({ type: 'goodieBags/clearData' });
      dispatch({ type: 'attributeTag/clearData' });
      dispatch({ type: 'workingTeam/clearData' });
    };
  }, []);

  useEffect(() => {
    console.log('attributeTags: ', attributeTags);
    const treeData = [];
    let others = {};
    attributeTags.forEach((item) => {
      let parent = item.categories?.edges?.[0]?.node;
      const parentPK = item.categories?.edges?.[0]?.node?.pk || -1;
      if (!parent) {
        parent = {
          pk: -1,
          title: (
            <LabelWithToolTips
              title={'Other attribute tags'}
              className="list-tree-title"
            />
          ),
          name: 'Other attribute tags',
        };

        if (others.title) {
          others.children.push({
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          });
        } else {
          others = {
            ...parent,
            key: `group-${parentPK}`,
            children: [
              {
                ...item,
                title: (
                  <LabelWithToolTips
                    title={item.title}
                    className="list-tree-sub-title"
                  />
                ),
                key: item.pk,
              },
            ],
          };
        }
      } else {
        parent.title = (
          <LabelWithToolTips
            title={`[ID:${parent.pk}] ${parent.name}`}
            className="list-tree-title"
          />
        );

        const exsitGroup = treeData.find((group) => group.pk == parentPK);
        if (exsitGroup) {
          exsitGroup.children.push({
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          });
          return;
        }

        treeData.push({
          ...parent,
          key: `group-${parentPK}`,
          children: [
            {
              ...item,
              title: (
                <LabelWithToolTips
                  title={item.title}
                  className="list-tree-sub-title"
                />
              ),
              key: item.pk,
            },
          ],
        });
      }
    });
    setTagsData([others].concat(treeData));
    setSourceTagsData([others].concat(treeData));
  }, [attributeTags]);

  const content = [
    {
      title: 'Starred campaign',
      data: [
        { name: 'Yes', pk: 'true' },
        { name: 'No', pk: 'false' },
      ],
      value: isStarredCampaign,
      setValue: setIsStarredCampaign,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Type',
      data: [
        { pk: CampaignType.couponsCampaign, name: 'Coupon' },
        { pk: CampaignType.generalMessageCampaign, name: 'General Message' },
        { pk: CampaignType.cashVoucherCampaign, name: 'Cash Voucher' },
        { pk: CampaignType.giftCampaign, name: 'Goodie Bag Coupon' },
        {
          pk: CampaignType.fortuneBagForGoodieBagCampaign,
          name: 'Goodie bag offer (Direct assign)',
        },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    // {
    //   title: 'Message channel',
    //   data: [
    //     { name: 'Inbox', pk: 'INBOX' },
    //     { name: 'Push', pk: 'PUSH_NOTIFICATION' },
    //   ],
    //   value: messageChannel,
    //   setValue: setMessageChannel,
    //   componment: FilterTypeEnum.choice,
    //   className: 'fit-content-height-area',
    // },
    {
      title: 'Redirection campaign',
      data: [
        { name: 'Yes', pk: 'true' },
        { name: 'No', pk: 'false' },
      ],
      value: isRedirect,
      setValue: setIsRedirect,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Working team',
      data: !!searchWorkingTeamData
        ? searchWorkingTeamData
        : [{ pk: 'null', name: 'All admin users' }, ...workingTeamList],
      value: workingTeam,
      setValue: setWorkingTeam,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      renderDisplayName: (item) =>
        `${item.pk === 'null' ? '' : `[ID:${item.pk}] `}${item.name}`,
      filterAction: (searchKey) => {
        const newData = [
          { pk: 'null', name: 'All admin users' },
          ...workingTeamList,
        ]?.filter((item) => {
          const name = `${item.pk} ${item.name?.toLowerCase() || ''}`; //item.name?.toLowerCase() || '';
          return name.includes(searchKey?.toLowerCase() || '');
        });
        setSearchWorkingTeamData(newData);
      },
    },
    // {
    //   title: 'Fortune bag group',
    //   data: campaignGroupData ? campaignGroupData : campaignGroupList,
    //   value: campaignGroup,
    //   setValue: setCampaignGroup,
    //   placeholder: 'Search by ID, Name',
    //   componment: FilterTypeEnum.searchableChoice,
    //   renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    //   filterAction: (searchKey) => {
    //     const newData = campaignGroupList?.filter((item) => {
    //       const name = item.name?.toLowerCase() || '';
    //       return (
    //         name.includes(searchKey?.toLowerCase() || '') ||
    //         String(item.pk) === searchKey
    //       );
    //     });
    //     setCampaignGroupData(newData);
    //   },
    // },
    {
      title: 'Brand',
      data: !!searchBrandData ? searchBrandData : brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = brandList?.filter((item) => {
          const name = `${item.pk} ${item.name?.toLowerCase() || ''}`; //item.name?.toLowerCase() || '';
          return name.includes(searchKey?.toLowerCase() || '');
        });
        setSearchBrandData(newData);
      },
    },
    {
      title: 'Available Coupon',
      data: [
        { name: 'Yes', pk: 'true' },
        { name: 'No', pk: 'false' },
      ],
      value: hasAvailableCoupon,
      setValue: setHasAvailableCoupon,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'coupon',
      displayTitle: 'Coupon Used/Acquired/Generated (eWallet|Physical)',
      data: [
        {
          subComponment: {
            title: 'Used: ',
            componment: FilterTypeEnum.singleChoice,
            data: [
              { name: 'Yes', pk: 'true' },
              { name: 'No', pk: 'false' },
            ],
            value: hasUsedCoupon,
            setValue: setHasUsedCoupon,
            inGroup: true,
            className: 'margin-left-70',
          },
        },
        {
          subComponment: {
            title: 'Acquired: ',
            componment: FilterTypeEnum.singleChoice,
            data: [
              { name: 'Yes', pk: 'true' },
              { name: 'No', pk: 'false' },
            ],
            value: hasAcquiredCoupon,
            setValue: setHasAcquiredCoupon,
            inGroup: true,
            className: 'margin-left-70',
          },
        },
        {
          subComponment: {
            displayTitle: 'Generated (eWallet|Physical)',
            title: 'Generated: ',
            componment: FilterTypeEnum.singleChoice,
            data: [
              { name: 'Yes', pk: 'true' },
              { name: 'No', pk: 'false' },
            ],
            value: hasGeneratedCoupon,
            setValue: setHasGeneratedCoupon,
            inGroup: true,
            className: 'margin-left-70',
          },
        },
      ],
      componment: FilterTypeEnum.groupSingleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'For Goodie bag',
      data: !!goodieData ? goodieData : goodieBags,
      value: goodie,
      setValue: setGoodie,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = goodieBags?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setGoodieData(newData);
      },
    },
    {
      title: 'tags',
      displayTitle: 'Attribute tag (grouped by campaign cat.)',
      data: tagsData,
      value: tags,
      setValue: setTags,
      componment: FilterTypeEnum.searchable2LevelChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = sourceTagsData
          ?.filter((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            return (
              name.includes(searchKey?.toLowerCase() || '') ||
              // item.pk === searchKey ||
              item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ).length > 0
            );
          })
          ?.map((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            const categoryMatched = name.includes(
              searchKey?.toLowerCase() || '',
            );

            if (categoryMatched) {
              return item;
            }

            return {
              ...item,
              children: item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ),
            };
          });
        setTagsData(newData);
      },
    },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    // {
    //   title: 'Target customer',
    //   data: [
    //     {
    //       name: 'Customer group',
    //       pk: 'groupsIn',
    //       subComponment: {
    //         data: customerGroupList,
    //         value: groups,
    //         setValue: setGroups,
    //         componment: FilterTypeEnum.choice,
    //         className: 'filter-list-items-sub-area',
    //       },
    //     },
    //     {
    //       name: 'Segment',
    //       pk: 'segmentsIn',
    //       subComponment: {
    //         data: segmentList,
    //         value: segments,
    //         setValue: setSegments,
    //         componment: FilterTypeEnum.choice,
    //         className: 'filter-list-items-sub-area',
    //       },
    //     },
    //     // {
    //     //   name: 'Level',
    //     //   pk: 'levelsIn',
    //     //   subComponment: {
    //     //     data: levelList,
    //     //     value: levels,
    //     //     setValue: setLevels,
    //     //     componment: FilterTypeEnum.choice,
    //     //     className: 'filter-list-items-sub-area',
    //     //   },
    //     // },
    //   ],
    //   value: targetCustomer,
    //   setValue: setTargetCustomer,
    //   componment: FilterTypeEnum.singleChoice,
    //   className: 'filter-list-items-has-sub-area-height',
    // },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible period',
      data: [
        { name: 'Inbox', pk: 'inbox' },
        { name: 'Push', pk: 'push' },
      ],
      value: visablePeriod,
      setValue: setVisablePeriod,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  // const confirmAction = () => {
  //   let search = {};
  //   if (targetCustomer) {
  //     search['target_customer'] = targetCustomer;
  //     if (selectedTarget) {
  //       search['selected_target'] = selectedTarget.join(',');
  //     }
  //   }

  //   backAction();

  //   return search;
  // };

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
