import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import RecordTableCard from '../../../components/customer/RecordTableCard';
import RecordTimelineCard from '../../../components/customer/RecordTimelineCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CustomerOverviewDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params.id;

  const {
    customer,
    activityLogs,
    couponTransactions,
    transactions,
    avaliableCampaigns,
    coupons,
  } = useSelector((state) => ({
    customer: state.customerList.customer,
    activityLogs: state.customerList.activityLogs,
    couponTransactions: state.couponTransactions.couponTransactionList || [],
    transactions: state.transactions.currentPageTransactionList || [],
    avaliableCampaigns: state.campaignList.pagedList,
    coupons: state.singleCoupon.pagedList,
  }));

  const tabs = [
    {
      name: 'Information',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={'Membership Information'}
                data={customer}
                fields={[
                  [{ title: 'Membership ID', field: 'membershipId' }],
                  [{ title: 'Join Channel', field: 'signUpChannel' }],
                  // [
                  //   { title: 'Current Level', field: 'level' },
                  //   { title: 'Level renewal date', field: 'levelRenewalDate' },
                  //   {
                  //     title: 'Level expired in(days)',
                  //     field: 'levelExpiredIndays',
                  //   },
                  // ],
                  // [{ title: 'Segment', field: 'segment' }],
                  // [{ title: 'Customer group', field: 'group' }],
                  [{ title: 'Referral source', field: 'referralSource' }],
                  [{ title: 'Create at', field: 'formateCreated' }],
                  [
                    {
                      title: 'Interest preference',
                      field: 'displayInterestPreferences',
                    },
                  ],
                  [{ title: 'Customer Lifetime Value', field: 'lifetime' }],
                  // [{ title: 'Average Order Value', field: 'averageOrder' }],
                  // [{ title: 'Total number of orders', field: 'totalOrders' }],
                  // [{ title: 'Days from last order', field: 'lastOrderDays' }],
                  [
                    {
                      title: 'Direct Marketing Preference',
                      field: 'optOutFromDirectMarketing',
                    },
                    // { title: 'Legal agreement', field: 'legalAgreement' },
                    {
                      // title: 'Data processing agreement',
                      title:
                        'Agree to terms & conditions and privacy policy agreement',
                      field: 'dataProcessingAgreement',
                    },
                  ],
                  [
                    {
                      // title: 'Data processing agreement',
                      title:
                        'Transfer personal information to third parties TnC',
                      field: 'transferPersonlaInfo',
                    },
                  ],
                  // [
                  //   { title: 'Points balance', field: 'pointsBalance' },
                  //   {
                  //     title: 'Total lifetime points used',
                  //     field: 'totalLifetimePointsUsed',
                  //   },
                  //   {
                  //     title: 'Total lifetime points expired',
                  //     field: 'totalLifetimePointsExpired',
                  //   },
                  // ],
                  // [{ title: 'TPE', field: 'tpe' }],
                  // [{ title: 'Level Privilege', field: 'levelPrivilege' }],
                  [{ title: 'Active Status', field: 'status' }],
                  [{ title: 'Available campaign', field: 'availableCampaign' }],
                  [{ title: 'Test User', field: 'isPreviewTesterDisplay' }],
                  [{ title: 'Coupons', field: 'coupons' }],
                  [
                    {
                      title: 'Preferred message language',
                      field: 'preferredMessageLanguageDisplay',
                    },
                  ],
                ]}
                extraDataFields={{
                  availableCampaign: avaliableCampaigns.map(
                    (item) => item.name,
                  ),
                  coupons: coupons?.map((item) => item.template?.name),
                }}
              />,
              // <MembershipInfoCard
              //   title={'Personal Information'}
              //   data={customer}
              //   fields={[
              // [{ title: 'First name', field: 'firstName' }],
              // [{ title: 'Last name', field: 'lastName' }],
              // [{ title: 'Chinese First name', field: 'chineseFirstName' }],
              // [{ title: 'Chinese Last name', field: 'chineseLastName' }],
              // [{ title: 'Preferred name (optional)', field: 'nickname' }],
              // [{ title: 'Gender (optional)', field: 'displayGender' }],
              // [{ title: 'Birth date', field: 'displayDateOfBirth' }],
              // [
              //   {
              //     title: 'Preferred message language',
              //     field: 'preferredMessageLanguageDisplay',
              //   },
              // ],
              // [{ title: 'Assign to test customer', field: 'assignToTest' }],
              //   ]}
              // />,
              // <MembershipInfoCard
              //   title={'Contact Information'}
              //   data={customer}
              //   fields={[
              //     // [{ title: 'Mobile number', field: 'mobileNumber' }],
              //     // [{ title: 'Email', field: 'email' }],
              //     [
              //       {
              //         title: 'Social media (optional)',
              //         field: 'socialMedia',
              //       },
              //     ],
              //     [{ title: 'Address (optional)', field: 'address' }],
              //   ]}
              // />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
    {
      name: 'Record',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <RecordTableCard
                title={'Transaction'}
                data={transactions?.slice(0, 10)}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: 'Event create date', name: 'createdDate' },
                  {
                    displayName: 'Transaction type',
                    name: 'transactionDisplayType',
                  },
                  { displayName: 'Event type', name: 'eventType' },
                  { displayName: 'BrandName', name: 'brand' },
                  { displayName: 'Store name', name: 'displayStoreName' },
                  {
                    displayName: 'Transaction value($)',
                    name: 'transactionValue',
                  },
                ]}
                viewAllLink={`/transactions?search=${
                  customer.nickname ? customer.nickname : customer.owner
                }`}
                buttonRequires={PermissionCodes.viewTransaction}
              />,
              <RecordTableCard
                title={'Coupons'}
                data={couponTransactions.slice(0, 10)}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: 'Event create date', name: 'createdDate' },
                  { displayName: 'Transaction type', name: 'displayType' },
                  { displayName: 'Event type', name: 'eventType' },
                  { displayName: 'Expired time', name: 'couponExpiryDate' },
                ]}
                viewAllLink={`/coupon_records?search=${customer.membershipId}`}
                tableWidth={694}
                buttonRequires={PermissionCodes.viewCouponTransaction}
              />,
              <RecordTimelineCard
                title={'points and level'}
                data={activityLogs}
              />,
            ]}
            hidePreview={true}
            sectionStyle="detail-record-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCustomer}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
    return () => {
      dispatch({ type: 'customerList/clearData' });
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(createAction('customerList/getOneCustomer')({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (!customer || !customer?.pk) {
      return;
    }
    dispatch(
      createAction('campaignList/getCampaignList')({
        ssoUid: customer.ssoUid,
        isAll: true,
        getAll: 'true',
      }),
    );
    dispatch(
      createAction('singleCoupon/getList')({
        ssoUid: customer.ssoUid,
        isAll: true,
        needTemplate: true,
        isExpired: 'false',
        isUsed: 'false',
        orderBy: '-pk',
        pageSize: 100,
        // getAllTemplate: true,
      }),
    );
    dispatch(
      createAction('customerList/getCustomerActivityLog')({
        ssoUid: customer.ssoUid,
      }),
    );
    dispatch(
      createAction('couponTransactions/getCouponTransactions')({
        searchKey: customer.membershipId,
      }),
    );
    dispatch(
      createAction('transactions/getCurrentPageTransactions')({
        searchKey: customer.membershipId,
        rank: true,
      }),
    );
  }, [dispatch, customer]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: customer.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

export default CustomerOverviewDetail;
