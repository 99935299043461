import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import PromptButton, { BUTTON_TYPES } from '../base/prompt/BaseButtons';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import { CustomNumberInputWithUnit } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import './GoodieBagsAllocationPrompt.scss';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export default function GoodieBagsAllocationPrompt(props) {
  const closeAction = props.closeAction || (() => {});
  const customClass = props.customClass || '';
  const nextAction = props.nextAction || (() => {});

  const goodieBag = props.goodieBag;
  const overallAmount = goodieBag?.overallAmount;

  const [canAllocatedNum, setCanAllocatedNum] = useState(overallAmount);

  const [show, setShow] = useState(props.show);

  const [otaAllocatedNum, setOTAAllocatedNum] = useState({});

  useEffect(() => {
    if (overallAmount) {
      setCanAllocatedNum(overallAmount);
    }
  }, [overallAmount]);

  const handleClose = () => {
    setShow(false);
    setOTAAllocatedNum({});
    setCanAllocatedNum(overallAmount);
    closeAction();
  };

  if (!show && props.show) {
    setShow(true);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const checkEmpty = (value) => {
    return value === undefined || value === null || value === '';
  };

  const getAllocatedNumber = (value) => {
    if (checkEmpty(value)) {
      return '';
    }
    const intNumber = parseInt(value);
    if (intNumber < 0) {
      return 0;
    }
    return intNumber;
  };

  const body = () => {
    return (
      <div className="goodie-bag-allocation-for-option-c">
        <div className="caution-area fortune-bags-caution-area">
          <label className="caution-text">
            Goodie bags allocation for Option C
          </label>
        </div>

        <label className="bag-num-title-normal">
          No. of goodie bags can be allocated:
          <label
            className={`bag-num ${canAllocatedNum < 0 ? 'bag-num-error' : ''}`}
          >
            {canAllocatedNum < 0 ? 0 : canAllocatedNum}
          </label>
        </label>
        <ErrorFieldMessage
          id={'canAllocatedNumError'}
          error={canAllocatedNum < 0}
          message={
            'The total input exceeds the number of goodie bags can be allocated, please adjust the input value,'
          }
        />

        <label className="bag-num-title">
          Allocate the number of links for option C’s merchants
        </label>

        <div className="base-prompt-other-body fortune-bags-allocation-other-body">
          {props?.ota
            ?.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            })
            .map((item, index) => (
              <CustomNumberInputWithUnit
                title={item.name}
                setValue={(value) => {
                  const allocatedInput = getAllocatedNumber(value);
                  setOTAAllocatedNum({
                    ...otaAllocatedNum,
                    [item.pk]: allocatedInput,
                  });
                  const others = Object.keys(otaAllocatedNum)
                    .filter((key) => key !== item.pk.toString())
                    ?.map((key) => otaAllocatedNum[key] || 0);

                  setCanAllocatedNum(
                    overallAmount -
                      (others.length > 0 ? others.reduce(reducer) : 0) -
                      allocatedInput || 0,
                  );
                }}
                defaultValue={''}
                others={{ value: otaAllocatedNum?.[item.pk] }}
                unit={'number of goodie bag(s)'}
                errorId={item.name}
                error={otaAllocatedNum?.[item.pk] < 0 || canAllocatedNum < 0}
                errorMessage={
                  otaAllocatedNum?.[item.pk] < 0
                    ? 'Please input valid number'
                    : ''
                }
              />
            ))}
        </div>
        <div className="base-prompt-buttons">
          <PromptButton
            title="Allocate"
            className="fortune-bags-allocation-button"
            action={() =>
              nextAction(
                props?.ota?.map((item) => ({
                  brandId: item.pk,
                  number: otaAllocatedNum[item.pk] || 0,
                })),
              )
            }
            type={BUTTON_TYPES.main}
            disabled={
              canAllocatedNum !== 0 ||
              Object.values(otaAllocatedNum).filter((num) => num < 0).length > 0
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`base-prompt-container ${customClass}`}
        style={{ width: '800px' }}
      >
        <Image
          src={CloseIcon}
          className="base-prompt-close-button fortune-bags-close-button"
          onClick={handleClose}
        />
        {body()}
      </Modal.Body>
    </Modal>
  );
}
