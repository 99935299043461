export const GoodieBagRewardType = {
  allTypes: 'all',
  cashVoucher: 'CASH_VOUCHER',
  gift: 'GIFT',
  both: 'BOTH',
  fortuneBag: 'FORTUNE_BAG',
};

export const CampaignType = {
  allTypes: 'all',
  generalMessageCampaign: 'GENERAL_MESSAGE_CAMPAIGN',
  earningRulesCampaign: 'EARNING_CAMPAIGN',
  couponsCampaign: 'COUPON_CAMPAIGN',
  fortuneBagCampaign: 'FORTUNE_BAG_CAMPAIGN',
  cashVoucherCampaign: 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG',
  giftCampaign: 'GIFT_CAMPAIGN_FOR_GOODIE_BAG',
  fortuneBagForGoodieBagCampaign: 'FORTUNE_CAMPAIGN_FOR_GOODIE_BAG',
};

export const COUPON_SET_TYPES = {
  SYSTEM_GENERATED: 'SYSTEM_GENERATED',
  IMPORT_EXISTING_COUPON: 'IMPORT_EXISTING_COUPON',
};
export const COUPON_REDEMPTION_METHOD = {
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
};

export const COUPON_FORMATS = {
  COUPON_QR_CODE: 'COUPON_QR_CODE',
  COUPON_BAR_CODE: 'COUPON_BAR_CODE',
};

export const COUPON_CODE_TYPE = {
  GENERIC: 'GENERIC',
  UNIQUE: 'UNIQUE',
};

export const COUPON_LOW_STOCK = {
  SPECIFIC_VALUE: 'SPECIFIC_VALUE',
  SPECIFIC_PERCENTAGE: 'SPECIFIC_PERCENTAGE',
};

export const StepStatus = {
  error: 'error',
  processing: 'processing',
  finished: 'finished',
  waiting: 'waiting',
};

export const LanguageConfig = {
  english: 'en',
  simplifiedChinese: 'zh-Hans',
  traditionalChinese: 'zh-Hant',
};

export const LanguageUpperConfig = {
  english: 'EN',
  simplifiedChinese: 'ZH_HANS',
  traditionalChinese: 'ZH_HANT',
};

export const EarningRuleType = {
  allTypes: 'all',
  generalPurchase: 'GENERAL_PURCHASE',
  memberReferral: 'MEMBER_REFERRAL',
  birthday: 'BIRTHDAY',
  qrCodeScanning: 'QR_CODE_SCANNING',
  gpsCheckIn: 'GPS_CHECK_IN',
  newMember: 'NEW_MEMBER',
};

export const EarningRuleTypeKey = {
  All: 'allTypes',
  GENERAL_PURCHASE: 'generalPurchase',
  MEMBER_REFERRAL: 'memberReferral',
  BIRTHDAY: 'birthday',
  QR_CODE_SCANNING: 'qrCodeScanning',
  GPS_CHECK_IN: 'gpsCheckIn',
  NEW_MEMBER: 'newMember',
};

export const TermsAndConditionsType = {
  merchant: 'FOR_MERCHANT',
  generic: 'FOR_GENERIC',
};

export const PaymentSettlementType = {
  byMonthly: 'MONTHLY',
  byMerchant: 'MERCHANT',
};

export const DisplayPaymentSettlementReviewStatusType = {
  waiting: 'Waiting for review',
  disagree: 'Merchant disagreed',
  agree: 'Merchant agreed',
};

export const PaymentAndSettlementReviewStatusType = {
  waiting: 'WAITING_FOR_REVIEW',
  disagree: 'MERCHANT_DISAGREED',
  agree: 'MERCHANT_AGREED',
};

export const LanguageTabTransLation = {
  english: 'English',
  simplifiedChinese: '简体',
  traditionalChinese: '繁体',
};

export const LanguageTransLation = {
  en: 'English',
  'zh-Hans': '简体',
  'zh-Hant': '繁体',
};

export const AmOrPm = {
  am: 'am',
  pm: 'pm',
};

export const SavingStatus = {
  onSaving: 'onSaving',
  onFinished: 'onFinished',
};

export const APIStatus = {
  success: 'success',
  calling: 'calling',
  failed: 'failed',
  none: 'none',
};

export const MessageChannel = {
  push: 'Push',
  email: 'Email',
  sms: 'SMS',
  web: 'Web',
  inbox: 'Inbox',
};

export const CheckStatus = {
  initOrNotChecked: -1,
  checkedWithFail: 0,
  checkedWithSuccess: 1,
};

export const SavedStatus = {
  init: -1,
  savedWithFail: 0,
  savedWithSuccess: 1,
  saving: 2,
};

export const EarningRuleRewardType = {
  coupons: 'COUPON',
  points: 'POINTS',
};

export const StatusTag = {
  active: 'Active',
  activeRequired: 'Active(Acquired)',
  deactive: 'Deactive',
  inactive: 'Inactive',
  acquired: 'Acquired',
  linkedFortuneBag: 'Linked to Goodie bag offer (Direct assign)',
  linkedGoodieBag: 'Linked to Goodie Bag',
};

export const YES_OR_NO_TAG = {
  yes: 'Yes',
  no: 'No',
};

export const PublishTagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
};

export const DownloadTag = {
  completed: 'Exported',
  cancelled: 'Cancelled',
  downloading: 'Exporting',
  importing: 'Importing',
  importCompleted: 'Imported',
  updated: 'Updated',
  error: 'Error',
  generating: 'Generating',
  generated: 'Generated',
  pendingForImport: 'Pending',
  pendingForExport: 'Pending',
  pendingForGenerate: 'Pending',
  scheduledImport: 'Scheduled import',
};

export const MessageTag = {
  scheduled: 'Scheduled',
  saved: 'Saved',
  sent: 'Sent',
  sending: 'Sending',
  error: 'Failed',
  expired: 'Expired',
};

export const ConditionTag = {
  EQUAL_TO: 'Equal to',
  NOT_EQUAL_TO: 'Not equal to',
  IS_EMPTY: 'Is empty',
  IS_NOT_EMPTY: 'Is not empty',
  BETWEEN: 'Between',
  LESS_THAN: 'Less than',
  GREATER_THAN: 'greater than',
  IN: 'In',
};

export const DaysTag = {
  '1_DAY': '1 day',
  '7_DAYS': '7 days',
  '1_MONTH': '1 month',
  '3_MONTHS': '3 months',
  '6_MONTHS': '6 months',
  '1_YEAR': '1 year',
};

const domain =
  process.env.REACT_APP_IMAGE || 'https://nginx.aillia.hkdev.motherapp.com';

export const ImageDomain = `${domain}/media/`;

export const GenderOptions = [
  { name: 'Male', value: 'MALE' },
  { name: 'Female', value: 'FEMALE' },
  { name: 'Prefer not to say', value: 'NOT_DISCLOSED' },
];

export const MonthOptions = [
  { name: 'January', value: '1' },
  { name: 'February', value: '2' },
  { name: 'March', value: '3' },
  { name: 'April', value: '4' },
  { name: 'May', value: '5' },
  { name: 'June', value: '6' },
  { name: 'July', value: '7' },
  { name: 'August', value: '8' },
  { name: 'September', value: '9' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export const CountryCodeOptions = [
  { name: '+852', value: 852 },
  { name: '+853', value: 853 },
  { name: '+86', value: 86 },
];

export const BANNER_CONTENT_TYPE = {
  ALL_TYPE: 'all',
  CAMPAIGN: 'CAMPAIGN',
  PRODUCT: 'PRODUCT',
};

export const POINT_RECORD_TYPES = [
  { key: 'TYPE_POINT_ADD', name: 'Add Points' },
  { key: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
];

export const COUPON_TEMPLATE_TYPE = {
  storeCode: 'STORE_CODE',
  couponCode: 'COUPON_CODE',
  couponQRCode: 'COUPON_QR_CODE',
  couponCodeImage: 'COUPON_CODE_IMAGE',
};

export const COUPON_TEMPLATE_TYPE_NAME = {
  STORE_CODE: 'Store code',
  COUPON_CODE: 'Coupon code',
  COUPON_QR_CODE: 'Coupon QR code',
  COUPON_CODE_IMAGE: 'Coupon code image',
};

export const DELETE_RELATED_SECTIONS = {
  CUSTOMER: [
    'AppInstallation',
    'CampaignParticipationRecord',
    'Coupon',
    'CouponTransaction',
    'PointTransaction',
    'Transaction',
  ],
  SEGMENT: ['Campaign'],
  CUSTOMER_GROUP: ['Campaign', 'ParticipationCustomer'],
  CAMPAIGN: [
    'Message',
    'CampaignParticipationRecord',
    'CouponTransaction',
    'PointsTransaction',
    'Segment Campaign Characteristic',
    'Banner',
  ],
  CAMPAIGN_CATEGORY: ['Campaign'],
  MESSAGE: ['InboxMessage'],
  EARNING_RULE: ['Campaign'],
  COUPON_TEMPLATE: ['Campaign', 'Coupon', 'EarningRule'],
  COUPON: ['CouponTransaction'],
  BRAND: ['Campaign', 'CouponTemplate', 'Store'],
  STORE: ['CampaignParticipationRecord', 'Coupon', 'Transaction'],
  STORE_CATEGORY: ['StoreSubcategory'],
  TRANSACTION: [
    'CampaignParticipationRecord',
    'PurchasedItem',
    'Coupon transaction',
    'Point transaction',
  ],
  POINT_RECORD: [],
  COUPON_RECORD: [],
  ADMIN_GROUP: ['ParticipationAdmin'],
  ADMIN: ['CouponTransaction', 'ImportJob', 'ExportJob'],
  BANNER: [],
  GENERAL_ALERT: ['General Alert'],
};

export const ADMIN_TYPE = {
  ALL_TYPE: 'all',
  HKTB_ADMIN: 'HKTB_ADMIN',
  MERCHANT_ADMIN: 'MERCHANT_ADMIN',
  MERCHANT_APP_ACCOUNT: 'MERCHANT_APP_ACCOUNT',
};

export const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CUSTOMER_ID_TYPE = {
  EMAIL: 'Email Address',
  MOBILE_NUMBER: 'Mobile No.',
};
export const SESSION_KEYS = {
  ADMIN_GROUP_SESSION_KEY: 'tempAdminGroup',
  ADMIN_SESSION_KEY: 'tempAdmin',
  ATTRIBUTE_TAG_KEY_SESSION_KEY: 'attributeTagKey',
  ATTRIBUTE_TAG_KEY_DATA_SESSION_KEY: 'attributeTagKeys',
  ATTRIBUTE_TAG_SESSION_KEY: 'attributeTag',
  ATTRIBUTE_TAG_DATA_SESSION_KEY: 'attributeTags',
  CREATE_BRAND_SESSION_KEY: 'createBrand',
  CREATE_BRAND_END_SESSION_KEY: 'createBrandStepEnd',
  CREATE_BRAND_ORIGIN_DATA_SESSION_KEY: 'origionalData',
  CREATE_CAMPAIGN_SESSION_KEY: 'createCampaign',
  CREATE_CAMPAIGN_PERVIEW_SESSION_KEY: 'createCampaignPreviewData',
  CREATE_FORTUNE_COUPONS: 'fortuneBagCoupons',
  CREATE_GOODIE_BAG: 'createGoodieBag',
  GOODIE_BAG_GROUP_SESSION_KEY: 'goodieBagGroup',
  CREATE_CAMPAIGN_END_SESSION_KEY: 'createCampaignStepEnd',
  CREATE_CAMPAIGN_ORIGIN_DATA_SESSION_KEY: 'createCampaignOriginalData',
  CREATE_COUPON_SESSION_KEY: 'createCoupon',
  CREATE_COUPON_END_SESSION_KEY: 'createCouponStepEnd',
  CREATE_COUPON_ORIGIN_DATA_SESSION_KEY: 'createCouponOriginalData',
  CAMPAIGN_LIST_SESSION_KEY: 'campaigns',
  CHANNEL_SESSION_KEY: 'channel',
  CHANNEL_DATA_SESSION_KEY: 'channels',
  CAMPAIGN_CATEGORY_SESSION_KEY: 'campaignCategoryKey',
  EARNING_RULE_SESSION_KEY: 'tempEarningRule',
  CREATE_MESSAGE_SESSION_KEY: 'createMessage',
  CREATE_MESSAGE_END_SESSION_KEY: 'createMessageStepEnd',
  CREATE_MESSAGE_ORIGIN_DATA_SESSION_KEY: 'createMessageOriginalData',
  CREATE_POINT_SESSION_KEY: 'createPoint',
  CREATE_POINT_END_SESSION_KEY: 'createPointStepEnd',
  CREATE_POINT_ORIGIN_DATA_SESSION_KEY: 'createPointOriginalData',
  STORE_CATEGORY_SESSION_KEY: 'storeCategoryKey',
  CREATE_TRANSACTION_SESSION_KEY: 'createTransaction',
  CREATE_TRANSACTION_END_SESSION_KEY: 'createTransactionStepEnd',
  CREATE_TRANSACTION_ORIGIN_DATA_SESSION_KEY: 'createTransactionOriginalData',
  CREATE_CUSTOMER_GROUP_SESSION_KEY: 'createCustomerGroup',
  CREATE_CUSTOMER_GROUP_END_SESSION_KEY: 'createCustomerGroupStepEnd',
  CREATE_CUSTOMER_GROUP_ORIGIN_DATA_SESSION_KEY:
    'createCustomerGroupOriginalData',
  TEMP_CUSTOMER_SESSION_KEY: 'tempCustomer',
  ENTITLEMENT_CUSTOMER_SESSION_KEY: 'entitlementCustomer',
  ENTITLEMENT_CUSTOMERS_SESSION_KEY: 'entitlementCustomers',
  CREATE_GENERAL_ALERT_SESSION_KEY: 'createGeneralAlert',
  CREATE_GENERAL_ALERT_END_SESSION_KEY: 'createGeneralAlertStepEnd',
  CREATE_GENERAL_ALERT_ORIGIN_DATA_SESSION_KEY:
    'createGeneralAlertOriginalData',
  BANNER_SESSION_KEY: 'bannerKey',
  INTEREST_PREFERENCE_SESSION_KEY: 'createInterestPreference',
  LANGUAGE_SESSION_KEY: 'language',
  LANGUAGE_DATA_SESSION_KEY: 'languages',
  LOGO_LABEL_SESSION_KEY: 'createLogoLabel',
  OFFER_TYPE_SESSION_KEY: 'offerTypeLabel',
  OFFER_TYPE_DATA_SESSION_KEY: 'offerTypeLabels',
  SEGMENT_SESSION_KEY: 'tempSegment',
  STORE_SESSION_KEY: 'tempStore',
  SURVEY_FORM_SESSION_KEY: 'surveyForms',

  TARGET_MARKET_SESSION_KEY: 'targetMarket',
  TARGET_MARKET_DATA_SESSION_KEY: 'targetMarkets',
  TOUR_CARD_SESSION_KEY: 'createTourCard',
  TERMS_AND_CONDITIONS_SESSION_KEY: 'createTermsAndConditions',

  OTP_CHANNEL_DATA_SESSION_KEY: 'otpChannels',
  OTP_CHANNEL_SESSION_KEY: 'otpChannels',

  WORKING_TEAM_DATA_SESSION_KEY: 'workingTeams',
  WORKING_TEAM_SESSION_KEY: 'workingTeams',
};

export const STORE_LOADING_TYPE = {
  BRAND: 'brands',
  CAMPAIGN: 'campaignIn',
  COUPON: 'couponTemplateIn',
};

export const TokenErrors = {
  Expiry: 'Signature has expired',
  DecodingError: 'Error decoding signature',
  RefreshTokenInvalid: 'Invalid refresh token',
  RefreshTokenExpired: 'Refresh token is expired',
  NoPermission: 'You do not have permission to perform this action',
};

export const AdminLoginMethod = {
  All: 'ALL',
  Saml: 'SAML',
};

export const UsedPlatform = {
  CustomerApp: { key: 'CUSTOMER_APP', value: 'Used in Customer App' },
  MerchantApp: { key: 'MERCHANT_APP', value: 'Used by Merchant App' },
  CMS: { key: 'CMS', value: 'Used by CMS' },
  MerchantAppPhysical: {
    key: 'MERCHANT_APP_PHYSICAL',
    value: 'Used by Merchant app (physical coupon)',
  },
};

export const CouponMediumType = {
  Both: 'BOTH',
  EWallet: 'EWALLET',
  Physical: 'PHYSICAL',
};

export const DisplayCouponMediumType = {
  BOTH: 'Both eWallet and Physical',
  EWALLET: 'eWallet only',
  PHYSICAL: 'Physical only',
};

export const CouponBatchExportType = {
  Image: 'PHYSICAL_COUPON_QRCODE_IMAGE',
  Template: 'PHYSICAL_COUPON_QRCODE_IMAGE_AND_TEMPLATE',
  CSV: 'PHYSICAL_COUPON_QRCODE_CSV',
};

export const DisplayAdminType = [
  { label: 'HKTB administrator', value: ADMIN_TYPE.HKTB_ADMIN },
  { label: 'Merchant administrator', value: ADMIN_TYPE.MERCHANT_ADMIN },
  { label: 'Merchant app account', value: ADMIN_TYPE.MERCHANT_APP_ACCOUNT },
];

export const UsedMethodType = {
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
  MERCHANT_SCAN_QR_CODE: 'MERCHANT_SCAN_QR_CODE',
  MERCHANT_INPUT_SRK_CODE: 'MERCHANT_INPUT_SRK_CODE',
  MERCHANT_ADMIN_INPUT_SRK_CODE: 'MERCHANT_ADMIN_INPUT_SRK_CODE',
  HKTB_ADMIN_INPUT_SRK_CODE: 'HKTB_ADMIN_INPUT_SRK_CODE',
};

export const DisplayUseMethod = {
  [UsedMethodType.MERCHANT_APP_SCAN_COUPON_QR_CODE]:
    'Mechant app scan Coupon QR code',
  [UsedMethodType.USERS_SCAN_STORE_QR_CODE]: 'Users scan Store QR code',
  [UsedMethodType.USERS_COPY_THE_DISCOUNT_CODE]:
    'Users reveal the coupon (discount code, URL, QR code/Barcode)',
  [UsedMethodType.MERCHANT_SCAN_QR_CODE]: "Merchant scan user's coupon QR code",
  [UsedMethodType.MERCHANT_INPUT_SRK_CODE]:
    "Merchant input user's coupon SRK code",
  [UsedMethodType.MERCHANT_ADMIN_INPUT_SRK_CODE]:
    "Merchant admin input user's coupon SRK code",
  [UsedMethodType.HKTB_ADMIN_INPUT_SRK_CODE]:
    "HKTB admin input user's coupon SRK code",
};
