import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { createAction } from '../../../utils';
import {
  BannerErrorFields,
  BannerLocation,
  BannerType,
  MPLinkType,
} from '../../../models/HomeBannersModel';
import { LandingPageType } from '../../../models/HomeBannersModel';
import { useFormContext } from 'react-hook-form';
import CreateBannerContent from '../../../components/banners/CreateBannerContent';
import { LanguageConfig } from '../../../config/CustomEnums';

const CreateBannerStepOne = ({ banner, languages }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, trigger, setError, clearErrors, setFocus } =
    useFormContext();

  const getLanguageTab = (language) => {
    return [<CreateBannerContent language={language} />];
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const manualyUrlIsValid = (value) => {
    const validUrlReg =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const result = validUrlReg.test(value);
    return result;
  };

  const save = async (isPublished) => {
    clearErrors();
    let isValid = await trigger([`mobilePhotos.en.0`, 'order'], {
      shouldFocus: true,
    });

    //`coverPhotos.en.0`,
    console.log('@@53: ', getValues());
    const [
      landingPageType,
      manualInputUrl,
      bannerType,
      wechatUrl,
      mpLink,
      linkToMPAppId,
      linkToMPPath,
      publishLocation,
      coverPhotos,
    ] = getValues([
      'landingPageType',
      'manualInputUrl',
      'bannerType',
      'wechatUrl',
      'mpLink',
      'linkToMPAppId',
      'linkToMPPath',
      'publishLocation',
      'coverPhotos',
    ]);
    console.log('@83', isValid, getValues());

    if (
      publishLocation?.includes(BannerLocation.HOME_PAGE) &&
      !coverPhotos?.en?.[0]
    ) {
      isValid = false;
      setError(
        `coverPhotos.en.0`,
        {
          type: 'require',
          message: BannerErrorFields.bannerImage.message,
        },
        { shouldFocus: true },
      );
    }

    const checkUrlIsValid = languages
      ?.filter((item) => item.code === LanguageConfig.english)
      .forEach((item) => {
        if (
          landingPageType === LandingPageType.MANUAL_INPUT_URL.value &&
          publishLocation?.includes(BannerLocation.HOME_PAGE) &&
          !manualyUrlIsValid(manualInputUrl[item.code])
        ) {
          setError(
            `manualInputUrl.${item.code}`,
            {
              type: 'require',
              message: BannerErrorFields.manualyUrl.message,
            },
            { shouldFocus: true },
          );
          isValid = false;
        }

        if (
          landingPageType === LandingPageType.MANUAL_INPUT_URL.value &&
          publishLocation?.includes(BannerLocation.WECHAT)
        ) {
          // if (!mpLink || !mpLink?.[item.code]) {
          //   isValid = false;
          //   setError(
          //     `mpLink.${item.code}`,
          //     {
          //       type: 'require',
          //       message: BannerErrorFields.wechatUrl.message,
          //     },
          //     { shouldFocus: true },
          //   );
          // }
          console.log('@@107: ', mpLink, wechatUrl[item.code]);
          if (
            (!mpLink || mpLink?.[item.code] === MPLinkType.URL) &&
            !manualyUrlIsValid(wechatUrl[item.code])
          ) {
            setError(
              `wechatUrl.${item.code}`,
              {
                type: 'require',
                message: BannerErrorFields.wechatUrl.message,
              },
              { shouldFocus: true },
            );
            isValid = false;
          }

          if (mpLink && mpLink[item.code] === MPLinkType.MP_LINK) {
            if (!linkToMPAppId[item.code]) {
              isValid = false;
              setError(
                `linkToMPAppId.${item.code}`,
                {
                  type: 'require',
                  message: BannerErrorFields.linkToMPAppId.message,
                },
                { shouldFocus: true },
              );
            }

            if (!linkToMPPath[item.code]) {
              isValid = false;
              setError(
                `linkToMPPath.${item.code}`,
                {
                  type: 'require',
                  message: BannerErrorFields.linkToMPPath.message,
                },
                { shouldFocus: true },
              );
            }
          }
        }
      });

    // if (!bannerType) {
    //   isValid = false;
    // }

    console.log('@@78: ', publishLocation, bannerType);
    if (!publishLocation || publishLocation?.length <= 0) {
      console.log('@@97');
      isValid = false;
      setError(
        `publishLocation`,
        {
          type: 'require',
          message: BannerErrorFields.publishLocation.message,
        },
        { shouldFocus: true },
      );
    }

    console.log('@@133: ', isValid);
    if (isValid) {
      dispatch(
        createAction('bannerList/createOrUpdateBanner')({
          data: {
            ...getValues(),
            isPublished: !!isPublished,
          },
          isCreate: params.id ? false : true,
        }),
      );
      return;
    }
  };

  return (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={'Save only'}
        saveText={`Save and ${banner.isPublished ? 'UnPublish' : 'Publish'}`}
        saveTempAction={async () => save(banner.isPublished)}
        saveAction={async () => save(!banner.isPublished)}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  banner: state.bannerList.banner,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateBannerStepOne);
