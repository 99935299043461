import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import CustomRadios from '../../base/CustomRadios';
import { Row } from 'react-bootstrap';
import './CouponDateTimeSelector.scss';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import {
  DISCOUNT_CODE_FORMAT,
  VALID_PERIOD_TYPE,
  displayDiscountCodeFormat,
} from '../../../models/CreateCouponModel';
import {
  COUPON_SET_TYPES,
  COUPON_FORMATS,
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
  COUPON_LOW_STOCK,
  CouponMediumType,
} from '../../../config/CustomEnums';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import SurveyFormSelectDropdown from './SurveyFormSelectDropdown';
import BasePrompt from '../../base/prompt/BasePrompt';
import CouponBarcodeSelectDropdown from './CouponBarcodeSelectDropDown';
import {
  CustomNumberInputWithUnit,
  CustomTitleLabel,
} from '../../earning/CustomBaseComponments';
import { connect } from 'react-redux';
import { checkPartnerCodeFormat } from '../../../models/CouponUtil';

const CreateCouponStepThreeGeneral = ({ couponTemplate }) => {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  const history = useHistory();
  const { id } = useParams();
  const [showPrompt, setShowPrompt] = useState(false);

  const watchCouponSetType = watch('couponSetType');
  const watchFormats = watch('formats');
  const watchCouponBarcode = watch('barcodeFormat');
  const watchCouponCodeType = watch('couponCodeType');
  const watchRequireTransactionAmountSurvey = watch(
    'requireTransactionAmountSurvey',
  );
  const watchTransactionAmountSurvey = watch('transactionAmountSurvey');
  const watchRedemptionMethod = watch('redemptionMethod');

  const watchDiscountCodeFormat =
    watch(`discountCodeFormat`) || DISCOUNT_CODE_FORMAT.CODE;
  const watchShowLandingPage = watch(`showLandingPage`);
  const watchLandingPageButtonName = watch(
    `translations.en.landingPageButtonName`,
  );
  const watchLandingPageUrl = watch(`translations.en.landingPageUrl`);

  const watchShowLowStockLabel = watch('showLowStockLabel');
  const watchLowStockMethod = watch('lowStockMethod');
  const watchLowStockValue = watch('lowStockValue');

  const isPartnerCodeFormat = checkPartnerCodeFormat(watchDiscountCodeFormat);

  return (
    <div className="create-coupon-step-three-container">
      <label className="create-section-title">GENERAL</label>

      {watchRedemptionMethod ===
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ? (
        <div>
          <CustomTitleLabel title={'Coupon code display format'} />
          <div className="second-section-content" key={watchDiscountCodeFormat}>
            {displayDiscountCodeFormat[watchDiscountCodeFormat]}
          </div>
        </div>
      ) : null}

      {watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL ||
      (watchShowLandingPage &&
        watchDiscountCodeFormat != DISCOUNT_CODE_FORMAT.URL &&
        !isPartnerCodeFormat) ? (
        <>
          <CustomTitleLabel title={'Landing page button name'} />
          <div className="second-section-content">
            {watchLandingPageButtonName}
          </div>
        </>
      ) : null}

      {watchShowLandingPage &&
      watchDiscountCodeFormat != DISCOUNT_CODE_FORMAT.URL &&
      !isPartnerCodeFormat ? (
        <>
          <CustomTitleLabel title={'Landing page URL'} />
          <div className="second-section-content">{watchLandingPageUrl}</div>
        </>
      ) : null}

      {watchCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON ? (
        <>
          {watchRedemptionMethod !==
          COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ? (
            <div className="second-section">
              <label className="create-section-label">Code format</label>
              <div style={{ display: 'flex' }}>
                <Controller
                  control={control}
                  name="formats"
                  render={() => (
                    <CustomRadios
                      onChange={(value) => {
                        setValue('formats', value, { shouldDirty: true });
                      }}
                      default={watchFormats}
                      options={[
                        {
                          label: 'Coupon QR code',
                          value: COUPON_FORMATS.COUPON_QR_CODE,
                        },
                        {
                          label: 'Coupon Barcode',
                          value: COUPON_FORMATS.COUPON_BAR_CODE,
                        },
                      ]}
                    />
                  )}
                />
              </div>
              <ReactHookFormErrorMessage errors={errors} id="formats" />
            </div>
          ) : null}

          {(watchRedemptionMethod !==
            COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
            watchFormats === COUPON_FORMATS.COUPON_BAR_CODE) ||
          (watchRedemptionMethod ===
            COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
            watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.BARCODE) ? (
            <>
              <CouponBarcodeSelectDropdown
                title={'Select the Coupon Barcode format'}
                defaultValue={{
                  label: watchCouponBarcode?.name,
                  value: watchCouponBarcode,
                }}
                onSelectChange={(value) => {
                  setValue('barcodeFormat', value, { shouldDirty: true });
                }}
                couponTemplate={couponTemplate}
              />
              <ReactHookFormErrorMessage errors={errors} id="barcodeFormat" />
            </>
          ) : null}

          <div className="second-section">
            <label className="create-section-label">
              {watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                ? 'Generic URL or Unique URL'
                : 'Generic code or Unique code'}
            </label>
            <div style={{ display: 'flex' }}>
              <Controller
                control={control}
                name="couponCodeType"
                render={() => (
                  <CustomRadios
                    onChange={(value) => {
                      setValue('couponCodeType', value, { shouldDirty: true });
                    }}
                    default={watchCouponCodeType}
                    options={[
                      {
                        label: 'Generic',
                        value: COUPON_CODE_TYPE.GENERIC,
                      },
                      {
                        label: 'Unique',
                        value: COUPON_CODE_TYPE.UNIQUE,
                      },
                    ]}
                  />
                )}
              />
            </div>
            <ReactHookFormErrorMessage errors={errors} id="couponCodeType" />
          </div>

          {watchCouponCodeType === COUPON_CODE_TYPE.GENERIC ? (
            <Controller
              control={control}
              name="genericCode"
              render={() => (
                <>
                  <CustomTitleWithInput
                    title={
                      watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                        ? 'Generic URL'
                        : 'Generic code'
                    }
                    defaultValue={watch('genericCode')}
                    error={{ error: hasError(errors, 'genericCode') }}
                    setValue={(value) => {
                      setValue('genericCode', value);
                    }}
                    multipleLines={
                      watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                    }
                    customClass={
                      watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                        ? 'muiltpleLinesInput'
                        : ''
                    }
                  />
                  <ReactHookFormErrorMessage errors={errors} id="genericCode" />
                </>
              )}
            />
          ) : null}
        </>
      ) : null}

      <Controller
        control={control}
        name="showLowStockLabel"
        render={() => (
          <CustomTitleWithSwitch
            title="Show low stock label in front end (Optional)"
            tips={`If the number of coupons is less than the set value, it will display "Low stock" label in Frontend. 
            Counted only based on the number of e-coupon (eWallet).`}
            defaultValue={watchShowLowStockLabel}
            setValue={(value) => {
              setValue('showLowStockLabel', value, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />
      {watchShowLowStockLabel ? (
        <>
          <div style={{ display: 'flex', marginTop: 26 }}>
            <Controller
              control={control}
              name="lowStockMethod"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('lowStockMethod', value, { shouldDirty: true });
                  }}
                  default={watchLowStockMethod}
                  options={[
                    {
                      label: 'Below specific amount',
                      value: COUPON_LOW_STOCK.SPECIFIC_VALUE,
                    },
                    {
                      label: 'Below specific percentage in total',
                      value: COUPON_LOW_STOCK.SPECIFIC_PERCENTAGE,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="lowStockMethod" />
        </>
      ) : null}
      {watchShowLowStockLabel && watchLowStockMethod ? (
        <div style={{ marginTop: 26 }}>
          <Controller
            control={control}
            name="lowStockValue"
            render={() => (
              <CustomNumberInputWithUnit
                onChangeFunc={(value) => {
                  setValue('lowStockValue', value, { shouldDirty: true });
                }}
                defaultValue={watchLowStockValue}
                unit={
                  watchLowStockMethod === COUPON_LOW_STOCK.SPECIFIC_PERCENTAGE
                    ? '%'
                    : ''
                }
                errorId={'lowStockValue'}
                error={hasError(errors, 'lowStockValue')}
                errorMessage={errors?.lowStockValue?.message}
                tips={
                  (watchLowStockMethod === COUPON_LOW_STOCK.SPECIFIC_VALUE &&
                    watch('totalNubmerOfGeneratedCoupons')) ||
                  watch('totalNubmerOfGeneratedCoupons') === 0
                    ? `Total single coupon: ${
                        watch('totalNubmerOfGeneratedCoupons') || 0
                      }`
                    : ''
                }
                others={{
                  step:
                    watchLowStockMethod === COUPON_LOW_STOCK.SPECIFIC_PERCENTAGE
                      ? '0.01'
                      : '1',
                }}
              />
            )}
          />
        </div>
      ) : null}
      <Controller
        control={control}
        name="requireHkidOrPassportNumber"
        render={() => (
          <CustomTitleWithSwitch
            title="Require end-user provides HKID or Passport number"
            defaultValue={watch('requireHkidOrPassportNumber')}
            setValue={(value) => {
              setValue('requireHkidOrPassportNumber', value, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="requireTransactionAmountSurvey"
        render={() => (
          <CustomTitleWithSwitch
            title="Require transaction amount survey"
            defaultValue={watchRequireTransactionAmountSurvey}
            setValue={(value) => {
              setValue('requireTransactionAmountSurvey', value, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />
      {watchRequireTransactionAmountSurvey ? (
        <Controller
          control={control}
          name="transactionAmountSurvey"
          render={() => (
            <>
              <SurveyFormSelectDropdown
                title="Survey form"
                customClass={
                  hasError(errors, 'transactionAmountSurvey')
                    ? 'field-has-error'
                    : ''
                }
                defaultValue={{
                  value: watchTransactionAmountSurvey,
                  label: watchTransactionAmountSurvey?.name,
                }}
                onSelectChange={(item) => {
                  setValue('transactionAmountSurvey', item.value, {
                    shouldDirty: true,
                  });
                }}
                editable
                addButton={{
                  title: 'Create Now',
                  action: () => {
                    setShowPrompt(true);
                  },
                  customClass:
                    'multiple-select-option-add btn-add-button-common',
                }}
                //  onFocus={() => focusChange('relatedBrand')}
              />
              <BasePrompt
                show={showPrompt}
                closeAction={() => {
                  setShowPrompt(false);
                }}
                rightButton={{
                  text: 'Go to create survey form',
                  action: () => {
                    const pathname = '/survey_forms/create';
                    history.push({
                      pathname: pathname,
                      state: {
                        from: history.location,
                        title: 'Continue to create coupon?',
                        content: 'You can continue to create the coupon.',
                      },
                    });
                  },
                }}
                title="Go to create a survey form?"
                description={`You will leave the coupon creation process. After you create a new survey form, you can back to this page.`}
              />
              <ReactHookFormErrorMessage
                errors={errors}
                id="transactionAmountSurvey"
              />
            </>
          )}
        />
      ) : null}
    </div>
  );
};

const mapPropsToState = (state) => ({
  couponTemplate: state.createCoupon.couponTemplate,
});
export default connect(mapPropsToState)(CreateCouponStepThreeGeneral);
