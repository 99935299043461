import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import './CustomBreadcrumb.scss';
import { useHistory, withRouter } from 'react-router-dom';
import Route from 'route-parser';
import { tabList } from '../../config/NavBarConfig';
import { pageRoutes } from '../../config/PageConfig';
import CustomViewer from './CustomViewer';

const isFunction = (value) => typeof value === 'function';

const getPathTokens = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

function getRoutes() {
  const routes = [...tabList, ...pageRoutes]; //tabList.concat([pageRoutes]);
  let formatedRoutes = {};
  routes.map((route, index) => {
    if ('list' in route) {
      route.list.map((item, index) => {
        formatedRoutes[item.path] = item.name;
      });
    } else {
      if (Array.isArray(route.path)) {
        route.path.map((path, index) => {
          formatedRoutes[path] = route.name || 'no set';
        });
      } else {
        // console.log('@@40: ', route.name, route.path);
        formatedRoutes[route.path] = route.name;
      }
    }
  });

  return formatedRoutes;
}

function getRouteMatch(routes, path) {
  // const formatedRoutes = getRoutes(routes);
  return Object.keys(routes)
    .map((key) => {
      const params = new Route(key).match(path);
      return {
        didMatch: params !== false,
        params,
        key,
      };
    })
    .filter((item) => item.didMatch)[0];
}

function getBreadcrumbs({ routes, match, location }) {
  // const formatedRoutes = formatRoutes(routes);

  const pathTokens = getPathTokens(location.pathname);
  return pathTokens.map((path, i) => {
    const routeMatch = getRouteMatch(routes, path);
    if (!routeMatch) {
      return null;
    }
    const routeValue = routes[routeMatch.key];
    const name = isFunction(routeValue)
      ? routeValue(routeMatch.params)
      : routeValue;
    return { name, link: path };
  });
}

function CustomBreadcrumb(props) {
  let breads = props.breadcrumbs;
  let name = props.name || '';
  if (breads === undefined) {
    const routes = getRoutes();
    const match = props.match;
    const location = props.location;
    breads = getBreadcrumbs({ routes, match, location });
  }
  // console.log('@@88: ', breads);
  const history = useHistory();
  return (
    <Breadcrumb className="breadcrumb-container">
      {breads.map((bread, index) => {
        if (bread) {
          const crumb = bread.name === 'no set' ? name : bread.name;
          // const innerHtml = nameIsHtml
          //   ? { dangerouslySetInnerHTML: { __html: name } }
          //   : {};
          return (
            <Breadcrumb.Item
              onClick={() => {
                if (index === breads.length - 1) {
                  return;
                }
                bread.isBack
                  ? history.goBack()
                  : history.push({
                      pathname: bread.link,
                      state: bread.state,
                      search: bread.search,
                    });
              }}
              key={`${index}-${bread.name}-${bread.link}`}
              active={index === breads.length - 1}
            >
              {props.nameIsHtml && name === crumb ? (
                <CustomViewer className="html" initValue={crumb} />
              ) : (
                <label>{crumb}</label>
              )}
            </Breadcrumb.Item>
          );
        }
      })}
    </Breadcrumb>
  );
}

export default withRouter(CustomBreadcrumb);
