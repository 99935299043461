import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './OverViewContent.scss';
import { LanguageConfig } from '../../config/CustomEnums';
import { fortuneBagOptions } from './CreateBrandContent';
import CustomViewer from '../base/CustomViewer';
import BasePrompt from '../base/prompt/BasePrompt';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import i18n from '../../I18n';

const getItemOrderList = (datas) => {
  if (!datas || !datas.length) {
    return '-';
  }
  return datas.map((data) => data.name);
};

const getFortuneBag = (fortuneBag) => {
  if (!fortuneBag?.checked) {
    return '-';
  }
  switch (fortuneBag.option) {
    case fortuneBagOptions.optionA.value:
      return fortuneBagOptions.optionA.label;
    case fortuneBagOptions.optionB.value:
      return fortuneBagOptions.optionB.label;
    case fortuneBagOptions.optionC.value:
      return fortuneBagOptions.optionC.label;
    default:
      return '-';
  }
};

export default function OverViewContent({ language }) {
  const selectedBrand = useSelector((state) => state.brand.selectedBrand);
  const name =
    language === LanguageConfig.english
      ? selectedBrand?.name
      : selectedBrand?.translations?.[language]?.name;
  const description =
    language === LanguageConfig.english
      ? selectedBrand?.description
      : selectedBrand?.translations?.[language]?.description;

  const getAttributeTags = (attributeTags) => {
    const formatAttributeTags = attributeTags?.map(
      (data) => `[ID:tag_${data.pk}] ${data.name}`,
    );
    return formatAttributeTags;
  };

  const getData = () => {
    const data = {
      ...selectedBrand,
      name,
      description,
      attributeTags: getAttributeTags(selectedBrand?.attributeTags),
      fortuneBag: getFortuneBag(selectedBrand?.fortuneBag),
      relatedStores: getItemOrderList(selectedBrand?.relatedStores),
    };
    return data;
  };

  return (
    <>
      <MembershipInfoCard
        title={'INFORMATION'}
        data={getData()}
        fields={[
          [
            {
              title: 'ID',
              field: 'pk',
            },
          ],
          [
            {
              title: 'Name',
              field: 'name',
            },
          ],
          [
            {
              title: 'Description',
              field: 'description',
              isHtml: true,
            },
          ],
          [
            {
              title: 'Handle the entitlement of goodie bags',
              field: 'fortuneBag',
            },
          ],
          [
            {
              title: 'Cover image',
              field: 'coverPhoto',
            },
          ],
          [
            {
              title: 'AttributeTag',
              field: 'attributeTags',
            },
          ],
          [
            {
              title: 'Related store',
              field: 'relatedStores',
            },
          ],
        ]}
      />
    </>
  );
}
