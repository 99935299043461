import { NavBarNames } from '../../config/NavBarNameList';

export function getCurrentRouteName(location) {
  const isBelongsToTab = (tab) => location?.toLowerCase()?.indexOf(tab) > -1;
  if (location === '/') {
    return NavBarNames.dashboardOverview;
  }

  if (isBelongsToTab('dashboard_customer')) {
    return NavBarNames.dashboardCustomer;
  }
  if (isBelongsToTab('dashboard_campaign')) {
    return NavBarNames.dashboardCampaign;
  }
  if (isBelongsToTab('dashboard_coupon')) {
    return NavBarNames.dashboardCoupon;
  }
  if (isBelongsToTab('dashboard_goodiebag')) {
    return NavBarNames.dashboardGoodiebag;
  }

  if (isBelongsToTab('simple')) {
    return NavBarNames.simpleDashboard;
  }

  if (isBelongsToTab('/campaigns')) {
    return NavBarNames.campaigns;
  }
  if (isBelongsToTab('/campaign_groups')) {
    return NavBarNames.campaignGroups;
  }
  if (isBelongsToTab('/goodie_bags')) {
    return NavBarNames.goodieBags;
  }
  if (isBelongsToTab('/goodie_bag_groups')) {
    return NavBarNames.goodieBagGroups;
  }
  if (isBelongsToTab('manual_use_coupon')) {
    return NavBarNames.manualUseCoupon;
  }
  if (isBelongsToTab('campaign') && isBelongsToTab('category')) {
    return NavBarNames.campaignCategory;
  }
  if (isBelongsToTab('coupon') && isBelongsToTab('records')) {
    return NavBarNames.couponRecords;
  }
  if (isBelongsToTab('coupon')) {
    return NavBarNames.coupons;
  }
  if (isBelongsToTab('logo_label')) {
    return NavBarNames.logoLabel;
  }
  if (isBelongsToTab('earn')) {
    return NavBarNames.earnRules;
  }
  if (isBelongsToTab('customer') && isBelongsToTab('group')) {
    return NavBarNames.customerGroup;
  }
  if (isBelongsToTab('custom')) {
    return NavBarNames.customer;
  }
  if (isBelongsToTab('segments')) {
    return NavBarNames.segments;
  }
  if (isBelongsToTab('levels')) {
    return NavBarNames.levels;
  }
  if (isBelongsToTab('general_alert')) {
    return NavBarNames.message;
  }
  if (isBelongsToTab('rewards')) {
    return NavBarNames.rewards;
  }
  if (isBelongsToTab('brands')) {
    return NavBarNames.brands;
  }
  if (isBelongsToTab('stores')) {
    return NavBarNames.stores;
  }
  if (isBelongsToTab('store') && isBelongsToTab('category')) {
    return NavBarNames.storeCategory;
  }
  if (isBelongsToTab('translations')) {
    return NavBarNames.translations;
  }
  if (isBelongsToTab('transactions')) {
    return NavBarNames.transactions;
  }
  if (isBelongsToTab('point') && isBelongsToTab('records')) {
    return NavBarNames.pointRecords;
  }
  if (isBelongsToTab('administrator_groups')) {
    return NavBarNames.adminGroup;
  }
  if (isBelongsToTab('administrators')) {
    return NavBarNames.admin;
  }
  if (isBelongsToTab('banners')) {
    return NavBarNames.banners;
  }
  if (isBelongsToTab('product') && isBelongsToTab('categories')) {
    return NavBarNames.productCategory;
  }
  if (isBelongsToTab('home_campaigns')) {
    return NavBarNames.featuredCampaign;
  }
  if (isBelongsToTab('featured') && isBelongsToTab('products')) {
    return NavBarNames.featuredProduct;
  }
  if (isBelongsToTab('tags')) {
    return NavBarNames.tags;
  }
  if (isBelongsToTab('store_codes')) {
    return NavBarNames.storeCodes;
  }
  if (isBelongsToTab('languages')) {
    return NavBarNames.langauge;
  }
  if (isBelongsToTab('tour_cards')) {
    return NavBarNames.tourCard;
  }
  if (isBelongsToTab('terms_and_conditions')) {
    return NavBarNames.termsAndConditions;
  }
  if (isBelongsToTab('payment_settlement')) {
    return NavBarNames.paymentSettlement;
  }
  if (isBelongsToTab('manage_recaptcha')) {
    return NavBarNames.manageRecaptcha;
  }

  if (isBelongsToTab('interest_preferences')) {
    return NavBarNames.interestPreference;
  }
  if (isBelongsToTab('channels')) {
    return NavBarNames.channel;
  }
  if (isBelongsToTab('markets')) {
    return NavBarNames.targetMarket;
  }
  if (isBelongsToTab('survey_forms')) {
    return NavBarNames.surveyForm;
  }
  if (isBelongsToTab('offer_type_labels')) {
    return NavBarNames.offerTypeLabel;
  }

  if (isBelongsToTab('otp_channel')) {
    return NavBarNames.otpChannel;
  }

  if (isBelongsToTab('check_otp')) {
    return NavBarNames.checkOtp;
  }

  if (isBelongsToTab('working_team')) {
    return NavBarNames.workingTeam;
  }
}
