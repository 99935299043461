export const PermissionCodes = {
  administratorgroup: 'administratorgroup',
  administrator: 'administrator',

  brand: 'brand',
  store: 'store',
  storecategory: 'storecategory',

  campaign: 'campaign',
  campaignGroup: 'campaigngroup',
  goodieBag: 'goodiebag',
  goodieBagGroup: 'goodiebaggroup',
  campaigncategory: 'campaigncategory',
  coupontemplate: 'coupontemplate',
  coupon: 'coupon',
  earningrule: 'earningrule',
  message: 'message',
  logolabel: 'logolabel',

  customer: 'customer',
  customergroup: 'customergroup',
  segment: 'segment',

  pointtransaction: 'pointtransaction',
  transaction: 'transaction',
  coupontransaction: 'coupontransaction',

  addAdministratorGroup: 'add_administratorgroup',
  changeAdministratorGroup: 'change_administratorgroup',
  deleteAdministratorGroup: 'delete_administratorgroup',
  // view_administratorgroup
  addAdministrator: 'add_administrator',
  changeAdministrator: 'change_administrator',
  deleteAdministrator: 'delete_administrator',
  viewAdministrator: 'view_administrator',

  //   addCustomer: 'add_customer',
  changeCustomer: 'change_customer',
  deleteCustomer: 'delete_customer',
  viewCustomer: 'view_customer',

  addSegment: 'add_segment',
  changeSegment: 'change_segment',
  deleteSegment: 'delete_segment',
  viewSegment: 'view_segment',

  addCustomerGroup: 'add_customergroup',
  changeCustomerGroup: 'change_customergroup',
  deleteCustomerGroup: 'delete_customergroup',
  // viewCustomerGroup: 'view_customergroup',

  addCampaign: 'add_campaign',
  changeCampaign: 'change_campaign',
  deleteCampaign: 'delete_campaign',
  viewCampaign: 'view_campaign',

  changeCampaignGroup: 'change_campaigngroup',
  viewCampaignGroup: 'view_campaigngroup',

  addGoodieBag: 'add_goodiebag',
  changeGoodieBag: 'change_goodiebag',
  deleteGoodieBag: 'delete_goodiebag',
  viewGoodieBag: 'view_goodiebag',

  changeGoodieBagGroup: 'change_goodiebaggroup',
  viewGoodieBagGroup: 'view_goodiebaggroup',

  addCampaignCategory: 'add_campaigncategory',
  changeCampaignCategory: 'change_campaigncategory',
  deleteCampaignCategory: 'delete_campaigncategory',
  viewCampaignCategory: 'view_campaigncategory',

  addEarningRule: 'add_earningrule',
  changeEarningRule: 'change_earningrule',
  deleteEarningRule: 'delete_earningrule',
  // viewEarningRule: 'view_earningrule',

  addMessage: 'add_message',
  changeMessage: 'change_message',
  deleteMessage: 'delete_message',
  viewMessage: 'view_message',

  addCouponTemplate: 'add_coupontemplate',
  changeCouponTemplate: 'change_coupontemplate',
  deleteCouponTemplate: 'delete_coupontemplate',
  viewCouponTemplate: 'view_coupontemplate',
  addCoupon: 'add_coupon',
  changeCoupon: 'change_coupon',
  deleteCoupon: 'delete_coupon',
  viewCoupon: 'view_coupon',

  addLogoLabel: 'add_logolabel',
  changeLogoLabel: 'change_logolabel',
  deleteLogoLabel: 'delete_logolabel',
  viewLogoLabel: 'view_logolabel',

  addBrand: 'add_brand',
  changeBrand: 'change_brand',
  deleteBrand: 'delete_brand',
  viewBrand: 'view_brand',

  addStore: 'add_store',
  changeStore: 'change_store',
  deleteStore: 'delete_store',
  viewStore: 'view_store',

  addStorecategory: 'add_storecategory',
  changeStorecategory: 'change_storecategory',
  deleteStorecategory: 'delete_storecategory',
  viewSorecategory: 'view_storecategory',

  addTransaction: 'add_transaction',
  changeTransaction: 'change_transaction',
  deleteTransaction: 'delete_transaction',
  viewTransaction: 'view_transaction',

  //   addCouponTransaction: 'add_coupontransaction',
  //   changeCouponTransaction: 'change_coupontransaction',
  //   deleteCouponTransaction: 'delete_coupontransaction',
  viewCouponTransaction: 'view_coupontransaction',

  addPointtransaction: 'add_pointtransaction',
  viewPointtransaction: 'view_pointtransaction',
  changePointtransaction: 'change_pointtransaction',

  publishCampaign: 'publish_campaign',
  unPublishCampaign: 'unpublish_campaign',

  addExportjob: 'add_exportjob',
  addImportjob: 'add_importjob',

  addCustomerPoints: 'add_customer_points',
  removeCustomerPoints: 'remove_customer_points',
  testMessage: 'test_message',

  // home manage

  banners: 'homepagebanner',
  viewBanners: 'view_homepagebanner',
  changeBanner: 'change_homepagebanner',

  targetMarkets: 'targetmarket',
  viewTargetMarkets: 'view_targetmarket',
  changeTargetMarkets: 'change_targetmarket',

  offerTypeLabel: 'offertypelabel',
  viewOfferTypeLabel: 'view_offertypelabel',
  changeOfferTypeLabel: 'change_offertypelabel',

  attributeTags: 'attributetag',
  viewAttributeTags: 'view_attributetag',
  changeAttributeTags: 'change_attributetag',

  targetMarket: 'targetmarket',
  viewTargetMarket: 'view_targetmarket',
  changeTargetMarket: 'change_targetmarket',

  channel: 'channel',
  viewChannel: 'view_channel',
  changeChannel: 'change_channel',

  surveyform: 'surveyform',
  viewSurveyForm: 'view_surveyform',
  changeSurveyForm: 'change_surveyform',

  language: 'language',
  viewLanguage: 'view_language',
  changeLanguage: 'change_language',

  tagKey: 'attributetagkey',
  viewTagKey: 'view_attributetagkey',
  changeTagKey: 'change_attributetagkey',

  tag: 'attributetag',
  viewTag: 'view_attributetag',
  changeTag: 'change_attributetag',

  tourCard: 'tourcard',
  viewTourCard: 'view_tourcard',
  changeTourCard: 'change_tourcard',

  termsAndConditions: 'termsandconditionstemplate',
  viewTermsAndConditions: 'view_termsandconditionstemplate',
  changeTermsAndConditions: 'change_termsandconditionstemplate',
  addTermsAndConditions: 'add_termsandconditionstemplate',
  deleteTermsAndConditions: 'delete_termsandconditionstemplate',

  paymentSettlement: 'paymentandsettlementreport',
  viewPaymentSettlement: 'view_paymentandsettlementreport',
  changePaymentSettlement: 'change_paymentandsettlementreport',

  interestPreference: 'interestpreference',
  viewInterestPreference: 'view_interestpreference',
  changeInterestPreference: 'change_interestpreference',

  entitlement: 'entitlement',
  viewEntitlement: 'view_entitlement',
  changeEntitlement: 'change_entitlement',

  fortunebagEntitlement: 'fortunebagentitlement',
  viewFortunebagEntitlement: 'view_fortunebagentitlement',
  changeFortunebagEntitlement: 'change_fortunebagentitlement',

  fortunebagEntitlementCustomerParticipationRecord:
    'fortunebagentitlementcustomerparticipationrecord',
  viewFortunebagEntitlementCustomerParticipationRecord:
    'view_fortunebagentitlementcustomerparticipationrecord',
  changeFortunebagEntitlementCustomerParticipationRecord:
    'change_fortunebagentitlementcustomerparticipationrecord',

  goodiebagEntitlement: 'goodiebagentitlement',
  viewGoodiebagEntitlement: 'view_goodiebagentitlement',
  changeGoodiebagEntitlement: 'change_goodiebagentitlement',

  goodiebagEntitlementCustomerParticipationRecord:
    'goodiebagentitlementcustomerparticipationrecord',
  viewGoodiebagEntitlementCustomerParticipationRecord:
    'view_goodiebagentitlementcustomerparticipationrecord',
  changeGoodiebagEntitlementCustomerParticipationRecord:
    'change_goodiebagentitlementcustomerparticipationrecord',

  starredCampaignLimitSettings: 'starredcampaignlimitsettings',
  viewStarredCampaignLimitSettings: 'view_starredcampaignlimitsettings',
  changeStarredCampaignLimitSettings: 'change_starredcampaignlimitsettings',
  viewCampaignSetting: 'view_campaignsettings',
  changeCampaignSetting: 'change_campaignsettings',

  otpChannel: 'otpchannel',
  viewOtpChannel: 'view_otpchannel',
  changeOtpChannel: 'change_otpchannel',

  checkOtp: 'checkotp',
  viewCheckOtp: 'view_checkotp',

  dashboard: 'dashboard',
  viewDashboard: 'view_dashboard',

  workingTeam: 'workingteam',
  viewWorkingTeam: 'view_workingteam',
  changeWorkingTeam: 'change_workingteam',

  loginMerchantApp: 'login_merchant_app',
};
