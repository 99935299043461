import {
  getCouponTransactions,
  getCouponTransaction,
} from '../services/TransactionRecordsAPIHelper';
import {
  getUseMethod,
  parseExpiredDate,
  parseSingleCouponRedeemPeriods,
} from './CouponUtil';
import {
  createAction,
  convertCursorToNumber,
  capitalizeFirstLetter,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from '../utils/TimeFormatUtil';
import { COUPON_STATUS } from './CouponListModel';
import { parseCouponStatus } from './SingleCouponModel';
import {
  CouponMediumType,
  DisplayCouponMediumType,
  UsedPlatform,
  ADMIN_TYPE,
  COUPON_REDEMPTION_METHOD,
} from '../config/CustomEnums';
import { getDisplayAdminType } from './AdminModel';
import { displayDiscountCodeFormat } from './CreateCouponModel';

const getInitialState = () => ({
  couponTransactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true, orderField: 'pk' },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      // orderField: 'membershipId',
    },
    {
      displayName: 'Coupon medium',
      fieldName: 'displayCouponMedium',
    },
    { displayName: 'Transaction Type', fieldName: 'displayType' },
    {
      displayName: 'Single coupon status',
      fieldName: 'singleCouponDisplayType',
    },
    {
      displayName: 'Used platform',
      fieldName: 'usedPlatform',
    },
    {
      displayName: 'Transaction Value',
      fieldName: 'transactionAmountSurveyValue',
    },
    { displayName: 'Reference ID', fieldName: 'referenceId' },
    {
      displayName: 'Coupon ID',
      fieldName: 'couponID',
      // orderField: 'coupon'
    },
    {
      displayName: 'Coupon Set Name',
      fieldName: 'couponTemplateName',
      // orderField: 'couponTemplateName',
    },
    {
      displayName: 'Expiry Date',
      fieldName: 'couponExpiryDate',
    },
    {
      displayName: 'Record create date',
      fieldName: 'creationDate',
      // orderField: 'creationDate',
    },
  ],
  merchantListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true, orderField: 'pk' },
    { displayName: 'Transaction Type', fieldName: 'displayType' },
    {
      displayName: 'Used platform',
      fieldName: 'usedPlatform',
    },
    { displayName: 'Reference ID', fieldName: 'referenceId' },
    {
      displayName: 'Coupon ID',
      fieldName: 'couponID',
      // orderField: 'coupon'
    },
    {
      displayName: 'Coupon Set Name',
      fieldName: 'couponTemplateName',
      // orderField: 'couponTemplateName',
    },
    {
      displayName: 'Expiry Date',
      fieldName: 'couponExpiryDate',
    },
    {
      displayName: 'Record create date',
      fieldName: 'creationDate',
      // orderField: 'creationDate',
    },
  ],
  manualUseListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Member ID',
      fieldName: 'membershipIdManual',
    },
    {
      displayName: 'Coupon set name',
      fieldName: 'couponTemplateName',
    },
    {
      displayName: 'Coupon Code',
      fieldName: 'couponCode',
    },
    {
      displayName: 'Reference ID',
      fieldName: 'referenceId',
    },
    {
      displayName: 'SRK',
      fieldName: 'srk',
    },
    {
      displayName: 'Use time',
      fieldName: 'usedAt',
      orderField: 'usedAt',
    },
    {
      displayName: 'Admin name',
      fieldName: 'administratorName',
    },
    {
      displayName: 'Brand',
      fieldName: 'administratorBrand',
    },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageCouponTransactionList: [],
  selectedCouponTransaction: {},
});

const parseCouponTransactionType = (type) => {
  let displayType = '';
  switch (type) {
    case 'EARN':
      displayType = 'Customer earned';
      break;
    case 'BUY':
      displayType = 'Customer acquired';
      break;
    case 'GRANT':
      displayType = 'Admin added';
      break;
    case 'RECLAIM':
      displayType = 'Admin removed';
      break;
    case 'USE':
      displayType = 'Customer use';
      break;
    case 'EXPIRED':
      displayType = 'Coupon expired';
      break;
    default:
      break;
  }
  return displayType;
};

const getEventType = (transactionType) => {
  let eventType = '';
  switch (transactionType) {
    case 'EARN':
    case 'BUY':
    case 'GRANT':
      eventType = 'Coupon in';
      break;
    case 'RECLAIM':
    case 'USE':
    case 'EXPIRED':
      eventType = 'Coupon out';
      break;
    default:
      break;
  }
  return eventType;
};

export const getDisplayUsedPlatForm = (key) => {
  switch (key) {
    case UsedPlatform.CustomerApp.key:
      return UsedPlatform.CustomerApp.value;
    case UsedPlatform.MerchantApp.key:
      return UsedPlatform.MerchantApp.value;
    case UsedPlatform.CMS.key:
      return UsedPlatform.CMS.value;
    case UsedPlatform.MerchantAppPhysical.key:
      return UsedPlatform.MerchantAppPhysical.value;
    default:
      return key;
  }
};

const parseOwnerProfile = (ownerProfile) => {
  const {
    mobilePhoneNumberCountryCode: countryCode,
    mobilePhoneNumberSubscriberNumber: subscriberNumber,
    fullname: ownerProfileFullName,
    identityNumber: ownerProfileIdentityNumber,
  } = ownerProfile || {};
  let ownerProfilePhoneNumber = null;
  if (subscriberNumber) {
    ownerProfilePhoneNumber = countryCode
      ? `+${countryCode}${subscriberNumber}`
      : subscriberNumber;
  }
  return {
    ownerProfilePhoneNumber,
    ownerProfileFullName,
    ownerProfileIdentityNumber,
  };
};

const parseCouponTransaction = (item) => {
  const isPhysical =
    item?.coupon?.batch?.couponMedium === CouponMediumType.Physical;
  return {
    ...item,
    pk: item.pk,
    id: item.id,
    membershipId:
      isPhysical && item?.coupon?.isUsed ? 'Guest user' : item?.membershipId,
    membershipIdManual: item.customer
      ? item.customer?.membershipId
      : item.coupon?.batch?.couponMedium === CouponMediumType.Physical
      ? 'Guest user'
      : '',
    ssoUid: item.customer?.ssoUid,
    originType: item.type,
    displayType: parseCouponTransactionType(item.type),
    pointsSpent: item.pointsSpent,
    creationDate: getDisplayDate(item.creationDate),
    usedAt: formatDate(item.coupon?.usedAt, 'DD MMM yyyy (ddd), HH:mm:s'),
    usedDate: formatDate(item.coupon?.usedAt, 'DD MMM yyyy (ddd), HH:mm a'),
    usedDateManual: formatDate(item.coupon?.usedAt, 'MMM D, yyyy'),
    campaignName: item.campaign?.name,
    campaignID: item.campaign?.pk,
    earningRuleName: item.campaign?.earningCampaignTypeEarningRule?.name,
    couponID: item.coupon?.pk,
    couponCode: item.coupon?.code,
    couponTemplateName: item.coupon?.template?.name,
    couponTemplateID: item.coupon?.template?.pk,
    couponSerialNumber: item.coupon?.serialNumber,
    couponTemplateNameWithSerialNumber: `${capitalizeFirstLetter(
      item.type.toString().toLowerCase(),
    )} ${item.coupon?.template?.name}(${item.coupon?.serialNumber})`,
    couponExpiryDate: item.coupon?.template
      ? parseExpiredDate(item.coupon?.template)
      : null,
    couponBarcodeFormat: item.coupon?.template?.barcodeFormat?.name,
    administratorName: item?.administrator?.username,
    administratorBrand:
      item?.administrator?.adminType === ADMIN_TYPE.HKTB_ADMIN
        ? 'HKTB'
        : item?.administrator?.brand?.name,
    createdDate: formatDate(item.creationDate),
    eventType: getEventType(item.type),
    transactionAmountSurveyValue: item.transactionAmountSurveyValue,
    isHighValueCoupon: item.coupon?.template?.requireHkidOrPassportNumber,
    ...parseOwnerProfile(item.coupon?.ownerProfile),
    singleCouponDisplayType: parseCouponStatus(item.coupon),
    referenceId: item.coupon?.usedAt ? item.coupon?.referenceId : null,
    srk: item.coupon?.srk,
    storeName: item?.store?.name,
    displayAdmin: item.administrator?.pk
      ? `[ID:${item.administrator?.pk}] ${item.administrator?.username}`
      : null,
    usedPlatform: getDisplayUsedPlatForm(item.coupon?.usedPlatform),
    displayCouponMedium:
      item?.coupon?.batch?.couponMedium === CouponMediumType.Physical
        ? DisplayCouponMediumType.PHYSICAL
        : DisplayCouponMediumType.EWALLET,
    useMethod: getUseMethod(item?.coupon),
    adminType: getDisplayAdminType(item?.administrator?.adminType),
    discountCodeFormat:
      item?.coupon?.template?.redemptionMethod ===
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
        ? displayDiscountCodeFormat[item?.coupon?.template?.discountCodeFormat]
        : '-',
    ...parseSingleCouponRedeemPeriods(item?.coupon?.template),
  };
};

export default {
  namespace: 'couponTransactions',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    getCouponTransactions: [
      function* ({ payload }, { call, put }) {
        // const { afterCursor } = payload;
        const serviceArgs = [
          getCouponTransactions,
          null,
          { ...payload, rank: true },
        ];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              couponTransactionList: couponTransactionList,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageCouponTransactions: [
      function* ({ payload }, { call, put, select }) {
        let currentPageCouponTransactionList = yield select(
          (state) => state.couponTransactions.currentPageCouponTransactionList,
        ) || [];
        const { page, loadMore, afterAction = () => {} } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        console.log('@@158: ', afterCursor);
        const serviceArgs = [getCouponTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const pageInfo = data.couponTransactions.pageInfo;
          const totalCount = data.couponTransactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          currentPageCouponTransactionList =
            page == 1
              ? couponTransactionList
              : loadMore
              ? [...currentPageCouponTransactionList, ...couponTransactionList]
              : couponTransactionList;
          yield put(
            createAction('updateState')({
              currentPageCouponTransactionList,
              pageInfo: {
                ...pageInfo,
                startCursor:
                  convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
          afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSingleCouponTransactionRecord: [
      function* ({ payload }, { put }) {
        const { couponTransactionPK } = payload;
        const transactionID = btoa(
          `CouponTransactionNode:${couponTransactionPK}`,
        );
        const serviceArgs = [getCouponTransaction, transactionID];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              selectedCouponTransaction: parseCouponTransaction(
                data.couponTransaction,
              ),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
