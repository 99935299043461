import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  convertPKToId,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { APIStatus } from '../config/CustomEnums';

function* getAll({
  payload = {},
  select,
  put,
  parse = () => { },
  objectKey,
  namespace,
  isSelect,
  listAPI = () => { },
  pageSize = 100,
  afterAction = () => { }
}) {
  const tempKey = isSelect ? 'selectedListTemp' : 'allListTemp';
  const allKey = isSelect ? 'selectedAllList' : 'notPagedAllList';
  const nextPage = isSelect ? 'getSelectedAllList' : 'getAllList';
  const loadingKey = isSelect ? 'selectedAllListLoading' : 'allListLoading';

  function* loadResult(data = {}, prePayload = {}) {
    const endCursor = data.endCursor || 0;
    const totalCount = data.totalCount || 0;
    const pagedList = data[objectKey] || [];
    let oldTempList = yield select((state) => state[namespace][tempKey]) || [];
    const newList = [...oldTempList, ...pagedList];
    console.log('totalCount:', objectKey, totalCount, endCursor);
    if (endCursor + 1 < totalCount) {
      const currentPage = (endCursor + 1) / pageSize + 1;
      yield put({
        type: 'updateState',
        payload: {
          [tempKey]: newList,
        },
      });
      yield put({
        type: nextPage,
        payload: { ...prePayload, page: currentPage },
      });
      return;
    }
    yield put({
      type: 'updateState',
      payload: {
        [allKey]: newList,
        [tempKey]: [],
        [loadingKey]: false,
      },
    });
  }
  const page = payload.page || 1;
  console.log('payload.page:', payload.page);
  const pageCursor = payload.page
    ? convertNumberToCursor((page - 1) * pageSize - 1)
    : '';

  function* onSuccess(data) {
    const objectData = data?.[objectKey] || {};
    const pageInfo = objectData.pageInfo;

    if (!pageInfo) {
      return;
    }
    console.log('71: apiSuccess:', data, objectKey);
    console.log(parse);
    const list = parse(data);
    const endCursor = convertCursorToNumber(pageInfo.endCursor);
    yield loadResult(
      {
        [objectKey]: list,
        endCursor: endCursor,
        totalCount: objectData.totalCount,
      },
      payload,
    );
    afterAction();
  }

  function* onError(data) {
    console.log('onErrordata:', data);
    yield loadResult({
      stores: [],
      totalCount: 0,
      endCursor: 0,
    });
  }
  console.log('apiWithResponseHandle:', payload);
  const serviceArgs = [
    listAPI,
    pageCursor,
    { ...payload, pageSize, isAll: true },
  ];
  yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
}

const getInitialState = () => ({
  pagedList: [],
  allList: [],
  notPagedAllList: [],
  allListTemp: [],
  selectedAllList: [],
  selectedListTemp: [],
  allListLoading: false,
  selectedAllListLoading: false,
  checkedList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {},
  apiStatus: APIStatus.none,
  detail: {},
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  createStatus: APIStatus.none,
  createdNew: null,
  createUpdateError: [],
});

export function createModel(options) {
  const {
    namespace,
    params = {},
    states = {},
    reducers = {},
    effects = {},
  } = options;
  // console.log('states:', namespace, states);
  return {
    namespace: `${namespace}`,
    state: { ...getInitialState(), ...states },
    reducers: {
      updateState(state, { payload }) {
        return { ...state, ...payload };
      },
      updateListData(state, { payload }) {
        const page = payload.page || 1;
        const pageCount = params.pageCount || 20;
        const data = payload.data;
        let newPayload = {};
        const listFieldName = payload?.listName || 'allList';
        if (payload.isSelectorLoad) {
          newPayload = {
            [listFieldName]:
              page > 1 ? [...state[listFieldName], ...data] : data,
          };
        }
        let newPayloadByOverview = {};
        if (payload.isSelectorByOverview) {
          newPayloadByOverview = {
            allListByOverview:
              page > 1 ? [...state.allListByOverview, ...data] : data,
          };
        }

        const pageInfo = payload.pageInfo;
        const startCursor = convertCursorToNumber(pageInfo.startCursor);
        const endCursor = convertCursorToNumber(pageInfo.endCursor);

        newPayload = {
          ...newPayload,
          ...newPayloadByOverview,
          pagedList: data,
          totalCount: payload.totalCount,
          totalPage: Math.ceil(payload.totalCount / pageCount),
          pageInfo: {
            startCursor: isNaN(startCursor) ? 0 : startCursor + 1,
            endCursor: isNaN(endCursor) ? 0 : endCursor + 1,
          },
        };
        return { ...state, ...newPayload };
      },
      clearData(state, { payload }) {
        return {
          ...state,
          ...states,
          ...getInitialState(),
          ...(params.initState || {}),
        };
      },
      loadFromCookie(state, { payload }) {
        const detail = getObjectFromSessionStorage(params.sessionKey);
        if (!detail) {
          return {
            ...state,
            detail: {
              ...getInitialState().detail,
              ...(params.initState?.detail || {}),
            },
            hasUpdatedDefaultValues: true,
          };
        }
        return {
          ...state,
          hasUpdatedDefaultValues: true,
          detail: {
            ...detail,
          },
        };
      },
      saveToCookie(state, { payload }) {
        saveToSessionStorage(params.sessionKey, payload);
        return { ...state };
      },
      removeFromCookie(state, { payload }) {
        removeFromSessionStorage(params.sessionKey);
        return { ...state, hasUpdatedDefaultValues: false };
      },
      ...reducers,
    },
    effects: {
      getList: [
        function* ({ payload }, { call, select, put }) {
          const page = payload?.page || 1;
          const pageCount = params.pageCount || 20;
          const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);
          const serviceArgs = [params.listAPI, pageCursor, payload];

          function* onSuccess(data) {
            yield put({
              type: 'updateListData',
              payload: {
                ...payload,
                data: params.parse(data),
                totalCount: data[params.dataKey || params.objectKey].totalCount,
                pageInfo: data[params.dataKey || params.objectKey].pageInfo,
              },
            });
          }
          if (payload?.loading) {
            yield loading(serviceArgs, onSuccess);
          } else {
            yield apiWithResponseHandle(serviceArgs, onSuccess);
          }
        },
        { type: 'takeLatest' },
      ],
      getAllList: [
        function* ({ payload }, { call, select, put }) {
          console.log('@@239: ', payload);
          yield put({
            type: 'updateState',
            payload: {
              allListLoading: true,
            },
          });
          // console.log("getAllList: " , params.listAPI, payload)
          yield getAll({
            payload,
            select,
            put,
            parse: payload?.parse || params.parse,
            objectKey: payload?.objectKey || params.objectKey,
            namespace,
            isSelect: false,
            listAPI: payload?.listAPI || params.listAPI,
            pageSize: payload?.pageSize,
            afterAction: payload?.afterAction,
          });
        },
        { type: 'takeLatest' },
      ],
      getSelectedAllList: [
        function* ({ payload }, { call, select, put }) {
          yield put({
            type: 'updateState',
            payload: {
              selectedAllListLoading: true,
            },
          });
          yield getAll({
            payload,
            select,
            put,
            parse: params.parse,
            objectKey: params.objectKey,
            namespace,
            isSelect: true,
            listAPI: params.listAPI,
            pageSize: payload?.pageSize,
          });
        },
        { type: 'takeLatest' },
      ],
      getOneDetail: [
        function* ({ payload }, { all, select, put }) {
          const id = convertPKToId(params.pkNode, payload.id);
          const afterGet = payload?.afterAction || (() => { });
          console.log('convertPKToId:', id, payload);
          const serviceArgs = [params.detailAPI, id];
          function* onSuccess(data) {
            const detail = params.parseDetail(data);
            afterGet(detail);
            yield all([
              put({
                type: 'updateState',
                payload: {
                  detail: detail,
                  hasUpdatedDefaultValues: true,
                },
              }),
            ]);
          }
          function* onError(err) {
            yield put({
              type: 'updateState',
              payload: { apiStatus: APIStatus.failed },
            });
          }

          yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
        },
        { type: 'takeLatest' },
      ],

      delete: [
        function* ({ payload }, { put, all, select }) {
          const { checked } = yield select((state) => ({
            checked: state[namespace].checkedList,
          }));

          let pks = checked.map((item) => item.pk);

          const serviceArgs = [params.deleteAPI, pks];
          const afterAction = payload.afterAction || (() => { });
          function* onSuccess(data) {
            yield put({
              type: 'updateState',
              payload: { checkedList: [] },
            });
            afterAction();
          }

          yield apiWithResponseHandle(serviceArgs, onSuccess);
        },
        { type: 'takeLatest' },
      ],
      responseCreateOrUpdate: [
        function* ({ payload }, { call, select, put }) {
          const afterActions = payload.afterActions || (() => { });
          function* onSuccess(data) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: payload.isCreate
                  ? APIStatus.success
                  : APIStatus.none,
                formHasSubmitted: true,
                hasUpdatedDefaultValues: false,
                apiStatus: payload.isCreate
                  ? APIStatus.none
                  : APIStatus.success,
                createdNew:
                  params.createdNewParse && payload.isCreate
                    ? params.createdNewParse(data)
                    : null,
                formChanged: false,
              },
            });
            yield put({
              type: 'removeFromCookie',
            });
            afterActions(data);
          }
          function* onError(err) {
            console.log('err: ', err);
            yield put({
              type: 'updateState',
              payload: {
                createStatus: payload.isCreate
                  ? APIStatus.none
                  : APIStatus.failed,
                apiStatus: payload.isCreate ? APIStatus.failed : APIStatus.none,
                createUpdateError: err.errors,
              },
            });
          }

          yield apiWithResponseHandle(
            payload.serviceArgs,
            onSuccess,
            onError,
            onError,
          );
        },
        { type: 'takeLatest' },
      ],
      updateProperty: [
        function* ({ payload }, { all, select, put }) {
          const { page, pageSize, stateName, dataKey, updateKey, updateApi } =
            payload;
          console.log('@@393', payload);
          const pageCursor = convertNumberToCursor((page - 1) * pageSize - 1);
          const serviceArgs = [updateApi, pageCursor, payload];
          function* onSuccess(data) {
            const selectedObject = yield select(
              (state) => state[namespace][stateName],
            );
            console.log("@@1051-1: ", selectedObject)
            yield put({
              type: `updateState`,
              payload: {
                [stateName]: {
                  ...selectedObject,
                  [updateKey]: [
                    ...(page > 1 ? selectedObject?.[updateKey] || [] : []),
                    ...(data?.[dataKey]?.edges?.map((item) => item.node) || []),
                  ],
                },
              },
            });
            console.log("@@1051-2: ", {
              ...selectedObject,
              [updateKey]: [
                ...(page > 1 ? selectedObject?.[updateKey] || [] : []),
                ...(data?.[dataKey]?.edges?.map((item) => item.node) || []),
              ],
            })
            if (data?.[updateKey]?.pageInfo?.hasNextPage) {
              yield put({
                type: 'updateProperty',
                payload: {
                  ...payload,
                  page: payload.page + 1,
                },
              });
              return;
            }else{
              if(payload.afterAction){
                yield payload.afterAction();
              }
            }
          }
          function* onError(err) {
            yield put({
              type: `${namespace}/updateState`,
              payload: { apiStatus: APIStatus.failed },
            });
          }

          yield apiWithResponseHandle(serviceArgs, onSuccess, onError);
        },
      ],
      ...effects,
    },
  };
}
