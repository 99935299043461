import { BaseHelper } from './BaseGraphQLAPIHelper';

const NODE = `{
  id
  pk
  channel
  title
  description
  smallDescription
  displayPriority
  showInFrontend
  translations {
    edges {
      node {
        id
        pk
        language
        title
        description
        smallDescription
      }
    }
  }
}`;

const BASE_NODE = `{
  id
  pk
  channel
  showInFrontend
}`;

export const getPagedList = (afterCursor, moreSearch) => {
  let filter = '';

  const searchName = moreSearch?.filterName || moreSearch?.searchKey;
  if (searchName) {
    filter = `, channelIcontains:"${searchName}"`;
  }
  let orderBy = moreSearch?.rank ? '-pk' : 'pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const query = `{
    otpChannels(first: 20, after:"${afterCursor}", orderBy:"${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${moreSearch?.isSelectorLoad ? BASE_NODE : NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getOne = (id) => {
  const query = `{
    otpChannel(id: "${id}") ${NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteChannels = (ids) => {
  const query = `mutation DeleteOtpChannels($input: DeleteOtpChannelsInput!) {
    deleteOtpChannels(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdate = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}OtpChannel($input: ${action}OTPChannelInput!) {
    ${action.toLowerCase()}OtpChannel(input: $input) {
      success
      node ${NODE}
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
