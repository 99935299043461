import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../models/GoodieBagsModel';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const getGoodieBagNode = ({
  isList,
  isSimplify,
  callFromCampaign,
  isAll,
  needQuantity,
}) => {
  if (isSimplify) {
    return `{
      pk
      name
      rewardType
    }`;
  }

  if (callFromCampaign) {
    return `{
      id
      pk
      name
      startDate
      endDate
      displayStartDate
      displayEndDate
      absoluteStartDate
      absoluteExpiryDate
      couponAcquirePeriodType
      numberOfDaysToExpireAfterAcquisition
      isPublished
      rewardType
    }`;
  }

  return `{
    creationDate
    lastModifiedDate
    language
    id
    pk
    name
    coverPhoto
    mobilePhoto
    ${
      isList
        ? ``
        : `
      shortDescription
      shortDescriptionInCampaignListingPage
      genericTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              title
              content
              language
            }
          }
        }
      }
      isFollowGenericTermsAndConditionsTemplate
      genericTermsAndConditionsTitle
      genericTermsAndConditionsContent
      assignCreditPerLink
      assignQuotaPerLink
    `
    }
    skipCheckLimit
    startDate
    endDate
    displayStartDate
    displayEndDate
    couponAcquirePeriodType
    absoluteStartDate
    absoluteExpiryDate
    numberOfDaysToExpireAfterAcquisition
    isPublished
    publicationDate
    status
    isExpired
    entitlementMethod
    ${
      needQuantity || !isList
        ? `
    quantityOfCreated
    quantityOfAcquired
    minCampaignStock`
        : ''
    }
    
    showCampaignCategoryFilter
    showBrandFilter
    showOfferTypeLabelFilter
    showCreditRangeFilter
    creditName
    quotaName
    ${
      isList
        ? ``
        : `
      translations {
        edges {
          node {
            language
            name
            shortDescription
            shortDescriptionInCampaignListingPage
            coverPhoto
            mobilePhoto
            pk
            genericTermsAndConditionsTitle
            genericTermsAndConditionsContent
            creditName
            quotaName

            ageRestrictedTermsAndConditionsTitle
            ageRestrictedTermsAndConditionsContent
            ageRestrictedPrimaryButtonName
            ageRestrictedSecondaryButtonName
          }
        }
      }
    `
    }
    isAgeRestricted
    ageRestrictedTermsAndConditionsTitle
    ageRestrictedTermsAndConditionsContent
    ageRestrictedPrimaryButtonName
    ageRestrictedSecondaryButtonName
    rewardType
    batches${isAll ? `(first: 1)` : ''} {
      edges {
        node {
          index
          lastModifiedDate
          fortuneBagQuantity
          validFortuneBagQuantity
          fortuneBagBrands ${isAll ? `(first: 1)` : ''} {
            edges {
              node {
                pk
                name
                handleFortuneBagEntitlement
                handleOption
              }
            }
          }
          ${
            isList
              ? ``
              : `
              entitlements {
                edges {
                  node {
                    pk
                    merchant {
                      pk
                      name
                      handleFortuneBagEntitlement
                      handleOption
                    }
                    fortuneBagQuantity
                  }
                }
              }
          `
          }
        }
      }
    }
    perHeadLimit
    groups {
      edges {
        node {
          pk
          name
        }
      }
    }
    hideOutOfStockCampaign
    showLocationFilter
  }`;
};

export const getGoodieBagsList = (afterCursor, others) => {
  let filter = '';
  console.log('@68', afterCursor, others);
  const {
    filterName,
    sort,
    status,
    active_period,
    visible_period,
    coupon_acquired_period,
    absolutePeriod,
    relativeDay,
    create_at,
    last_modified,
    entitlement_method,
    search,
    rewardType,
    isSimplify,
    isAll,
    isLinkedGroup,
    groupIn,
    goodie_bag_group,
    type,
    coupon_out_of_stock,
  } = others || {};
  if (search) {
    filter = `, search:"${search}"`;
  }
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }
  if (sort) {
    filter += `, orderBy: "${sort}"`;
  } else {
    filter += `, orderBy: "-pk"`;
  }
  if (status) {
    filter += `, isPublished: ${status}`;
  }
  if (active_period) {
    filter += `, activeTimeRange: "${active_period}"`;
  }
  if (visible_period) {
    const displayStartDateGte = visible_period?.split(',')?.[0];
    const displayEndDateLte = visible_period?.split(',')?.[1];
    filter += `, displayStartDateGte: "${displayStartDateGte}", displayEndDateLte: "${displayEndDateLte}"`;
  }
  if (isLinkedGroup || isLinkedGroup === false) {
    filter += `, isLinkedGroup: ${isLinkedGroup}`;
  }
  if (groupIn || goodie_bag_group) {
    filter += `, groupIn: "${groupIn || goodie_bag_group}"`;
  }
  if (coupon_acquired_period) {
    switch (coupon_acquired_period) {
      case 'absolutePeriod':
        if (absolutePeriod) {
          const absoluteGte = absolutePeriod?.split(',')?.[0];
          const absoluteLte = absolutePeriod?.split(',')?.[1];
          if (absoluteGte && absoluteLte) {
            filter += `, absoluteGte: "${absoluteGte}", absoluteLte: "${absoluteLte}"`;
          }
        }
        break;
      case 'relativeDay':
        if (relativeDay) {
          filter += `, relativeDay: ${relativeDay}`;
        }
        break;
      default:
        filter += `, allTime: true`;
        break;
    }
  }
  if (create_at) {
    const creationDateGte = create_at?.split(',')?.[0];
    const creationDateLte = create_at?.split(',')?.[1];
    if (creationDateGte && creationDateLte) {
      filter += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
    }
  }
  if (last_modified) {
    const lastModifiedDateGte = last_modified?.split(',')?.[0];
    const lastModifiedDateLte = last_modified?.split(',')?.[1];
    if (lastModifiedDateGte && lastModifiedDateLte) {
      filter += `, lastModifiedDateGte: "${lastModifiedDateGte}", lastModifiedDateLte: "${lastModifiedDateLte}"`;
    }
  }
  if (entitlement_method) {
    filter += `, entitlementMethodIn: "${entitlement_method}"`;
  }

  if (type) {
    filter += `, rewardTypeIn: "${type}"`;
  } else {
    if (rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value) {
      filter += `, rewardTypeIn: "${rewardType}"`;
    } else if (rewardType) {
      filter += `, rewardTypeIn: "${rewardType},BOTH"`;
    }
  }

  if (coupon_out_of_stock === 'true') {
    filter += `, couponOutOfStockFortuneBag: true`;
  }

  const query = `{
    goodieBags(first: ${
      isAll ? others.pageSize : 20
    }, after:"${afterCursor}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${getGoodieBagNode({
          isList: true,
          isSimplify,
          isAll,
          needQuantity: others?.needQuantity,
        })}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGoodieBagDetail = (id, callFromCampaign) => {
  const query = `{
    goodieBag(id: "${id}")
      ${getGoodieBagNode({ callFromCampaign })}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const checkCouponOutOfStockFortuneBag = (sendEmail) => {
  const query = `mutation CheckCouponOutOfStockFortuneBag($input: CheckCouponOutOfStockFortuneBagInput!) {
    checkCouponOutOfStockFortuneBag(input: $input) {
      existsCouponOutOfStock
    }
  }`;
  const variables = {
    input: {
      sendEmail,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createGoodieBag = (payload) => {
  console.log('@198', payload);
  const query = `mutation createGoodieBag($input: CreateGoodieBagInput!) {
    createGoodieBag(input: $input) {
      node ${getGoodieBagNode({ isSimplify: true })}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGoodieBag = (payload) => {
  console.log('@216', payload);
  const query = `mutation updateGoodieBag($input: UpdateGoodieBagInput!) {
    updateGoodieBag(input: $input) {
      node ${getGoodieBagNode({ isSimplify: true })}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishGoodieBag = (payload) => {
  const query = `mutation publishGoodieBag($input: PublishGoodieBagInput!) {
    publishGoodieBag(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteGoodieBag = (ids) => {
  console.log('@@251', ids);
  const query = `mutation deleteGoodieBags($input: DeleteGoodieBagsInput!) {
    deleteGoodieBags(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGoodieBagCouponCampaigns = (id, afterCursor) => {
  const query = `{
    campaigns(first: 1000, after: "${afterCursor}", orderBy:"pk", goodieBagIn: "${id}" ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          pk
          name
          type
          brand {
            pk
            name
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGoodieBagCampaignSlots = (afterCursor, filter) => {
  const { id, rewardType } = filter;
  let searchString = '';
  if (id) {
    searchString += `, goodieBagIn: "${id}"`;
  }

  if (rewardType) {
    searchString += `, rewardTypeIn: "${rewardType}"`;
  }
  const query = `{
    goodieBagCampaignSlots(first: 100, after: "${afterCursor}", orderBy:"pk"${searchString} ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          pk
          name
          rewardType
          assignRewardPerLink
          index
          ${
            filter.isSelector
              ? ''
              : `  
          translations (first: 100) {
            edges {
              node {
                pk
                language
                name
              }
            }
          }`
          }          
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGoodieBagParticipationRecordsCount = (filter) => {
  const { goodieBagIn, filterName, filterConditions, activeTimeRange } = filter;
  let searchString = '';
  if (goodieBagIn) {
    searchString += `, goodieBagIn: "${goodieBagIn}"`;
  }

  if (filterName) {
    searchString += `, nameIcontains:"${filterName}"`;
  }

  if (filterConditions) {
    searchString += `, filterConditions: "${filterConditions}"`;
  }

  if (activeTimeRange) {
    const creationDateGte = activeTimeRange?.split(',')?.[0];
    const creationDateLte = activeTimeRange?.split(',')?.[1];
    searchString += `, creationDateGte: "${creationDateGte}", creationDateLte: "${creationDateLte}"`;
  }

  const query = `{
    goodieBagParticipationRecords(first: 1000 ${searchString} ) {
      totalCount
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
